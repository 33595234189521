@charset "UTF-8";
/*
 THEMING
*/
/********************************
DEFAULT STYLING - end
********************************/
@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 13px !important; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    font-size: 14px !important; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 15px !important; } }

/*
  Generic styling for the mega-menu for it to be display as a list of elements
  Target the dgs-mega-menu, which is build up on nested unordered lists

  IMPORTANT: If you want a specific change for the mega-menu add it on a project file.
 */
#wrapper ul.dgs-mega-menu,
#wrapper ul.dgs-mega-menu ul {
  list-style: none; }

#wrapper ul.dgs-mega-menu > li {
  padding: 0 1rem;
  display: inline-block; }
  #wrapper ul.dgs-mega-menu > li > a, #wrapper .component.locations-spot .locations-search ul.dgs-mega-menu > li > .search-filter-button, .component.locations-spot .locations-search #wrapper ul.dgs-mega-menu > li > .search-filter-button, #wrapper .component.locations-spot .filter-list ul.dgs-mega-menu > li > .close-button, .component.locations-spot .filter-list #wrapper ul.dgs-mega-menu > li > .close-button, #wrapper .component.locations-spot .filter-list .filter-row ul.dgs-mega-menu > li > label, .component.locations-spot .filter-list .filter-row #wrapper ul.dgs-mega-menu > li > label, #wrapper .component.locations-spot .location-detail ul.dgs-mega-menu > li > .close-button, .component.locations-spot .location-detail #wrapper ul.dgs-mega-menu > li > .close-button {
    display: inline-block;
    padding: 1rem 0;
    position: relative; }
    #wrapper ul.dgs-mega-menu > li > a.active, #wrapper .component.locations-spot .locations-search ul.dgs-mega-menu > li > .active.search-filter-button, .component.locations-spot .locations-search #wrapper ul.dgs-mega-menu > li > .active.search-filter-button, #wrapper .component.locations-spot .filter-list ul.dgs-mega-menu > li > .active.close-button, .component.locations-spot .filter-list #wrapper ul.dgs-mega-menu > li > .active.close-button, #wrapper .component.locations-spot .filter-list .filter-row ul.dgs-mega-menu > li > label.active, .component.locations-spot .filter-list .filter-row #wrapper ul.dgs-mega-menu > li > label.active, #wrapper .component.locations-spot .location-detail ul.dgs-mega-menu > li > .active.close-button, .component.locations-spot .location-detail #wrapper ul.dgs-mega-menu > li > .active.close-button {
      position: relative; }
    #wrapper ul.dgs-mega-menu > li > a.active:before, #wrapper .component.locations-spot .locations-search ul.dgs-mega-menu > li > .active.search-filter-button:before, .component.locations-spot .locations-search #wrapper ul.dgs-mega-menu > li > .active.search-filter-button:before, #wrapper .component.locations-spot .filter-list ul.dgs-mega-menu > li > .active.close-button:before, .component.locations-spot .filter-list #wrapper ul.dgs-mega-menu > li > .active.close-button:before, #wrapper .component.locations-spot .filter-list .filter-row ul.dgs-mega-menu > li > label.active:before, .component.locations-spot .filter-list .filter-row #wrapper ul.dgs-mega-menu > li > label.active:before, #wrapper .component.locations-spot .location-detail ul.dgs-mega-menu > li > .active.close-button:before, .component.locations-spot .location-detail #wrapper ul.dgs-mega-menu > li > .active.close-button:before, #wrapper ul.dgs-mega-menu > li > a.current:before, #wrapper .component.locations-spot .locations-search ul.dgs-mega-menu > li > .current.search-filter-button:before, .component.locations-spot .locations-search #wrapper ul.dgs-mega-menu > li > .current.search-filter-button:before, #wrapper .component.locations-spot .filter-list ul.dgs-mega-menu > li > .current.close-button:before, .component.locations-spot .filter-list #wrapper ul.dgs-mega-menu > li > .current.close-button:before, #wrapper .component.locations-spot .filter-list .filter-row ul.dgs-mega-menu > li > label.current:before, .component.locations-spot .filter-list .filter-row #wrapper ul.dgs-mega-menu > li > label.current:before, #wrapper .component.locations-spot .location-detail ul.dgs-mega-menu > li > .current.close-button:before, .component.locations-spot .location-detail #wrapper ul.dgs-mega-menu > li > .current.close-button:before {
      height: 0.3rem;
      background-color: lightgrey;
      visibility: visible;
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      transform: scaleX(1); }
  #wrapper ul.dgs-mega-menu > li > ul {
    width: 100%;
    display: none;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    padding: 2.5rem 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.25rem 0; }
    #wrapper ul.dgs-mega-menu > li > ul a, #wrapper ul.dgs-mega-menu > li > ul .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper ul.dgs-mega-menu > li > ul .search-filter-button, #wrapper ul.dgs-mega-menu > li > ul .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper ul.dgs-mega-menu > li > ul .close-button, #wrapper ul.dgs-mega-menu > li > ul .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper ul.dgs-mega-menu > li > ul label, #wrapper ul.dgs-mega-menu > li > ul .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper ul.dgs-mega-menu > li > ul .close-button {
      display: inline-block;
      padding: 0.5rem 0; }
      #wrapper ul.dgs-mega-menu > li > ul a.as-text, #wrapper ul.dgs-mega-menu > li > ul .component.locations-spot .locations-search .as-text.search-filter-button, .component.locations-spot .locations-search #wrapper ul.dgs-mega-menu > li > ul .as-text.search-filter-button, #wrapper ul.dgs-mega-menu > li > ul .component.locations-spot .filter-list .as-text.close-button, .component.locations-spot .filter-list #wrapper ul.dgs-mega-menu > li > ul .as-text.close-button, #wrapper ul.dgs-mega-menu > li > ul .component.locations-spot .filter-list .filter-row label.as-text, .component.locations-spot .filter-list .filter-row #wrapper ul.dgs-mega-menu > li > ul label.as-text, #wrapper ul.dgs-mega-menu > li > ul .component.locations-spot .location-detail .as-text.close-button, .component.locations-spot .location-detail #wrapper ul.dgs-mega-menu > li > ul .as-text.close-button {
        cursor: default; }
    #wrapper ul.dgs-mega-menu > li > ul > li {
      display: inline-block;
      padding: 0 3rem;
      overflow-wrap: break-word;
      border-right: 0.05rem solid lightgrey;
      box-sizing: border-box;
      /* One - two items */
      /* Three elements */
      /* Four items */
      /* Five...seven items */ }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(1), #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2) ~ li {
        width: 50%; }
        #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(1) > ul, #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2) > ul,
        #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(2) ~ li > ul {
          columns: 2; }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(3),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(3) ~ li {
        width: 33.3333%; }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(4),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(4) ~ li {
        width: 25%; }
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(5),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(5) ~ li, #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(6),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(6) ~ li, #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(7),
      #wrapper ul.dgs-mega-menu > li > ul > li:first-child:nth-last-child(7) ~ li {
        width: 20%; }
      #wrapper ul.dgs-mega-menu > li > ul > li:nth-child(n+6) {
        display: none; }
      #wrapper ul.dgs-mega-menu > li > ul > li:last-child {
        border-right: 0; }
      #wrapper ul.dgs-mega-menu > li > ul > li > a, #wrapper .component.locations-spot .locations-search ul.dgs-mega-menu > li > ul > li > .search-filter-button, .component.locations-spot .locations-search #wrapper ul.dgs-mega-menu > li > ul > li > .search-filter-button, #wrapper .component.locations-spot .filter-list ul.dgs-mega-menu > li > ul > li > .close-button, .component.locations-spot .filter-list #wrapper ul.dgs-mega-menu > li > ul > li > .close-button, #wrapper .component.locations-spot .filter-list .filter-row ul.dgs-mega-menu > li > ul > li > label, .component.locations-spot .filter-list .filter-row #wrapper ul.dgs-mega-menu > li > ul > li > label, #wrapper .component.locations-spot .location-detail ul.dgs-mega-menu > li > ul > li > .close-button, .component.locations-spot .location-detail #wrapper ul.dgs-mega-menu > li > ul > li > .close-button {
        font-weight: bold;
        padding-bottom: 0.5rem; }
      #wrapper ul.dgs-mega-menu > li > ul > li ul a, #wrapper ul.dgs-mega-menu > li > ul > li ul .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper ul.dgs-mega-menu > li > ul > li ul .search-filter-button, #wrapper ul.dgs-mega-menu > li > ul > li ul .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper ul.dgs-mega-menu > li > ul > li ul .close-button, #wrapper ul.dgs-mega-menu > li > ul > li ul .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper ul.dgs-mega-menu > li > ul > li ul label, #wrapper ul.dgs-mega-menu > li > ul > li ul .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper ul.dgs-mega-menu > li > ul > li ul .close-button {
        font-weight: normal; }

ul.dgs-burger-menu {
  list-style: none;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  ul.dgs-burger-menu, ul.dgs-burger-menu li:not(.last-navigation) > ul:not(.last-navigation) {
    list-style: none;
    background-color: white;
    display: block;
    position: absolute;
    width: 100%;
    padding-top: 2rem; }
  ul.dgs-burger-menu li {
    padding: 0.8rem 0; }
  ul.dgs-burger-menu a.as-text, ul.dgs-burger-menu .component.locations-spot .locations-search .as-text.search-filter-button, .component.locations-spot .locations-search ul.dgs-burger-menu .as-text.search-filter-button, ul.dgs-burger-menu .component.locations-spot .filter-list .as-text.close-button, .component.locations-spot .filter-list ul.dgs-burger-menu .as-text.close-button, ul.dgs-burger-menu .component.locations-spot .filter-list .filter-row label.as-text, .component.locations-spot .filter-list .filter-row ul.dgs-burger-menu label.as-text, ul.dgs-burger-menu .component.locations-spot .location-detail .as-text.close-button, .component.locations-spot .location-detail ul.dgs-burger-menu .as-text.close-button {
    cursor: default; }
  ul.dgs-burger-menu > li > ul {
    z-index: 1;
    top: 0; }
  ul.dgs-burger-menu.text-center li > a, ul.dgs-burger-menu.text-center .component.locations-spot .locations-search li > .search-filter-button, .component.locations-spot .locations-search ul.dgs-burger-menu.text-center li > .search-filter-button, ul.dgs-burger-menu.text-center .component.locations-spot .filter-list li > .close-button, .component.locations-spot .filter-list ul.dgs-burger-menu.text-center li > .close-button, ul.dgs-burger-menu.text-center .component.locations-spot .filter-list .filter-row li > label, .component.locations-spot .filter-list .filter-row ul.dgs-burger-menu.text-center li > label, ul.dgs-burger-menu.text-center .component.locations-spot .location-detail li > .close-button, .component.locations-spot .location-detail ul.dgs-burger-menu.text-center li > .close-button {
    width: 90%;
    margin: auto;
    padding: 0.5rem 0.2rem;
    display: block;
    text-align: center; }
  ul.dgs-burger-menu.text-left li {
    text-align: left; }
    ul.dgs-burger-menu.text-left li > a, ul.dgs-burger-menu.text-left .component.locations-spot .locations-search li > .search-filter-button, .component.locations-spot .locations-search ul.dgs-burger-menu.text-left li > .search-filter-button, ul.dgs-burger-menu.text-left .component.locations-spot .filter-list li > .close-button, .component.locations-spot .filter-list ul.dgs-burger-menu.text-left li > .close-button, ul.dgs-burger-menu.text-left .component.locations-spot .filter-list .filter-row li > label, .component.locations-spot .filter-list .filter-row ul.dgs-burger-menu.text-left li > label, ul.dgs-burger-menu.text-left .component.locations-spot .location-detail li > .close-button, .component.locations-spot .location-detail ul.dgs-burger-menu.text-left li > .close-button {
      width: 90%;
      margin: 0 0 0 1.5rem;
      padding: 0.5rem 0.2rem;
      display: inline-block; }
  ul.dgs-burger-menu.text-right li {
    text-align: right; }
    ul.dgs-burger-menu.text-right li > a, ul.dgs-burger-menu.text-right .component.locations-spot .locations-search li > .search-filter-button, .component.locations-spot .locations-search ul.dgs-burger-menu.text-right li > .search-filter-button, ul.dgs-burger-menu.text-right .component.locations-spot .filter-list li > .close-button, .component.locations-spot .filter-list ul.dgs-burger-menu.text-right li > .close-button, ul.dgs-burger-menu.text-right .component.locations-spot .filter-list .filter-row li > label, .component.locations-spot .filter-list .filter-row ul.dgs-burger-menu.text-right li > label, ul.dgs-burger-menu.text-right .component.locations-spot .location-detail li > .close-button, .component.locations-spot .location-detail ul.dgs-burger-menu.text-right li > .close-button {
      width: 90%;
      margin: 0 1.5rem 0 0;
      padding: 0.5rem 0.2rem;
      display: inline-block; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a,
.component.locations-spot .locations-search .search-filter-button,
.component.locations-spot .filter-list .close-button,
.component.locations-spot .filter-list .filter-row label,
.component.locations-spot .location-detail .close-button, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  /* COG: this is known to cause problems in CQ4 and SharePoint */ }

/* remember to define focus styles! */
:focus {
  outline: 0; }

body {
  line-height: 1;
  background: white;
  position: relative;
  overflow: auto; }

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: ""; }

blockquote, q {
  quotes: "" ""; }

/* COG: make sup and sup working again */
sub {
  vertical-align: sub;
  font-size: 0.9em; }

sup {
  vertical-align: super;
  font-size: 0.9em; }

* {
  box-sizing: border-box; }

select::-ms-expand {
  /* for IE 11 */
  display: none; }

html,
body {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  line-height: 1.6;
  font-size: 1.15rem;
  color: #4d4d4d;
  background-color: #fff; }

h1,
.like-h1 {
  font-size: 2.13rem;
  line-height: 1.2;
  color: inherit; }
  @media only screen and (max-width: 767px) {
    h1,
    .like-h1 {
      font-size: 2rem; } }

h2,
.like-h2 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.3;
  color: inherit;
  font-weight: normal; }
  @media all and (-ms-high-contrast: none) {
    h2,
    .like-h2 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h2,
    .like-h2 {
      font-size: 1.5rem; } }

h3,
.like-h3 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    h3,
    .like-h3 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h3,
    .like-h3 {
      font-size: 1.25rem; } }

h4,
.like-h4 {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.067rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    h4,
    .like-h4 {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    h4,
    .like-h4 {
      font-size: 1rem; } }

p {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  line-height: 1.6;
  font-size: 1.15rem; }

strong {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal; }

pre {
  padding: 2rem;
  background-color: #1890ba;
  color: #75ab30; }

h1 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h1 {
      margin-top: 0.5rem; } }

h2 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h1 {
      margin-top: 0.66667rem; } }

h3 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h1 {
      margin-top: 0.66667rem; } }

h4 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h1 {
      margin-top: 0.66667rem; } }

h5 + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h1 {
      margin-top: 0.66667rem; } }

p + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h1 {
      margin-top: 0.66667rem; } }

ol + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h1 {
      margin-top: 0.66667rem; } }

ul + h1 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h1 {
      margin-top: 0.66667rem; } }

h1 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h2 {
      margin-top: 0.5rem; } }

h2 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h2 {
      margin-top: 0.66667rem; } }

h3 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h2 {
      margin-top: 0.66667rem; } }

h4 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h2 {
      margin-top: 0.66667rem; } }

h5 + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h2 {
      margin-top: 0.66667rem; } }

p + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h2 {
      margin-top: 0.66667rem; } }

ol + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h2 {
      margin-top: 0.66667rem; } }

ul + h2 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h2 {
      margin-top: 0.66667rem; } }

h1 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h3 {
      margin-top: 0.5rem; } }

h2 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h3 {
      margin-top: 0.66667rem; } }

h3 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h3 {
      margin-top: 0.66667rem; } }

h4 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h3 {
      margin-top: 0.66667rem; } }

h5 + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h3 {
      margin-top: 0.66667rem; } }

p + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h3 {
      margin-top: 0.66667rem; } }

ol + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h3 {
      margin-top: 0.66667rem; } }

ul + h3 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h3 {
      margin-top: 0.66667rem; } }

h1 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h4 {
      margin-top: 0.5rem; } }

h2 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h4 {
      margin-top: 0.66667rem; } }

h3 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h4 {
      margin-top: 0.66667rem; } }

h4 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h4 {
      margin-top: 0.66667rem; } }

h5 + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h4 {
      margin-top: 0.66667rem; } }

p + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h4 {
      margin-top: 0.66667rem; } }

ol + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h4 {
      margin-top: 0.66667rem; } }

ul + h4 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h4 {
      margin-top: 0.66667rem; } }

h1 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + h5 {
      margin-top: 0.5rem; } }

h2 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + h5 {
      margin-top: 0.66667rem; } }

h3 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + h5 {
      margin-top: 0.66667rem; } }

h4 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + h5 {
      margin-top: 0.66667rem; } }

h5 + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + h5 {
      margin-top: 0.66667rem; } }

p + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + h5 {
      margin-top: 0.66667rem; } }

ol + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + h5 {
      margin-top: 0.66667rem; } }

ul + h5 {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + h5 {
      margin-top: 0.66667rem; } }

h1 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + p {
      margin-top: 0.5rem; } }

h2 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + p {
      margin-top: 0.66667rem; } }

h3 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + p {
      margin-top: 0.66667rem; } }

h4 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + p {
      margin-top: 0.66667rem; } }

h5 + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + p {
      margin-top: 0.66667rem; } }

p + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + p {
      margin-top: 0.66667rem; } }

ol + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + p {
      margin-top: 0.66667rem; } }

ul + p {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + p {
      margin-top: 0.66667rem; } }

h1 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + ol {
      margin-top: 0.5rem; } }

h2 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + ol {
      margin-top: 0.66667rem; } }

h3 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + ol {
      margin-top: 0.66667rem; } }

h4 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + ol {
      margin-top: 0.66667rem; } }

h5 + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + ol {
      margin-top: 0.66667rem; } }

p + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + ol {
      margin-top: 0.66667rem; } }

ol + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + ol {
      margin-top: 0.66667rem; } }

ul + ol {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + ol {
      margin-top: 0.66667rem; } }

h1 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h1 + ul {
      margin-top: 0.5rem; } }

h2 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h2 + ul {
      margin-top: 0.66667rem; } }

h3 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h3 + ul {
      margin-top: 0.66667rem; } }

h4 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h4 + ul {
      margin-top: 0.66667rem; } }

h5 + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    h5 + ul {
      margin-top: 0.66667rem; } }

p + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    p + ul {
      margin-top: 0.66667rem; } }

ol + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ol + ul {
      margin-top: 0.66667rem; } }

ul + ul {
  margin-top: 1rem; }
  @media only screen and (max-width: 767px) {
    ul + ul {
      margin-top: 0.66667rem; } }


a,
.component.locations-spot .locations-search .search-filter-button,
.component.locations-spot .filter-list .close-button,
.component.locations-spot .filter-list .filter-row label,
.component.locations-spot .location-detail .close-button,
.like-a {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #1890ba;
  font-weight: normal;
  text-decoration: none; }
  @media all and (-ms-high-contrast: none) {
    
    a,
    .component.locations-spot .locations-search .search-filter-button,
    .component.locations-spot .filter-list .close-button,
    .component.locations-spot .filter-list .filter-row label,
    .component.locations-spot .location-detail .close-button,
    .like-a {
      font-weight: bold; } }
  a:hover, .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .location-detail .close-button:hover,
  .like-a:hover {
    color: #808285; }

.like-a-negative {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #1890ba;
  font-weight: normal;
  text-decoration: none;
  color: #fff; }
  @media all and (-ms-high-contrast: none) {
    .like-a-negative {
      font-weight: bold; } }
  .like-a-negative:hover {
    color: #808285; }
  .like-a-negative:hover {
    color: #f0f0f0; }

::selection {
  background: #1890ba;
  color: #fff; }

.content .component {
  opacity: 0;
  transition: opacity 1s; }
  .content .component.hide {
    display: none; }

.footer {
  opacity: 0;
  transition: opacity 1s; }

.content .component.component-ready.animate,
.footer.animate {
  opacity: 1; }

.content .component.component-failed {
  display: none !important; }

ul,
ol {
  list-style-position: inside; }
  ul li,
  ol li {
    line-height: 1.4;
    text-indent: 0;
    padding-left: 1rem; }
    ul li + li,
    ol li + li {
      margin-top: 0.5rem; }

@media only screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none; } }

@media (min-width: 1200px) {
  .hide-on-desktop {
    display: none !important; } }

.without-padding-bottom {
  padding-bottom: 0 !important; }
  .without-padding-bottom > .component-content {
    padding-bottom: 0 !important; }

.without-margin-bottom {
  margin-bottom: 0 !important; }
  .without-margin-bottom > .component-content {
    margin-bottom: 0 !important; }

.with-padding-bottom {
  padding-bottom: 5rem !important; }
  .with-padding-bottom > .component-content {
    padding-bottom: 0 !important; }

.with-margin-bottom {
  margin-bottom: 5rem !important; }
  .with-margin-bottom > .component-content {
    margin-bottom: 0 !important; }

.clearfix {
  clear: both; }
  .clearfix:before, .clearfix:after {
    content: '';
    display: table; }

.bg-color-1 {
  background-color: #4d4d4d; }

.text-color-1 {
  color: #4d4d4d; }

.bg-color-2 {
  background-color: #f7941d; }

.text-color-2 {
  color: #f7941d; }

.bg-color-3 {
  background-color: #1890ba; }

.text-color-3 {
  color: #1890ba; }

.bg-color-4 {
  background-color: #808285; }

.text-color-4 {
  color: #808285; }

.bg-color-5 {
  background-color: #f0f0f0; }

.text-color-5 {
  color: #f0f0f0; }

.bg-color-6 {
  background-color: #fff; }

.text-color-6 {
  color: #fff; }

.bg-color-7 {
  background-color: #75ab30; }

.text-color-7 {
  color: #75ab30; }

.bg-color-8 {
  background-color: #f7bd00; }

.text-color-8 {
  color: #f7bd00; }

.bg-color-9 {
  background-color: #f74407; }

.text-color-9 {
  color: #f74407; }

.negative-elements a, .negative-elements .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .negative-elements .search-filter-button, .negative-elements .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .negative-elements .close-button, .negative-elements .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .negative-elements label, .negative-elements .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .negative-elements .close-button,
.negative-elements .like-a {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #1890ba;
  font-weight: normal;
  text-decoration: none;
  color: #fff; }
  @media all and (-ms-high-contrast: none) {
    .negative-elements a, .negative-elements .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .negative-elements .search-filter-button, .negative-elements .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .negative-elements .close-button, .negative-elements .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .negative-elements label, .negative-elements .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .negative-elements .close-button,
    .negative-elements .like-a {
      font-weight: bold; } }
  .negative-elements a:hover, .negative-elements .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search .negative-elements .search-filter-button:hover, .negative-elements .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list .negative-elements .close-button:hover, .negative-elements .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row .negative-elements label:hover, .negative-elements .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail .negative-elements .close-button:hover,
  .negative-elements .like-a:hover {
    color: #808285; }
  .negative-elements a:hover, .negative-elements .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search .negative-elements .search-filter-button:hover, .negative-elements .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list .negative-elements .close-button:hover, .negative-elements .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row .negative-elements label:hover, .negative-elements .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail .negative-elements .close-button:hover,
  .negative-elements .like-a:hover {
    color: #f0f0f0; }

.negative-elements input[type='submit'],
.negative-elements button,
.negative-elements .like-button {
  padding-left: 2rem;
  padding-right: 2rem;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none; }
  .negative-elements input[type='submit']:not(.disabled),
  .negative-elements button:not(.disabled),
  .negative-elements .like-button:not(.disabled) {
    cursor: pointer; }
  .negative-elements input[type='submit'] a, .negative-elements input[type='submit'] .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .negative-elements input[type='submit'] .search-filter-button, .negative-elements input[type='submit'] .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .negative-elements input[type='submit'] .close-button, .negative-elements input[type='submit'] .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .negative-elements input[type='submit'] label, .negative-elements input[type='submit'] .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .negative-elements input[type='submit'] .close-button,
  .negative-elements button a,
  .negative-elements button .component.locations-spot .locations-search .search-filter-button,
  .component.locations-spot .locations-search .negative-elements button .search-filter-button,
  .negative-elements button .component.locations-spot .filter-list .close-button,
  .component.locations-spot .filter-list .negative-elements button .close-button,
  .negative-elements button .component.locations-spot .filter-list .filter-row label,
  .component.locations-spot .filter-list .filter-row .negative-elements button label,
  .negative-elements button .component.locations-spot .location-detail .close-button,
  .component.locations-spot .location-detail .negative-elements button .close-button,
  .negative-elements .like-button a,
  .negative-elements .like-button .component.locations-spot .locations-search .search-filter-button,
  .component.locations-spot .locations-search .negative-elements .like-button .search-filter-button,
  .negative-elements .like-button .component.locations-spot .filter-list .close-button,
  .component.locations-spot .filter-list .negative-elements .like-button .close-button,
  .negative-elements .like-button .component.locations-spot .filter-list .filter-row label,
  .component.locations-spot .filter-list .filter-row .negative-elements .like-button label,
  .negative-elements .like-button .component.locations-spot .location-detail .close-button,
  .component.locations-spot .location-detail .negative-elements .like-button .close-button {
    color: #fff;
    text-decoration: none;
    background-color: transparent; }
  .negative-elements input[type='submit']:not(.disabled):hover,
  .negative-elements button:not(.disabled):hover,
  .negative-elements .like-button:not(.disabled):hover {
    color: #fff;
    background-color: #4d4d4d;
    text-shadow: none; }
    .negative-elements input[type='submit']:not(.disabled):hover a, .negative-elements input[type='submit']:not(.disabled):hover .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .negative-elements input[type='submit']:not(.disabled):hover .search-filter-button, .negative-elements input[type='submit']:not(.disabled):hover .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .negative-elements input[type='submit']:not(.disabled):hover .close-button, .negative-elements input[type='submit']:not(.disabled):hover .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .negative-elements input[type='submit']:not(.disabled):hover label, .negative-elements input[type='submit']:not(.disabled):hover .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .negative-elements input[type='submit']:not(.disabled):hover .close-button,
    .negative-elements button:not(.disabled):hover a,
    .negative-elements button:not(.disabled):hover .component.locations-spot .locations-search .search-filter-button,
    .component.locations-spot .locations-search .negative-elements button:not(.disabled):hover .search-filter-button,
    .negative-elements button:not(.disabled):hover .component.locations-spot .filter-list .close-button,
    .component.locations-spot .filter-list .negative-elements button:not(.disabled):hover .close-button,
    .negative-elements button:not(.disabled):hover .component.locations-spot .filter-list .filter-row label,
    .component.locations-spot .filter-list .filter-row .negative-elements button:not(.disabled):hover label,
    .negative-elements button:not(.disabled):hover .component.locations-spot .location-detail .close-button,
    .component.locations-spot .location-detail .negative-elements button:not(.disabled):hover .close-button,
    .negative-elements .like-button:not(.disabled):hover a,
    .negative-elements .like-button:not(.disabled):hover .component.locations-spot .locations-search .search-filter-button,
    .component.locations-spot .locations-search .negative-elements .like-button:not(.disabled):hover .search-filter-button,
    .negative-elements .like-button:not(.disabled):hover .component.locations-spot .filter-list .close-button,
    .component.locations-spot .filter-list .negative-elements .like-button:not(.disabled):hover .close-button,
    .negative-elements .like-button:not(.disabled):hover .component.locations-spot .filter-list .filter-row label,
    .component.locations-spot .filter-list .filter-row .negative-elements .like-button:not(.disabled):hover label,
    .negative-elements .like-button:not(.disabled):hover .component.locations-spot .location-detail .close-button,
    .component.locations-spot .location-detail .negative-elements .like-button:not(.disabled):hover .close-button {
      text-decoration: none;
      color: #fff;
      text-shadow: none; }

.negative-elements input:not([type='radio']):not([type='checkbox']):not([type='submit']),
.negative-elements textarea,
.negative-elements select {
  color: #fff;
  border-color: #f7941d; }

.component.container > .component-content {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .component.container > .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }
  .component.container > .component-content .component-content {
    padding: 0; }

.component.container.width-100 > .component-content {
  width: 100%;
  max-width: 100%;
  padding: 0; }

.width-56 h1,
.width-56 h2,
.width-56 h3,
.width-56 h4,
.width-56 h5,
.width-56 h6,
.width-56 p {
  width: 56%; }

.bgcolor-gray,
.component-bgcolor-gray.component,
.content-bgcolor-gray > .component-content {
  background-color: #eaeaea; }

.bgcolor-blue,
.component-bgcolor-blue.component,
.content-bgcolor-blue > .component-content {
  background-color: #1890ba; }

.bgcolor-orange,
.component-bgcolor-orange.component,
.content-bgcolor-orange > .component-content {
  background-color: #f7941d; }

.text-color-dark .component-content {
  color: #333; }

.text-color-light .component-content {
  color: #ffffff; }

.text-color-orange .compnent-content {
  color: #f7941d; }

.yh-bgcolor--black {
  background-color: #454545 !important; }

.yh-bgcolor--blue {
  background-color: #1890ba !important; }

.yh-bgcolor--blue-dark {
  background-color: #0c4a60 !important; }

.yh-bgcolor--gray {
  background-color: #454545 !important; }

.yh-bgcolor--gray-light {
  background-color: #eaeaea !important; }

.yh-bgcolor--whitesmoke {
  background-color: whitesmoke !important; }

.yh-bgcolor--white {
  background-color: #fff !important; }

.yh-textcolor--black {
  color: #454545 !important; }

.yh-textcolor--blue {
  color: #1890ba !important; }

.yh-textcolor--gray {
  color: #454545 !important; }

.yh-textcolor--gray-light {
  color: #eaeaea !important; }

.yh-textcolor--white {
  color: #fff !important; }

.component-border-top--gray {
  border-top: 2px solid #eaeaea; }

.component-border-bottom--gray {
  border-bottom: 2px solid #eaeaea; }

img {
  width: 100%;
  height: auto; }

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 15px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 0.8571rem;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

.nice-select {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 2px solid #f7941d;
  border-radius: 4px;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #4d4d4d;
  line-height: 1;
  border-radius: 0;
  height: 3.35rem;
  float: none; }
  .nice-select:active, .nice-select.open, .nice-select.open, .nice-select:focus, .nice-select:hover {
    border-color: #f7941d; }
  .nice-select > .current {
    display: inline-block;
    line-height: 1.5; }
  .nice-select:after {
    border-bottom: 2px solid #f7941d;
    border-right: 2px solid #f7941d; }
  .nice-select > .list {
    width: 100%;
    border-radius: 0;
    box-shadow: 0 0 0 2px #f7941d;
    margin-top: 2px; }
    .nice-select > .list .option {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
      line-height: 1;
      text-indent: 0; }
  .resource-center .nice-select {
    min-width: 13.8rem; }

#wrapper .component.blog .blog-image img {
  width: 100%;
  display: block; }

#wrapper .component.blog .component-content {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 2rem; }
  #wrapper .component.blog .component-content .blog-reading-time,
  #wrapper .component.blog .component-content .blog-author {
    line-height: 1.5; }
  #wrapper .component.blog .component-content .blog-date {
    line-height: 1.5; }
  #wrapper .component.blog .component-content .blog-author-value {
    color: #000;
    font-family: inherit; }
  #wrapper .component.blog .component-content .blog-title {
    margin: 1rem 0 0.2rem; }
  #wrapper .component.blog .component-content .blog-introduction {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: inherit;
    line-height: 1.6;
    font-size: 1.15rem;
    font-weight: 700; }
  #wrapper .component.blog .component-content .blog-content {
    margin-bottom: 0.8rem; }

@media only screen and (min-width: 992px) {
  #wrapper .component.blog .component-content .blog-title {
    margin: 2rem 0; } }

.component.blog-categories {
  padding-top: 0;
  padding-bottom: 0;
  position: relative; }
  .component.blog-categories .component-content .blog-categories-content {
    background: #000;
    margin: 0; }
    .component.blog-categories .component-content .blog-categories-content .blog-category {
      border: 0;
      border-bottom: 1px solid #fff; }
      .component.blog-categories .component-content .blog-categories-content .blog-category a, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content .blog-category .search-filter-button, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content .blog-category .close-button, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content .blog-category label, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content .blog-category .close-button {
        color: fff;
        display: block;
        max-width: none;
        padding: 0.8rem 1.2rem;
        width: auto;
        text-align: center; }
        .component.blog-categories .component-content .blog-categories-content .blog-category a::after, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .locations-search .search-filter-button::after, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content .blog-category .search-filter-button::after, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .filter-list .close-button::after, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content .blog-category .close-button::after, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .filter-list .filter-row label::after, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content .blog-category label::after, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .location-detail .close-button::after, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content .blog-category .close-button::after {
          position: relative;
          content: '';
          display: block;
          height: 1px;
          width: 0;
          top: 0.3rem;
          left: 0;
          right: 0;
          margin: auto;
          background-color: white;
          transition: width, 0.3s; }
        .component.blog-categories .component-content .blog-categories-content .blog-category a.active, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .locations-search .active.search-filter-button, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content .blog-category .active.search-filter-button, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .filter-list .active.close-button, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content .blog-category .active.close-button, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .filter-list .filter-row label.active, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content .blog-category label.active, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .location-detail .active.close-button, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content .blog-category .active.close-button {
          font-weight: 700; }
          .component.blog-categories .component-content .blog-categories-content .blog-category a.active::after, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .locations-search .active.search-filter-button::after, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content .blog-category .active.search-filter-button::after, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .filter-list .active.close-button::after, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content .blog-category .active.close-button::after, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .filter-list .filter-row label.active::after, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content .blog-category label.active::after, .component.blog-categories .component-content .blog-categories-content .blog-category .component.locations-spot .location-detail .active.close-button::after, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content .blog-category .active.close-button::after {
            width: 5rem; }

@media only screen and (min-width: 992px) {
  .component.blog-categories .component-content .blog-categories-content-wrapper {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    display: flex; } }
  @media only screen and (min-width: 992px) and (min-width: 1200px) {
    .component.blog-categories .component-content .blog-categories-content-wrapper {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

@media only screen and (min-width: 992px) {
    .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category {
      border: 0;
      border-right: 1px solid #fff; }
      .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category a, .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category .search-filter-button, .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category .close-button, .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category label, .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category .close-button {
        display: inline-block;
        padding: 1.25rem 0.95rem;
        text-align: center;
        min-width: 7.8rem;
        transition: transform 0.1s; }
      .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:last-child {
        border-right: 0; }
      .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:hover a, .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:hover .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:hover .search-filter-button, .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:hover .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:hover .close-button, .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:hover .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:hover label, .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:hover .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content-wrapper .blog-category:hover .close-button {
        transform: scale(1.1); }
  .component.blog-categories.add-opacity .blog-categories-content {
    background-color: rgba(0, 0, 0, 0.8); } }

.component.blog-list #blog-list li,
.component.latest-blog-posts #blog-list li {
  list-style: none;
  text-indent: 0;
  padding: 0;
  margin: 1rem; }
  .component.blog-list #blog-list li > a, .component.blog-list #blog-list .component.locations-spot .locations-search li > .search-filter-button, .component.locations-spot .locations-search .component.blog-list #blog-list li > .search-filter-button, .component.blog-list #blog-list .component.locations-spot .filter-list li > .close-button, .component.locations-spot .filter-list .component.blog-list #blog-list li > .close-button, .component.blog-list #blog-list .component.locations-spot .filter-list .filter-row li > label, .component.locations-spot .filter-list .filter-row .component.blog-list #blog-list li > label, .component.blog-list #blog-list .component.locations-spot .location-detail li > .close-button, .component.locations-spot .location-detail .component.blog-list #blog-list li > .close-button,
  .component.latest-blog-posts #blog-list li > a,
  .component.latest-blog-posts #blog-list .component.locations-spot .locations-search li > .search-filter-button,
  .component.locations-spot .locations-search .component.latest-blog-posts #blog-list li > .search-filter-button,
  .component.latest-blog-posts #blog-list .component.locations-spot .filter-list li > .close-button,
  .component.locations-spot .filter-list .component.latest-blog-posts #blog-list li > .close-button,
  .component.latest-blog-posts #blog-list .component.locations-spot .filter-list .filter-row li > label,
  .component.locations-spot .filter-list .filter-row .component.latest-blog-posts #blog-list li > label,
  .component.latest-blog-posts #blog-list .component.locations-spot .location-detail li > .close-button,
  .component.locations-spot .location-detail .component.latest-blog-posts #blog-list li > .close-button {
    display: block; }
  .component.blog-list #blog-list li .news-title,
  .component.latest-blog-posts #blog-list li .news-title {
    display: none; }
  .component.blog-list #blog-list li .news-text,
  .component.blog-list #blog-list li .news-link,
  .component.latest-blog-posts #blog-list li .news-text,
  .component.latest-blog-posts #blog-list li .news-link {
    margin-top: 1rem;
    text-align: center; }
  .component.blog-list #blog-list li .news-image,
  .component.latest-blog-posts #blog-list li .news-image {
    text-align: center; }
    .component.blog-list #blog-list li .news-image img,
    .component.latest-blog-posts #blog-list li .news-image img {
      max-width: 100%; }
  .component.blog-list #blog-list li .news-text,
  .component.latest-blog-posts #blog-list li .news-text {
    line-height: 1.5rem; }
  .component.blog-list #blog-list li .news-link span,
  .component.latest-blog-posts #blog-list li .news-link span {
    margin: 0; }
  .component.blog-list #blog-list li .news-date,
  .component.latest-blog-posts #blog-list li .news-date {
    text-align: center;
    padding: 0.5rem 0;
    color: #000; }

@media only screen and (min-width: 768px) {
  .component.blog-list #blog-list,
  .component.latest-blog-posts #blog-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 3.6rem 0; }
    .component.blog-list #blog-list li,
    .component.latest-blog-posts #blog-list li {
      box-sizing: border-box;
      margin: 0 auto 1rem auto;
      padding: 0 2%;
      width: 33%; }
      .component.blog-list #blog-list li .news-image img,
      .component.latest-blog-posts #blog-list li .news-image img {
        max-height: 180px; }
      .component.blog-list #blog-list li .news-text,
      .component.latest-blog-posts #blog-list li .news-text {
        height: 125px;
        max-height: 125px;
        overflow: hidden; } }

@media only screen and (min-width: 992px) {
  .component.blog-list .component-content,
  .component.latest-blog-posts .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto; } }
  @media only screen and (min-width: 992px) and (min-width: 1200px) {
    .component.blog-list .component-content,
    .component.latest-blog-posts .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

a.icomoon, .component.locations-spot .locations-search .icomoon.search-filter-button, .component.locations-spot .filter-list .icomoon.close-button, .component.locations-spot .filter-list .filter-row label.icomoon, .component.locations-spot .location-detail .icomoon.close-button {
  font-family: "Open Sans", "Arial", sans-serif !important;
  font-weight: 600; }
  a.icomoon::before, .component.locations-spot .locations-search .icomoon.search-filter-button::before, .component.locations-spot .filter-list .icomoon.close-button::before, .component.locations-spot .filter-list .filter-row label.icomoon::before, .component.locations-spot .location-detail .icomoon.close-button::before {
    font-family: "DGS-WebProgram" !important;
    font-weight: 400;
    font-size: 18px;
    text-indent: 0;
    transform: translate(15px); }
    @media (min-width: 992px) {
      a.icomoon::before, .component.locations-spot .locations-search .icomoon.search-filter-button::before, .component.locations-spot .filter-list .icomoon.close-button::before, .component.locations-spot .filter-list .filter-row label.icomoon::before, .component.locations-spot .location-detail .icomoon.close-button::before {
        transform: translate(0); } }

a.icon-home:before, .component.locations-spot .locations-search .icon-home.search-filter-button:before, .component.locations-spot .filter-list .icon-home.close-button:before, .component.locations-spot .filter-list .filter-row label.icon-home:before, .component.locations-spot .location-detail .icon-home.close-button:before {
  font-family: 'icomoon-yourhearing' !important;
  content: '\e914'; }

a.icomoon, .component.locations-spot .locations-search .icomoon.search-filter-button, .component.locations-spot .filter-list .icomoon.close-button, .component.locations-spot .filter-list .filter-row label.icomoon, .component.locations-spot .location-detail .icomoon.close-button {
  font-family: "Open Sans", "Arial", sans-serif !important;
  font-weight: 600; }
  a.icomoon::before, .component.locations-spot .locations-search .icomoon.search-filter-button::before, .component.locations-spot .filter-list .icomoon.close-button::before, .component.locations-spot .filter-list .filter-row label.icomoon::before, .component.locations-spot .location-detail .icomoon.close-button::before {
    font-weight: 400;
    font-size: 18px;
    text-indent: 0;
    transform: translate(15px); }
    @media (min-width: 992px) {
      a.icomoon::before, .component.locations-spot .locations-search .icomoon.search-filter-button::before, .component.locations-spot .filter-list .icomoon.close-button::before, .component.locations-spot .filter-list .filter-row label.icomoon::before, .component.locations-spot .location-detail .icomoon.close-button::before {
        transform: translate(0); } }

#wrapper .header {
  opacity: 0; }
  #wrapper .header.animate {
    opacity: 1; }
  #wrapper .header .desktop-section {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: static; }
    #wrapper .header .desktop-section > .inner-container {
      background-color: #ffffff; }
      #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content {
        margin: 0 auto;
        width: 100%;
        padding: 0; }
        @media (min-width: 1200px) {
          #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content {
            position: relative;
            width: 77rem;
            max-width: 1140px; } }
        @media (min-width: 992px) {
          #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content {
            padding: 0 1rem; } }
        #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          list-style-type: none;
          height: 3.125rem;
          font-size: 18px; }
          #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li {
            display: inline-flex;
            margin: 0;
            padding: 0;
            width: 100%; }
            @media (min-width: 1200px) {
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li {
                width: auto !important;
                float: none !important; } }
            #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li a, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .search-filter-button, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li label, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button,
            #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li div {
              display: flex;
              align-items: center;
              padding: 0 1rem;
              border-left: 1px solid rgba(255, 255, 255, 0.5);
              color: #ffffff;
              text-decoration: none;
              font-weight: normal; }
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li a::before, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button::before, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .search-filter-button::before, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .close-button::before, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button::before, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label::before, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li label::before, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .location-detail .close-button::before, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button::before,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li div::before {
                display: block;
                height: 1.25rem;
                width: 1.25rem;
                font-size: 18px;
                margin-right: 0.5rem; }
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li a:hover, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .search-filter-button:hover, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button:hover, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li label:hover, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button:hover,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li div:hover {
                background-color: rgba(255, 255, 255, 0.2); }
            @media (min-width: 992px) {
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li a[href^='tel']:link, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button[href^='tel']:link, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .search-filter-button[href^='tel']:link, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .close-button[href^='tel']:link, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button[href^='tel']:link, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label[href^='tel']:link, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li label[href^='tel']:link, #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .location-detail .close-button[href^='tel']:link, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button[href^='tel']:link,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li a[href^='tel']:visited,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button[href^='tel']:visited,
              .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .search-filter-button[href^='tel']:visited,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .close-button[href^='tel']:visited,
              .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button[href^='tel']:visited,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label[href^='tel']:visited,
              .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li label[href^='tel']:visited,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .location-detail .close-button[href^='tel']:visited,
              .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button[href^='tel']:visited,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li a[href^='tel']:hover,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button[href^='tel']:hover,
              .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .search-filter-button[href^='tel']:hover,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .close-button[href^='tel']:hover,
              .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button[href^='tel']:hover,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label[href^='tel']:hover,
              .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li label[href^='tel']:hover,
              #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .component.locations-spot .location-detail .close-button[href^='tel']:hover,
              .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-topbar .inner-container .contact-bar .component-content ul li .close-button[href^='tel']:hover {
                color: #ffffff;
                pointer-events: auto;
                cursor: default; } }
      #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container {
        padding: 2rem;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        display: flex;
        align-items: center;
        padding-top: 0.25rem;
        padding-bottom: 0; }
        @media (min-width: 1200px) {
          #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container {
            position: relative;
            width: 90.25rem;
            max-width: 1140px; } }
        #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image {
          display: flex;
          align-items: center; }
          #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image a, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .search-filter-button, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .close-button, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image label, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .close-button {
            display: block; }
            #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image a img, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .locations-search .search-filter-button img, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .search-filter-button img, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .filter-list .close-button img, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .close-button img, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .filter-list .filter-row label img, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image label img, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .location-detail .close-button img, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .close-button img {
              width: 110px;
              height: auto;
              padding: 1rem 0; }
              @media (min-width: 1200px) {
                #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image a img, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .locations-search .search-filter-button img, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .search-filter-button img, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .filter-list .close-button img, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .close-button img, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .filter-list .filter-row label img, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image label img, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .component.locations-spot .location-detail .close-button img, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.image .close-button img {
                  width: 145px; } }
        #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu {
          display: flex;
          align-items: center;
          justify-content: space-around; }
          #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu li {
            margin-top: 0;
            padding: 0 1.5rem; }
            #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu li:last-of-type {
              padding-right: 0; }
          #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu a, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .search-filter-button, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .close-button, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu label, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .close-button {
            color: #4d4d4d;
            font-family: "Open Sans", "Arial", sans-serif;
            font-weight: 600;
            font-size: 1rem;
            transition: all 0.15s ease;
            white-space: nowrap;
            -webkit-font-smoothing: antialiased; }
            @media (min-width: 1200px) {
              #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu a, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .search-filter-button, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .close-button, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu label, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .close-button {
                font-size: 18px;
                padding: 0; } }
            #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu a:hover, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .search-filter-button:hover, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .close-button:hover, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu label:hover, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .close-button:hover {
              color: #1890ba; }
            #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu a.active, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .locations-search .active.search-filter-button, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .active.search-filter-button, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .filter-list .active.close-button, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .active.close-button, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .filter-list .filter-row label.active, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu label.active, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .location-detail .active.close-button, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .active.close-button {
              color: #1890ba; }
            #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu a.current, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .locations-search .current.search-filter-button, .component.locations-spot .locations-search #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .current.search-filter-button, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .filter-list .current.close-button, .component.locations-spot .filter-list #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .current.close-button, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .filter-list .filter-row label.current, .component.locations-spot .filter-list .filter-row #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu label.current, #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .component.locations-spot .location-detail .current.close-button, .component.locations-spot .location-detail #wrapper .header .desktop-section > .inner-container .header-desktop-nav .inner-container .component.navigation ul.dgs-mega-menu .current.close-button {
              color: #1890ba; }
    #wrapper .header .desktop-section::after {
      height: auto;
      top: 0;
      box-shadow: none; }
  #wrapper .header .mobile-section .header-mobile-topbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 9999; }
    #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content {
      padding: 0; }
      @media (min-width: 992px) {
        #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content {
          padding: 0 1rem; } }
      #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        list-style-type: none;
        height: 3.125rem;
        font-size: 18px; }
        #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li {
          display: inline-flex;
          margin: 0;
          padding: 0;
          width: 100%; }
          @media (min-width: 1200px) {
            #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li {
              width: auto !important;
              float: none !important; } }
          #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li a, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .search-filter-button, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .close-button, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li label, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .close-button,
          #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li div {
            display: flex;
            align-items: center;
            padding: 0 1rem;
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            color: #ffffff;
            text-decoration: none;
            font-weight: normal; }
            #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li a::before, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button::before, .component.locations-spot .locations-search #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .search-filter-button::before, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .close-button::before, .component.locations-spot .filter-list #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .close-button::before, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label::before, .component.locations-spot .filter-list .filter-row #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li label::before, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .location-detail .close-button::before, .component.locations-spot .location-detail #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .close-button::before,
            #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li div::before {
              display: block;
              height: 1.25rem;
              width: 1.25rem;
              font-size: 18px;
              margin-right: 0.5rem; }
            #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li a:hover, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .search-filter-button:hover, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .close-button:hover, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li label:hover, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .close-button:hover,
            #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li div:hover {
              background-color: rgba(255, 255, 255, 0.2); }
      #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul {
        height: auto;
        font-size: 13px; }
        #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li {
          width: 100%; }
          #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li a, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .search-filter-button, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .close-button, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li label, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .close-button,
          #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li div {
            text-align: center;
            justify-content: center;
            padding: 2.6rem 0.5rem 0.75rem;
            position: relative;
            width: 100%; }
            #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li a::before, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button::before, .component.locations-spot .locations-search #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .search-filter-button::before, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .close-button::before, .component.locations-spot .filter-list #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .close-button::before, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label::before, .component.locations-spot .filter-list .filter-row #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li label::before, #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .component.locations-spot .location-detail .close-button::before, .component.locations-spot .location-detail #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li .close-button::before,
            #wrapper .header .mobile-section .header-mobile-topbar .rich-text-list.contact-bar .component-content ul li div::before {
              top: 0;
              position: absolute;
              left: 50%;
              margin-top: 0.75rem;
              transform: translate(-50%); }
  #wrapper .header .mobile-section .header-mobile-nav .inner-container {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    z-index: 100;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    @media (min-width: 1200px) {
      #wrapper .header .mobile-section .header-mobile-nav .inner-container {
        position: relative;
        width: 90.25rem;
        max-width: 1140px; } }
    #wrapper .header .mobile-section .header-mobile-nav .inner-container .image {
      display: flex;
      align-items: center;
      height: auto;
      width: 110px; }
    #wrapper .header .mobile-section .header-mobile-nav .inner-container .nav-expander {
      float: right;
      padding: 3% 3% 0 0; }
      #wrapper .header .mobile-section .header-mobile-nav .inner-container .nav-expander.nav-expanded > span:first-child {
        transform: rotate(-45deg) translate(-5px, 5px); }
      #wrapper .header .mobile-section .header-mobile-nav .inner-container .nav-expander.nav-expanded > span:nth-child(2) {
        transform: scaleX(0); }
      #wrapper .header .mobile-section .header-mobile-nav .inner-container .nav-expander.nav-expanded > span:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -5px); }
      #wrapper .header .mobile-section .header-mobile-nav .inner-container .nav-expander span {
        user-select: none;
        transition: transform 0.3s ease-in-out;
        background-color: #1890ba;
        width: 27px;
        height: 2px;
        display: block;
        margin: 5px 0; }
  #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #eaeaea;
    z-index: 10 !important; }
    #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu li {
      margin-top: 0;
      background-color: #ffffff;
      border-bottom: thin solid;
      border-bottom-color: #b4b5b7;
      text-align: center;
      padding: 0; }
      #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu li > a, #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu .component.locations-spot .locations-search li > .search-filter-button, .component.locations-spot .locations-search #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu li > .search-filter-button, #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu .component.locations-spot .filter-list li > .close-button, .component.locations-spot .filter-list #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu li > .close-button, #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu .component.locations-spot .filter-list .filter-row li > label, .component.locations-spot .filter-list .filter-row #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu li > label, #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu .component.locations-spot .location-detail li > .close-button, .component.locations-spot .location-detail #wrapper .header .mobile-section .header-mobile-burgermenu .inner-container .navigation .component-content ul.dgs-burger-menu li > .close-button {
        padding: 2rem;
        margin: 0;
        color: #1890ba;
        font-size: 15px;
        font-weight: "Open Sans", "Arial", sans-serif;
        width: 100%; }

#footer {
  border-top: 3px solid #f0f0f0;
  padding-bottom: 66px; }
  @media (min-width: 768px) {
    #footer {
      padding-bottom: 0; } }
  #footer .rich-text-list {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
    #footer .rich-text-list .component-content {
      padding: 2em 0; }
      @media (min-width: 992px) {
        #footer .rich-text-list .component-content {
          padding: 0; } }
      @media (min-width: 992px) {
        #footer .rich-text-list .component-content ul {
          display: flex;
          align-items: center;
          justify-content: space-between; } }
      @media (min-width: 992px) {
        #footer .rich-text-list .component-content ul li {
          width: auto;
          white-space: nowrap; } }
      #footer .rich-text-list .component-content ul.yh-footer-navigation,
      #footer .rich-text-list .component-content ul.footer-navigation {
        text-align: center;
        width: 100%; }
        @media (min-width: 768px) {
          #footer .rich-text-list .component-content ul.yh-footer-navigation,
          #footer .rich-text-list .component-content ul.footer-navigation {
            display: flex;
            align-items: center;
            justify-content: center; } }
        @media (min-width: 768px) {
          #footer .rich-text-list .component-content ul.yh-footer-navigation li,
          #footer .rich-text-list .component-content ul.footer-navigation li {
            text-indent: 0;
            margin: 0; } }
        #footer .rich-text-list .component-content ul.yh-footer-navigation a, #footer .rich-text-list .component-content ul.yh-footer-navigation .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #footer .rich-text-list .component-content ul.yh-footer-navigation .search-filter-button, #footer .rich-text-list .component-content ul.yh-footer-navigation .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #footer .rich-text-list .component-content ul.yh-footer-navigation .close-button, #footer .rich-text-list .component-content ul.yh-footer-navigation .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #footer .rich-text-list .component-content ul.yh-footer-navigation label, #footer .rich-text-list .component-content ul.yh-footer-navigation .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #footer .rich-text-list .component-content ul.yh-footer-navigation .close-button,
        #footer .rich-text-list .component-content ul.footer-navigation a,
        #footer .rich-text-list .component-content ul.footer-navigation .component.locations-spot .locations-search .search-filter-button,
        .component.locations-spot .locations-search #footer .rich-text-list .component-content ul.footer-navigation .search-filter-button,
        #footer .rich-text-list .component-content ul.footer-navigation .component.locations-spot .filter-list .close-button,
        .component.locations-spot .filter-list #footer .rich-text-list .component-content ul.footer-navigation .close-button,
        #footer .rich-text-list .component-content ul.footer-navigation .component.locations-spot .filter-list .filter-row label,
        .component.locations-spot .filter-list .filter-row #footer .rich-text-list .component-content ul.footer-navigation label,
        #footer .rich-text-list .component-content ul.footer-navigation .component.locations-spot .location-detail .close-button,
        .component.locations-spot .location-detail #footer .rich-text-list .component-content ul.footer-navigation .close-button {
          display: block;
          font-weight: 400; }
      #footer .rich-text-list .component-content div.yh-footer-copyright {
        text-align: center;
        color: #999999; }
      #footer .rich-text-list .component-content ul.yh-footer-socialmedia {
        display: flex;
        align-items: center;
        justify-content: center; }
        #footer .rich-text-list .component-content ul.yh-footer-socialmedia li {
          padding: 0 !important;
          margin: 0 !important;
          border: 0 !important; }
          #footer .rich-text-list .component-content ul.yh-footer-socialmedia li .icomoon {
            width: 26px;
            font-size: 26px;
            display: block;
            text-indent: 0;
            margin: 0 5px; }
            @media (min-width: 1200px) {
              #footer .rich-text-list .component-content ul.yh-footer-socialmedia li .icomoon {
                margin: 0 0.5em; } }
          #footer .rich-text-list .component-content ul.yh-footer-socialmedia li .icon-facebook {
            color: #3b5998; }
          #footer .rich-text-list .component-content ul.yh-footer-socialmedia li .icon-twitter {
            color: #38a1f3; }
          #footer .rich-text-list .component-content ul.yh-footer-socialmedia li .icon-google {
            color: #cc3333; }
    #footer .rich-text-list .component-content > ul > li ul li:not(:last-child) {
      padding-bottom: 1rem;
      border-bottom: 1px solid #999999; }
      @media (min-width: 768px) {
        #footer .rich-text-list .component-content > ul > li ul li:not(:last-child) {
          border-bottom: 0;
          border-right: 1px solid #999999;
          padding: 0.1em 1em; } }

input[type='submit'].button-orange,
button.button-orange,
.button-orange {
  background-color: #f7941d;
  border-radius: 4px;
  border: 0;
  border-bottom: 1px solid #d97a08;
  color: #ffffff;
  padding: 0.5rem 2rem;
  transition: background-color 0.15s ease; }
  input[type='submit'].button-orange:hover,
  button.button-orange:hover,
  .button-orange:hover {
    background-color: #d97a08; }
  input[type='submit'].button-orange:focus,
  button.button-orange:focus,
  .button-orange:focus {
    box-shadow: 0px 0px 6px 0px #fac380; }

input[type='submit'].button-blue,
button.button-blue,
.button-blue {
  background-color: #1890ba;
  border-radius: 4px;
  border: 0;
  border-bottom: 1px solid #126d8d;
  color: #ffffff;
  padding: 0.5rem 2rem;
  transition: background-color 0.15s ease; }
  input[type='submit'].button-blue:hover,
  button.button-blue:hover,
  .button-blue:hover {
    background-color: #126d8d; }
  input[type='submit'].button-blue:focus,
  button.button-blue:focus,
  .button-blue:focus {
    box-shadow: 0px 0px 6px 0px #fac380; }

input[type='submit'].button-white,
button.button-white,
.button-white {
  background-color: #ffffff;
  border-radius: 4px;
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  color: #ffffff;
  padding: 0.5rem 2rem;
  transition: background-color 0.15s ease;
  color: #1890ba;
  font-size: 1.2143rem;
  display: inline-block; }
  input[type='submit'].button-white:hover,
  button.button-white:hover,
  .button-white:hover {
    background-color: #e6e6e6; }
  input[type='submit'].button-white:focus,
  button.button-white:focus,
  .button-white:focus {
    box-shadow: 0px 0px 6px 0px #fac380; }

input[type='submit'].button-gray,
button.button-gray,
.button-gray {
  background-color: #808285;
  border-radius: 4px;
  border: 0;
  border-bottom: 1px solid #67696b;
  color: #ffffff;
  padding: 0.5rem 2rem;
  transition: background-color 0.15s ease; }
  input[type='submit'].button-gray:hover,
  button.button-gray:hover,
  .button-gray:hover {
    background-color: #67696b; }
  input[type='submit'].button-gray:focus,
  button.button-gray:focus,
  .button-gray:focus {
    box-shadow: 0px 0px 6px 0px #fac380; }

input[type='submit'].button-outline-orange,
button.button-outline-orange,
.button-outline-orange {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  color: #f7941d;
  padding: 0.5rem 2rem;
  transition: background-color 0.3s ease; }
  input[type='submit'].button-outline-orange:hover,
  button.button-outline-orange:hover,
  .button-outline-orange:hover {
    background-color: transparent;
    border-color: #999999;
    color: #c16c07; }
  input[type='submit'].button-outline-orange:focus,
  button.button-outline-orange:focus,
  .button-outline-orange:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }

input[type='submit'].button-outline-blue,
button.button-outline-blue,
.button-outline-blue {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  color: #1890ba;
  padding: 0.5rem 2rem;
  transition: background-color 0.3s ease; }
  input[type='submit'].button-outline-blue:hover,
  button.button-outline-blue:hover,
  .button-outline-blue:hover {
    background-color: transparent;
    border-color: #999999;
    color: #0f5c76; }
  input[type='submit'].button-outline-blue:focus,
  button.button-outline-blue:focus,
  .button-outline-blue:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }

input[type='submit'].button-outline-gray,
button.button-outline-gray,
.button-outline-gray {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  color: #808285;
  padding: 0.5rem 2rem;
  transition: background-color 0.3s ease; }
  input[type='submit'].button-outline-gray:hover,
  button.button-outline-gray:hover,
  .button-outline-gray:hover {
    background-color: transparent;
    border-color: #999999;
    color: #5a5c5e; }
  input[type='submit'].button-outline-gray:focus,
  button.button-outline-gray:focus,
  .button-outline-gray:focus {
    box-shadow: 0px 0px 6px 0px #1890ba; }

button,
.button,
.yh-button a,
.yh-button .component.locations-spot .locations-search .search-filter-button,
.component.locations-spot .locations-search .yh-button .search-filter-button,
.yh-button .component.locations-spot .filter-list .close-button,
.component.locations-spot .filter-list .yh-button .close-button,
.yh-button .component.locations-spot .filter-list .filter-row label,
.component.locations-spot .filter-list .filter-row .yh-button label,
.yh-button .component.locations-spot .location-detail .close-button,
.component.locations-spot .location-detail .yh-button .close-button,
.image-spot-element-link a,
.image-spot-element-link .component.locations-spot .locations-search .search-filter-button,
.component.locations-spot .locations-search .image-spot-element-link .search-filter-button,
.image-spot-element-link .component.locations-spot .filter-list .close-button,
.component.locations-spot .filter-list .image-spot-element-link .close-button,
.image-spot-element-link .component.locations-spot .filter-list .filter-row label,
.component.locations-spot .filter-list .filter-row .image-spot-element-link label,
.image-spot-element-link .component.locations-spot .location-detail .close-button,
.component.locations-spot .location-detail .image-spot-element-link .close-button {
  display: block;
  background-color: #f7941d;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  border-bottom: 1px solid #d97a08;
  padding: 0.5rem 2rem;
  transition: background-color 0.3s ease; }
  button:hover,
  .button:hover,
  .yh-button a:hover,
  .yh-button .component.locations-spot .locations-search .search-filter-button:hover,
  .component.locations-spot .locations-search .yh-button .search-filter-button:hover,
  .yh-button .component.locations-spot .filter-list .close-button:hover,
  .component.locations-spot .filter-list .yh-button .close-button:hover,
  .yh-button .component.locations-spot .filter-list .filter-row label:hover,
  .component.locations-spot .filter-list .filter-row .yh-button label:hover,
  .yh-button .component.locations-spot .location-detail .close-button:hover,
  .component.locations-spot .location-detail .yh-button .close-button:hover,
  .image-spot-element-link a:hover,
  .image-spot-element-link .component.locations-spot .locations-search .search-filter-button:hover,
  .component.locations-spot .locations-search .image-spot-element-link .search-filter-button:hover,
  .image-spot-element-link .component.locations-spot .filter-list .close-button:hover,
  .component.locations-spot .filter-list .image-spot-element-link .close-button:hover,
  .image-spot-element-link .component.locations-spot .filter-list .filter-row label:hover,
  .component.locations-spot .filter-list .filter-row .image-spot-element-link label:hover,
  .image-spot-element-link .component.locations-spot .location-detail .close-button:hover,
  .component.locations-spot .location-detail .image-spot-element-link .close-button:hover {
    background-color: #d97a08;
    color: #ffffff; }
  button:focus,
  .button:focus,
  .yh-button a:focus,
  .yh-button .component.locations-spot .locations-search .search-filter-button:focus,
  .component.locations-spot .locations-search .yh-button .search-filter-button:focus,
  .yh-button .component.locations-spot .filter-list .close-button:focus,
  .component.locations-spot .filter-list .yh-button .close-button:focus,
  .yh-button .component.locations-spot .filter-list .filter-row label:focus,
  .component.locations-spot .filter-list .filter-row .yh-button label:focus,
  .yh-button .component.locations-spot .location-detail .close-button:focus,
  .component.locations-spot .location-detail .yh-button .close-button:focus,
  .image-spot-element-link a:focus,
  .image-spot-element-link .component.locations-spot .locations-search .search-filter-button:focus,
  .component.locations-spot .locations-search .image-spot-element-link .search-filter-button:focus,
  .image-spot-element-link .component.locations-spot .filter-list .close-button:focus,
  .component.locations-spot .filter-list .image-spot-element-link .close-button:focus,
  .image-spot-element-link .component.locations-spot .filter-list .filter-row label:focus,
  .component.locations-spot .filter-list .filter-row .image-spot-element-link label:focus,
  .image-spot-element-link .component.locations-spot .location-detail .close-button:focus,
  .component.locations-spot .location-detail .image-spot-element-link .close-button:focus {
    box-shadow: 0px 0px 6px 0px #fac380; }

a.button, .component.locations-spot .locations-search .button.search-filter-button, .component.locations-spot .filter-list .button.close-button, .component.locations-spot .filter-list .filter-row label.button, .component.locations-spot .location-detail .button.close-button {
  font-color: #ffffff;
  font-size: 1.2143rem;
  font-weight: 400;
  font-style: normal;
  display: inline-block; }

.component.data-hierarchy-spot.ui-elements {
  position: relative; }
  .component.data-hierarchy-spot.ui-elements .component-content {
    margin: 0 auto;
    width: 100%; }
    @media (min-width: 1200px) {
      .component.data-hierarchy-spot.ui-elements .component-content {
        position: relative;
        width: 77rem;
        max-width: 1140px; } }
  .component.data-hierarchy-spot.ui-elements .section-navigation {
    position: absolute;
    left: -2rem;
    transform: translateX(-100%);
    text-align: right; }
    .component.data-hierarchy-spot.ui-elements .section-navigation.sticky {
      position: fixed;
      top: 11rem;
      left: 2rem;
      text-align: left;
      transform: none; }
    .component.data-hierarchy-spot.ui-elements .section-navigation .navigation {
      margin-bottom: 0.5rem;
      font-size: 0.8rem; }
      .component.data-hierarchy-spot.ui-elements .section-navigation .navigation a.active, .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .component.locations-spot .locations-search .active.search-filter-button, .component.locations-spot .locations-search .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .active.search-filter-button, .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .component.locations-spot .filter-list .active.close-button, .component.locations-spot .filter-list .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .active.close-button, .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .component.locations-spot .filter-list .filter-row label.active, .component.locations-spot .filter-list .filter-row .component.data-hierarchy-spot.ui-elements .section-navigation .navigation label.active, .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .component.locations-spot .location-detail .active.close-button, .component.locations-spot .location-detail .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .active.close-button {
        font-family: "Open Sans", "Arial", sans-serif;
        font-weight: 700;
        font-style: normal; }
        @media all and (-ms-high-contrast: none) {
          .component.data-hierarchy-spot.ui-elements .section-navigation .navigation a.active, .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .component.locations-spot .locations-search .active.search-filter-button, .component.locations-spot .locations-search .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .active.search-filter-button, .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .component.locations-spot .filter-list .active.close-button, .component.locations-spot .filter-list .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .active.close-button, .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .component.locations-spot .filter-list .filter-row label.active, .component.locations-spot .filter-list .filter-row .component.data-hierarchy-spot.ui-elements .section-navigation .navigation label.active, .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .component.locations-spot .location-detail .active.close-button, .component.locations-spot .location-detail .component.data-hierarchy-spot.ui-elements .section-navigation .navigation .active.close-button {
            font-weight: bold; } }
  .component.data-hierarchy-spot.ui-elements .section {
    clear: both; }
  .component.data-hierarchy-spot.ui-elements .header {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.3;
    color: inherit;
    margin-bottom: 1rem; }
    @media all and (-ms-high-contrast: none) {
      .component.data-hierarchy-spot.ui-elements .header {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      .component.data-hierarchy-spot.ui-elements .header {
        font-size: 1.25rem; } }
    .component.data-hierarchy-spot.ui-elements .header .header__underline {
      height: 1px;
      width: 10rem;
      background-color: #f0f0f0;
      margin-top: 0.5rem; }
  .component.data-hierarchy-spot.ui-elements .color {
    display: block;
    float: left;
    position: relative;
    width: 10rem;
    height: 10rem;
    padding: 0.5rem;
    margin: 0 2rem 2rem 0; }
    .component.data-hierarchy-spot.ui-elements .color.white-color-border {
      border: thin solid #BBB; }
    .component.data-hierarchy-spot.ui-elements .color:nth-child(3n+3) {
      clear: both; }
    .component.data-hierarchy-spot.ui-elements .color .color__name {
      position: absolute;
      bottom: 2rem; }
    .component.data-hierarchy-spot.ui-elements .color .color__hexcode {
      position: absolute;
      bottom: 0.5rem; }
  .component.data-hierarchy-spot.ui-elements .type {
    margin-bottom: 2rem; }
    .component.data-hierarchy-spot.ui-elements .type .type__description {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 0.8rem;
      margin-top: 0.5rem; }
  .component.data-hierarchy-spot.ui-elements .font {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 2rem; }
    .component.data-hierarchy-spot.ui-elements .font.light {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 300;
      font-style: normal; }
    .component.data-hierarchy-spot.ui-elements .font.regular {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 400;
      font-style: normal; }
    .component.data-hierarchy-spot.ui-elements .font.italic {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 400;
      font-style: normal; }
    .component.data-hierarchy-spot.ui-elements .font.medium {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 600;
      font-style: normal; }
    .component.data-hierarchy-spot.ui-elements .font.bold {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal; }
      @media all and (-ms-high-contrast: none) {
        .component.data-hierarchy-spot.ui-elements .font.bold {
          font-weight: bold; } }
  .component.data-hierarchy-spot.ui-elements .button-element {
    margin: 0 2rem 2rem 0;
    display: inline-block; }
  .component.data-hierarchy-spot.ui-elements .form-element {
    margin-bottom: 2rem; }
    .component.data-hierarchy-spot.ui-elements .form-element .form-element__description {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 0.8rem;
      margin-top: 0.5rem; }

label {
  display: block;
  padding-bottom: 0.5rem; }

input:not([type='radio']):not([type='checkbox']):not([type='submit']),
textarea,
select {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 2px solid #b4b5b7;
  border-radius: 4px;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #4d4d4d;
  line-height: 1;
  background-color: transparent; }
  input:not([type='radio']):not([type='checkbox']):not([type='submit']):focus,
  textarea:focus,
  select:focus {
    border-color: #f7941d; }

input[type="submit"],
button,
.like-button {
  background-color: #f7941d;
  border-radius: 4px;
  border: 0;
  border-bottom: 1px solid #d97a08;
  color: #ffffff;
  padding: 0.5rem 2rem;
  transition: background-color 0.15s ease; }
  input[type="submit"]:hover,
  button:hover,
  .like-button:hover {
    background-color: #d97a08; }
  input[type="submit"]:focus,
  button:focus,
  .like-button:focus {
    box-shadow: 0px 0px 6px 0px #fac380; }

.like-button-negative {
  padding-left: 2rem;
  padding-right: 2rem;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
  transition: all 0.2s;
  -webkit-appearance: none; }
  .like-button-negative:not(.disabled) {
    cursor: pointer; }
  .like-button-negative a, .like-button-negative .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .like-button-negative .search-filter-button, .like-button-negative .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .like-button-negative .close-button, .like-button-negative .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .like-button-negative label, .like-button-negative .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .like-button-negative .close-button {
    color: #fff;
    text-decoration: none;
    background-color: transparent; }
  .like-button-negative:not(.disabled):hover {
    color: #fff;
    background-color: #4d4d4d;
    text-shadow: none; }
    .like-button-negative:not(.disabled):hover a, .like-button-negative:not(.disabled):hover .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .like-button-negative:not(.disabled):hover .search-filter-button, .like-button-negative:not(.disabled):hover .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .like-button-negative:not(.disabled):hover .close-button, .like-button-negative:not(.disabled):hover .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .like-button-negative:not(.disabled):hover label, .like-button-negative:not(.disabled):hover .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .like-button-negative:not(.disabled):hover .close-button {
      text-decoration: none;
      color: #fff;
      text-shadow: none; }

.component.form .component-content .scfTitleBorder {
  font-size: 2.13rem;
  line-height: 1.2;
  color: inherit;
  margin: 0 0 1rem 0; }
  @media only screen and (max-width: 767px) {
    .component.form .component-content .scfTitleBorder {
      font-size: 2rem; } }

.component.form .component-content .scfSectionBorder {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.component.form .component-content .scfDroplistBorder,
.component.form .component-content .scfEmailBorder,
.component.form .component-content .scfMultipleLineTextBorder,
.component.form .component-content .scfSingleLineTextBorder,
.component.form .component-content .scfPasswordBorder,
.component.form .component-content .scfNumberBorder,
.component.form .component-content .scfDatePickerBorder,
.component.form .component-content .scfDateBorder,
.component.form .component-content .scfRadioButtonListBorder,
.component.form .component-content .scfListBoxBorder,
.component.form .component-content .scfCheckBoxListBorder,
.component.form .component-content .scfFileUploadBorder,
.component.form .component-content .scfDateSelectorBorder,
.component.form .component-content .scfCreditCardBorder,
.component.form .component-content .scfConfirmPasswordBorder,
.component.form .component-content .scfCaptchaBorder,
.component.form .component-content .scfTelephoneBorder,
.component.form .component-content .scfSmsTelephoneBorder {
  margin-bottom: 1.33333rem;
  margin-top: 0;
  position: relative; }

.component.form .component-content .scfDropListLabel,
.component.form .component-content .scfEmailLabel,
.component.form .component-content .scfMultipleLineTextLabel,
.component.form .component-content .scfSingleLineTextLabel,
.component.form .component-content .scfPasswordLabel,
.component.form .component-content .scfNumberLabel,
.component.form .component-content .scfDatePickerLabel,
.component.form .component-content .scfDateLabel,
.component.form .component-content .scfRadioButtonListLabel,
.component.form .component-content .scfCheckBoxListLabel,
.component.form .component-content .scfListBoxLabel,
.component.form .component-content .scfFileUploadLabel,
.component.form .component-content .scfDateSelectorLabel,
.component.form .component-content .scfCreditCardLabel,
.component.form .component-content .scfConfirmPasswordLabel,
.component.form .component-content .scfCaptchaLabel,
.component.form .component-content .scfTelephoneLabel,
.component.form .component-content .scfSmsTelephoneLabel {
  float: none;
  clear: none;
  width: 100%;
  padding: 0 0 0.5rem 0; }

.component.form .component-content .scfEmailGeneralPanel,
.component.form .component-content .scfMultipleLineGeneralPanel,
.component.form .component-content .scfSingleLineGeneralPanel,
.component.form .component-content .scfPasswordGeneralPanel,
.component.form .component-content .scfNumberGeneralPanel,
.component.form .component-content .scfDatePickerGeneralPanel,
.component.form .component-content .scfDateGeneralPanel,
.component.form .component-content .scfRadioButtonListGeneralPanel,
.component.form .component-content .scfCheckBoxListGeneralPanel,
.component.form .component-content .scfFileUploadGeneralPanel,
.component.form .component-content .scfDateSelectorGeneralPanel,
.component.form .component-content .scfCreditCardGeneralPanel,
.component.form .component-content .scfConfirmPasswordGeneralPanel,
.component.form .component-content .scfCaptchaGeneralPanel,
.component.form .component-content .scfTelephoneGeneralPanel,
.component.form .component-content .scfSmsTelephoneGeneralPanel {
  float: none;
  clear: none;
  width: 100%; }

.component.form .component-content .scfValidatorRequired,
.component.form .component-content .scfRequired {
  float: none;
  position: absolute;
  right: 0;
  top: 0.5rem; }

.component.form .component-content .scfForm input:not([type='checkbox']):not([type='radio']):not([type='submit']),
.component.form .component-content .scfForm select,
.component.form .component-content .scfForm textarea {
  width: 100%; }

.component.form .component-content .scfSubmitButtonBorder {
  text-align: center; }

.component.form .component-content .scfListBoxGeneralPanel,
.component.form .component-content .scfDropListGeneralPanel {
  width: 100%;
  float: none; }

.component.form .component-content .scfError,
.component.form .component-content .scfValidationSummary,
.component.form .component-content .scfValidatorRequired,
.component.form .component-content .scfValidator {
  color: #f74407; }

.component.form .component-content .scfValidationSummary {
  margin-left: 0; }
  .component.form .component-content .scfValidationSummary:not(:empty) {
    margin-bottom: 1rem; }

.component.form .component-content .scfSectionBorderAsFieldSet {
  padding: 0;
  border: 0;
  margin: 0 0 1rem 0; }

.component.form .component-content .scfSectionLegend {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.3;
  color: inherit;
  padding: 0;
  margin: 0;
  margin-bottom: 0.66667rem; }
  @media all and (-ms-high-contrast: none) {
    .component.form .component-content .scfSectionLegend {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    .component.form .component-content .scfSectionLegend {
      font-size: 1.25rem; } }

.component.form .component-content .scfHiddenFieldBorder {
  display: none; }

.component.form .component-content input[type="checkbox"] + label,
.component.form .component-content input[type="radio"] + label {
  margin-left: 0.3rem; }

#wrapper .image.component.center-image {
  text-align: center; }

#wrapper .image.component a, #wrapper .image.component .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper .image.component .search-filter-button, #wrapper .image.component .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper .image.component .close-button, #wrapper .image.component .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper .image.component label, #wrapper .image.component .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper .image.component .close-button {
  display: inline-block;
  max-width: 100%; }

#wrapper .image.component .image-caption {
  display: block;
  font-style: italic;
  font-size: 11px; }

.image-spot .component-content .image-spot-elements .image-spot-element-text, .image-spot .component-content .image-spot-elements .image-spot-element-header {
  max-width: 80%;
  margin: auto; }

.image-spot {
  margin-bottom: 2rem; }
  .image-spot .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto; }
    .image-spot .component-content ul {
      list-style: none; }
    .image-spot .component-content li {
      margin-top: 0;
      line-height: normal; }
    @media (min-width: 1200px) {
      .image-spot .component-content {
        position: relative;
        width: 90.25rem;
        max-width: 1140px; } }
    .image-spot .component-content .image-spot-elements {
      text-align: center;
      position: relative; }
      .image-spot .component-content .image-spot-elements .image-spot-element {
        list-style: none;
        margin-bottom: 2rem;
        overflow: hidden;
        text-indent: 0;
        padding-left: 0; }
      .image-spot .component-content .image-spot-elements .image-spot-element-image {
        padding: 0 25%; }
        .image-spot .component-content .image-spot-elements .image-spot-element-image * {
          max-width: 100%;
          height: auto;
          width: auto; }
        .image-spot .component-content .image-spot-elements .image-spot-element-image:not(:empty) {
          margin-bottom: 1rem; }
      .image-spot .component-content .image-spot-elements .image-spot-element-link:not(:empty) {
        margin-top: 1rem; }
  .image-spot.slim .component-content {
    margin: 0 auto;
    width: 100%; }
    @media (min-width: 1200px) {
      .image-spot.slim .component-content {
        position: relative;
        width: 77rem;
        max-width: 1140px; } }
  .image-spot.round-spot .image-spot-element-image img {
    display: inline-block;
    border-radius: 50%; }

@media only screen and (min-width: 992px) {
  .image-spot .component-content .image-spot-elements {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */
    /* two items */
    /* three items */
    /* four items */
    /* five items */ }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(6),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(7),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(8),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(9),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(10),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li .image-spot-element-image {
      padding: 0 4rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li .image-spot-element-image {
      padding: 0 2rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li .image-spot-element-image {
      padding: 0 1.5rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li .image-spot-element-image {
      padding: 0 1rem; }
    .image-spot .component-content .image-spot-elements .image-spot-element {
      margin-bottom: 0; }
  .image-spot .component-content .image-spot-element-context {
    display: none; } }

.component.intro-banner {
  padding-top: 0;
  padding-bottom: 0; }
  .component.intro-banner .component-content {
    position: relative; }
  .component.intro-banner .intro-banner-image {
    width: 100%; }
    .component.intro-banner .intro-banner-image img {
      max-width: 100%;
      height: auto;
      display: block;
      width: 100%; }
  .component.intro-banner .intro-banner-content {
    position: absolute;
    top: 2rem;
    bottom: 2rem;
    left: 0;
    right: 0; }
  .component.intro-banner .outer-pos-wrapper {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    height: 100%; }
    @media (min-width: 1200px) {
      .component.intro-banner .outer-pos-wrapper {
        position: relative;
        width: 90.25rem;
        max-width: 1140px; } }
  .component.intro-banner .inner-pos-wrapper {
    width: 100%;
    position: relative;
    display: inline-block; }
    @media only screen and (min-width: 992px) {
      .component.intro-banner .inner-pos-wrapper {
        width: 55%; } }
  .component.intro-banner .intro-banner-link {
    display: none; }
  .component.intro-banner .sticky-downlink {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center; }
    .component.intro-banner .sticky-downlink a, .component.intro-banner .sticky-downlink .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.intro-banner .sticky-downlink .search-filter-button, .component.intro-banner .sticky-downlink .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.intro-banner .sticky-downlink .close-button, .component.intro-banner .sticky-downlink .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.intro-banner .sticky-downlink label, .component.intro-banner .sticky-downlink .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.intro-banner .sticky-downlink .close-button {
      display: inline-block;
      transition: all 0.2s;
      color: inherit; }
      .component.intro-banner .sticky-downlink a:before, .component.intro-banner .sticky-downlink .component.locations-spot .locations-search .search-filter-button:before, .component.locations-spot .locations-search .component.intro-banner .sticky-downlink .search-filter-button:before, .component.intro-banner .sticky-downlink .component.locations-spot .filter-list .close-button:before, .component.locations-spot .filter-list .component.intro-banner .sticky-downlink .close-button:before, .component.intro-banner .sticky-downlink .component.locations-spot .filter-list .filter-row label:before, .component.locations-spot .filter-list .filter-row .component.intro-banner .sticky-downlink label:before, .component.intro-banner .sticky-downlink .component.locations-spot .location-detail .close-button:before, .component.locations-spot .location-detail .component.intro-banner .sticky-downlink .close-button:before {
        font-family: "DGS-WebProgram";
        content: "";
        font-size: 4rem;
        line-height: 1;
        color: inherit; }
      .component.intro-banner .sticky-downlink a:hover, .component.intro-banner .sticky-downlink .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search .component.intro-banner .sticky-downlink .search-filter-button:hover, .component.intro-banner .sticky-downlink .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list .component.intro-banner .sticky-downlink .close-button:hover, .component.intro-banner .sticky-downlink .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row .component.intro-banner .sticky-downlink label:hover, .component.intro-banner .sticky-downlink .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail .component.intro-banner .sticky-downlink .close-button:hover {
        margin-bottom: -0.5rem; }
      .component.intro-banner .sticky-downlink a span, .component.intro-banner .sticky-downlink .component.locations-spot .locations-search .search-filter-button span, .component.locations-spot .locations-search .component.intro-banner .sticky-downlink .search-filter-button span, .component.intro-banner .sticky-downlink .component.locations-spot .filter-list .close-button span, .component.locations-spot .filter-list .component.intro-banner .sticky-downlink .close-button span, .component.intro-banner .sticky-downlink .component.locations-spot .filter-list .filter-row label span, .component.locations-spot .filter-list .filter-row .component.intro-banner .sticky-downlink label span, .component.intro-banner .sticky-downlink .component.locations-spot .location-detail .close-button span, .component.locations-spot .location-detail .component.intro-banner .sticky-downlink .close-button span {
        display: none; }
  .component.intro-banner.middle .inner-pos-wrapper {
    top: 50%;
    transform: translateY(-50%); }
  .component.intro-banner.bottom .inner-pos-wrapper {
    top: 100%;
    transform: translateY(-100%); }
  .component.intro-banner.right .outer-pos-wrapper {
    text-align: right; }
  .component.intro-banner.center .outer-pos-wrapper {
    text-align: center; }
  .component.intro-banner.content-overflow .intro-banner-content {
    position: static;
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.content-overflow .intro-banner-content {
        padding-top: 1rem; } }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.content-overflow .intro-banner-content {
        padding-bottom: 1rem; } }
  .component.intro-banner.content-overflow .inner-pos-wrapper {
    position: static;
    transform: none; }

.component.latest-blog-posts ul#blog-list {
  padding-bottom: 4rem;
  justify-content: space-between;
  margin: 0; }
  @media (min-width: 992px) {
    .component.latest-blog-posts ul#blog-list {
      padding-bottom: 0; } }
  .component.latest-blog-posts ul#blog-list li {
    margin: 2rem 0; }
    @media (min-width: 1200px) {
      .component.latest-blog-posts ul#blog-list li {
        margin: 0;
        padding: 0;
        width: 26.691729323308273%; } }
  .component.latest-blog-posts ul#blog-list .news-image img {
    width: 100%; }
  .component.latest-blog-posts ul#blog-list .news-title {
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.3;
    color: inherit;
    display: block;
    margin-top: 1rem; }
    @media all and (-ms-high-contrast: none) {
      .component.latest-blog-posts ul#blog-list .news-title {
        font-weight: bold; } }
    @media only screen and (max-width: 767px) {
      .component.latest-blog-posts ul#blog-list .news-title {
        font-size: 1.25rem; } }
    @media (min-width: 992px) {
      .component.latest-blog-posts ul#blog-list .news-title {
        font-family: "Open Sans", "Arial", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 1.067rem;
        line-height: 1.3;
        color: inherit; } }
  @media all and (min-width: 992px) and (-ms-high-contrast: none) {
    .component.latest-blog-posts ul#blog-list .news-title {
      font-weight: bold; } }
  @media only screen and (min-width: 992px) and (max-width: 767px) {
    .component.latest-blog-posts ul#blog-list .news-title {
      font-size: 1rem; } }
  .component.latest-blog-posts ul#blog-list .news-text {
    display: none; }
  .component.latest-blog-posts ul#blog-list .news-date {
    display: none; }
  .component.latest-blog-posts ul#blog-list .news-link {
    display: none; }

.component.link-list .component-content {
  width: auto;
  max-width: 100%;
  margin: 0; }
  .component.link-list .component-content li {
    list-style-type: none;
    border-bottom: 1px solid #cecfd0;
    padding: 1rem 0;
    text-indent: 0; }
    .component.link-list .component-content li:last-child {
      border-bottom: 0; }

.component.rich-text .component-content {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .component.rich-text .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

.component.rich-text.slim .component-content {
  margin: 0 auto;
  width: 100%; }
  @media (min-width: 1200px) {
    .component.rich-text.slim .component-content {
      position: relative;
      width: 77rem;
      max-width: 1140px; } }

.component.rich-text.overlay {
  padding: 0 !important;
  opacity: 0 !important;
  position: relative;
  transition: opacity 0.1s ease-in; }
  .component.rich-text.overlay .component-content {
    transition: all 0.2s ease-in;
    position: absolute;
    top: 0;
    left: -25%;
    right: 100%;
    transform: translateY(-100%);
    padding: 1rem;
    background-color: black;
    color: #ffffff;
    margin: 0;
    width: auto;
    max-width: none; }
    .component.rich-text.overlay .component-content * {
      color: white; }
    .component.rich-text.overlay .component-content h1,
    .component.rich-text.overlay .component-content h2,
    .component.rich-text.overlay .component-content h3,
    .component.rich-text.overlay .component-content h4 {
      font-size: 1rem; }
    .component.rich-text.overlay .component-content p {
      font-size: 0.8rem; }
  .component.rich-text.overlay.active {
    opacity: 1 !important; }
    .component.rich-text.overlay.active .component-content {
      left: 0;
      right: 75%; }

.on-page-editor #wrapper > #content .component.rich-text {
  min-height: 100px; }

.component.rich-text.rich-text--alert-banner .component-content {
  padding: 1rem 0; }

.component.rich-text-list .component-content {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .component.rich-text-list .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }
  .component.rich-text-list .component-content:before, .component.rich-text-list .component-content:after {
    content: '';
    clear: both;
    display: table; }
  .component.rich-text-list .component-content > ul {
    list-style: none; }
    .component.rich-text-list .component-content > ul > li {
      width: 100%;
      text-indent: 0;
      padding-left: 0; }
      .component.rich-text-list .component-content > ul > li + li {
        margin-top: 2rem; }
      .component.rich-text-list .component-content > ul > li ul {
        list-style: none; }
        .component.rich-text-list .component-content > ul > li ul li + li {
          margin-top: 0.7rem; }
        .component.rich-text-list .component-content > ul > li ul li:not(:last-child) {
          padding-bottom: 0.7rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
      .component.rich-text-list .component-content > ul > li:not(:last-child) {
        padding-right: 2rem; }

@media only screen and (min-width: 992px) {
  .component.rich-text-list .component-content > ul {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */ }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(2),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(3),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(5),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(6),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(7),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(8),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(9),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(10),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
    .component.rich-text-list .component-content > ul > li + li {
      margin-top: 0; } }

.rich-text-list.contact-bar .component-content ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style-type: none;
  height: 3.125rem;
  font-size: 18px; }
  .rich-text-list.contact-bar .component-content ul li {
    display: inline-flex;
    margin: 0;
    padding: 0; }
    .rich-text-list.contact-bar .component-content ul li:first-of-type a, .rich-text-list.contact-bar .component-content ul li:first-of-type .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .rich-text-list.contact-bar .component-content ul li:first-of-type .search-filter-button, .rich-text-list.contact-bar .component-content ul li:first-of-type .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .rich-text-list.contact-bar .component-content ul li:first-of-type .close-button, .rich-text-list.contact-bar .component-content ul li:first-of-type .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .rich-text-list.contact-bar .component-content ul li:first-of-type label, .rich-text-list.contact-bar .component-content ul li:first-of-type .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .rich-text-list.contact-bar .component-content ul li:first-of-type .close-button {
      border-left: none; }
    .rich-text-list.contact-bar .component-content ul li a, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .rich-text-list.contact-bar .component-content ul li .search-filter-button, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .rich-text-list.contact-bar .component-content ul li .close-button, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .rich-text-list.contact-bar .component-content ul li label, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .rich-text-list.contact-bar .component-content ul li .close-button,
    .rich-text-list.contact-bar .component-content ul li div {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      color: #ffffff;
      text-decoration: none;
      font-weight: normal; }
      .rich-text-list.contact-bar .component-content ul li a::before, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button::before, .component.locations-spot .locations-search .rich-text-list.contact-bar .component-content ul li .search-filter-button::before, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .close-button::before, .component.locations-spot .filter-list .rich-text-list.contact-bar .component-content ul li .close-button::before, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label::before, .component.locations-spot .filter-list .filter-row .rich-text-list.contact-bar .component-content ul li label::before, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .location-detail .close-button::before, .component.locations-spot .location-detail .rich-text-list.contact-bar .component-content ul li .close-button::before,
      .rich-text-list.contact-bar .component-content ul li div::before {
        display: block;
        height: 1.25rem;
        width: 1.25rem;
        font-size: 18px;
        margin-right: 0.5rem; }
      .rich-text-list.contact-bar .component-content ul li a:hover, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search .rich-text-list.contact-bar .component-content ul li .search-filter-button:hover, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list .rich-text-list.contact-bar .component-content ul li .close-button:hover, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row .rich-text-list.contact-bar .component-content ul li label:hover, .rich-text-list.contact-bar .component-content ul li .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail .rich-text-list.contact-bar .component-content ul li .close-button:hover,
      .rich-text-list.contact-bar .component-content ul li div:hover {
        background-color: rgba(255, 255, 255, 0.2); }

.rich-text-list.contact-bar.contact-bar-flex-center .component-content ul {
  justify-content: center; }
  .rich-text-list.contact-bar.contact-bar-flex-center .component-content ul li:first-of-type a, .rich-text-list.contact-bar.contact-bar-flex-center .component-content ul li:first-of-type .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .rich-text-list.contact-bar.contact-bar-flex-center .component-content ul li:first-of-type .search-filter-button, .rich-text-list.contact-bar.contact-bar-flex-center .component-content ul li:first-of-type .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .rich-text-list.contact-bar.contact-bar-flex-center .component-content ul li:first-of-type .close-button, .rich-text-list.contact-bar.contact-bar-flex-center .component-content ul li:first-of-type .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .rich-text-list.contact-bar.contact-bar-flex-center .component-content ul li:first-of-type label, .rich-text-list.contact-bar.contact-bar-flex-center .component-content ul li:first-of-type .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .rich-text-list.contact-bar.contact-bar-flex-center .component-content ul li:first-of-type .close-button {
    border-left: none; }

@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 13px !important; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    font-size: 14px !important; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 15px !important; } }

/*
 THEMING
*/
/********************************
DEFAULT STYLING - end
********************************/
@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 13px !important; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    font-size: 14px !important; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 15px !important; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.component.text-image-spot .text-image-spot-image-container {
  background-size: 0px; }

.component.text-image-spot .text-image-spot-image img {
  max-width: 100%;
  height: auto; }

.component.text-image-spot .text-image-spot-text-container {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .component.text-image-spot .text-image-spot-text-container {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

.component.text-image-spot.inoborder {
  padding-top: 0;
  padding-bottom: 0; }
  .component.text-image-spot.inoborder .text-image-spot-text-container {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media only screen and (max-width: 767px) {
      .component.text-image-spot.inoborder .text-image-spot-text-container {
        padding-top: 1rem; } }
    @media only screen and (max-width: 767px) {
      .component.text-image-spot.inoborder .text-image-spot-text-container {
        padding-bottom: 1rem; } }
    .component.text-image-spot.inoborder .text-image-spot-text-container.repeating-background {
      padding-top: 0; }

@media only screen and (max-width: 767px) {
  .component.text-image-spot.ialigncenter .text-image-spot-image-container {
    text-align: center; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .component.text-image-spot.ialigncenter .text-image-spot-image-container {
    text-align: center; } }

@media only screen and (max-width: 767px) {
  .component.text-image-spot.taligncenter .text-image-spot-text-container {
    text-align: center; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .component.text-image-spot.taligncenter .text-image-spot-text-container {
    text-align: center; } }

.component.text-image-spot.multiple-images {
  margin-top: 2rem;
  position: relative; }
  .component.text-image-spot.multiple-images.iright .text-image-spot-image-elements {
    margin-top: 1rem; }
  .component.text-image-spot.multiple-images .text-image-spot-image-container:after {
    content: "";
    display: table;
    clear: both; }

.component.text-image-spot .text-image-spot-image-elements {
  max-width: 80%;
  margin: 0 auto; }
  .component.text-image-spot .text-image-spot-image-elements:after {
    content: "";
    display: table;
    clear: both; }
  .component.text-image-spot .text-image-spot-image-elements.slick-slider .text-image-spot-element {
    max-width: none; }

.component.text-image-spot .text-image-spot-element {
  display: block;
  float: right;
  padding: 0;
  max-width: 25%; }
  .component.text-image-spot .text-image-spot-element:before {
    display: none; }

.component.text-image-spot .text-image-spot-image-element {
  padding-left: 1rem; }
  .component.text-image-spot .text-image-spot-image-element img {
    max-width: 100%;
    height: auto;
    border-bottom: 2px solid transparent; }
  .component.text-image-spot .text-image-spot-image-element img:hover, .component.text-image-spot .text-image-spot-image-element img.selected {
    border-bottom: 2px solid grey; }

.component.text-image-spot .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  outline: none;
  background: transparent;
  border: solid 2px grey;
  border-radius: 15px; }
  .component.text-image-spot .slick-arrow:before {
    position: relative;
    top: 1px;
    font-family: "";
    font-size: 16px;
    line-height: 1;
    color: grey; }
  .component.text-image-spot .slick-arrow.slick-next {
    right: -10%;
    z-index: 100; }
    .component.text-image-spot .slick-arrow.slick-next:before {
      content: "";
      left: 1px; }
  .component.text-image-spot .slick-arrow.slick-prev {
    left: -10%;
    z-index: 100; }
    .component.text-image-spot .slick-arrow.slick-prev:before {
      content: "";
      right: 1px; }
  .component.text-image-spot .slick-arrow.slick-disabled {
    cursor: default;
    opacity: 0.5; }

@media only screen and (min-width: 992px) {
  .component.text-image-spot {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .component.text-image-spot .component-content {
      display: table-row;
      width: 100%; }
    .component.text-image-spot .text-image-spot-image-container,
    .component.text-image-spot .text-image-spot-text-container {
      display: table-cell;
      width: 50%; }
    .component.text-image-spot .text-image-spot-text {
      position: relative; }
    .component.text-image-spot:not(.iright) .text-image-spot-text {
      float: left;
      padding-left: 2rem; } }
    @media only screen and (min-width: 992px) and (max-width: 767px) {
      .component.text-image-spot:not(.iright) .text-image-spot-text {
        padding-left: 1rem; } }

@media only screen and (min-width: 992px) {
    .component.text-image-spot.iright {
      direction: rtl; }
      .component.text-image-spot.iright .text-image-spot-image-container {
        direction: ltr; }
      .component.text-image-spot.iright .text-image-spot-text-container {
        direction: ltr; }
      .component.text-image-spot.iright .text-image-spot-text {
        float: right;
        padding-right: 2rem; } }
      @media only screen and (min-width: 992px) and (max-width: 767px) {
        .component.text-image-spot.iright .text-image-spot-text {
          padding-right: 1rem; } }

@media only screen and (min-width: 992px) {
    .component.text-image-spot .text-image-spot-text {
      box-sizing: border-box;
      width: 45.125rem;
      max-width: 1140px; }
    .component.text-image-spot.tslim .text-image-spot-text {
      width: 38.5rem; }
    .component.text-image-spot.tw40 .text-image-spot-text {
      position: relative;
      width: 36.1rem;
      max-width: 912px;
      left: 9.025rem; }
    .component.text-image-spot.tw40.iright .text-image-spot-text {
      left: -9.025rem; }
    .component.text-image-spot.tw40.tslim .text-image-spot-text {
      width: 30.8rem;
      left: 7.7rem; }
    .component.text-image-spot.tw60 .text-image-spot-text {
      position: relative;
      width: 54.15rem;
      max-width: 1368px;
      left: -9.025rem; }
    .component.text-image-spot.tw60.tslim .text-image-spot-text {
      width: 46.2rem;
      left: -7.7rem; }
    .component.text-image-spot .text-image-spot-text-container {
      vertical-align: top; }
    .component.text-image-spot.tposmiddle .text-image-spot-text-container {
      vertical-align: middle; }
    .component.text-image-spot.tposbottom .text-image-spot-text-container {
      vertical-align: bottom; }
    .component.text-image-spot .text-image-spot-image {
      box-sizing: border-box;
      width: 100%;
      float: left; }
      .component.text-image-spot .text-image-spot-image img {
        float: right;
        max-width: 100%;
        height: auto; }
    .component.text-image-spot.inormal .text-image-spot-image img {
      width: 45.125rem;
      max-width: 1140px; }
    .component.text-image-spot.islim .text-image-spot-image img {
      width: 38.5rem;
      max-width: 1140px; }
    .component.text-image-spot.iright .text-image-spot-image img {
      float: left; }
    .component.text-image-spot.ialignborder:not(.iright) .text-image-spot-image img {
      float: right; }
    .component.text-image-spot.iw40 .text-image-spot-image {
      width: 80%; }
    .component.text-image-spot.iw40.iright .text-image-spot-image {
      margin-left: 20%; }
    .component.text-image-spot.iw40.inormal .text-image-spot-image {
      width: 36.1rem; }
    .component.text-image-spot.iw40.inormal.iright .text-image-spot-image {
      margin-left: 9.025rem; }
    .component.text-image-spot.iw40.islim .text-image-spot-image {
      width: 30.8rem; }
    .component.text-image-spot.iw40.islim.iright .text-image-spot-image {
      margin-left: 7.7rem; }
    .component.text-image-spot.iw60 .text-image-spot-image {
      width: 120%; }
    .component.text-image-spot.iw60.iright .text-image-spot-image {
      margin-left: -20%; }
    .component.text-image-spot.iw60.inormal .text-image-spot-image {
      width: 54.15rem; }
    .component.text-image-spot.iw60.inormal.iright .text-image-spot-image {
      margin-left: -9.025rem; }
    .component.text-image-spot.iw60.islim .text-image-spot-image {
      width: 46.2rem; }
    .component.text-image-spot.iw60.islim.iright .text-image-spot-image {
      margin-left: -7.7rem; }
    .component.text-image-spot .text-image-spot-image-container {
      vertical-align: top; }
    .component.text-image-spot.iposmiddle .text-image-spot-image-container {
      vertical-align: middle; }
    .component.text-image-spot.iposbottom .text-image-spot-image-container {
      vertical-align: bottom; }
    .component.text-image-spot.tforceheight .text-image-spot-image-container {
      background-size: cover;
      background-position: center; }
    .component.text-image-spot.tforceheight .text-image-spot-image img {
      display: none; }
    .component.text-image-spot.tforceheight:not(.iright).inormal .text-image-spot-image {
      left: auto;
      right: 0; }
    .component.text-image-spot.tforceheight:not(.iright).inormal.iw60 .text-image-spot-image {
      right: -9.025rem; }
    .component.text-image-spot.tforceheight:not(.iright).inormal.iw40 .text-image-spot-image {
      right: 9.025rem; }
    .component.text-image-spot.tforceheight:not(.iright).islim .text-image-spot-image {
      left: auto;
      right: 0; }
    .component.text-image-spot.tforceheight:not(.iright).islim.iw60 .text-image-spot-image {
      right: -7.7rem; }
    .component.text-image-spot.tforceheight:not(.iright).islim.iw40 .text-image-spot-image {
      right: 7.7rem; }
  .multiple-images.iright .text-image-spot-image-elements {
    float: left; }
  .multiple-images .text-image-spot-image-elements {
    max-width: 90%;
    float: right; }
  .multiple-images .slick-arrow.slick-next {
    right: -40px; }
  .multiple-images .slick-arrow.slick-prev {
    left: -40px; } }

@media only screen and (min-width: 992px) {
  .component.two-section-grid.wide-thin-columns > .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto; } }
  @media only screen and (min-width: 992px) and (min-width: 1200px) {
    .component.two-section-grid.wide-thin-columns > .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

@media only screen and (min-width: 992px) {
  .component.two-section-grid.wide-thin-columns .section-one {
    width: 66.66%;
    float: left; }
  .component.two-section-grid.wide-thin-columns .section-two {
    width: 33.32%;
    float: left; }
  .component.two-section-grid.wide-thin-columns::after {
    content: '';
    display: block;
    clear: both; }
  .component.two-section-grid.thin-wide-columns .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto; } }
  @media only screen and (min-width: 992px) and (min-width: 1200px) {
    .component.two-section-grid.thin-wide-columns .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

@media only screen and (min-width: 992px) {
  .component.two-section-grid.thin-wide-columns .section-one {
    width: 33.32%;
    float: left; }
  .component.two-section-grid.thin-wide-columns .section-two {
    width: 66.66%;
    float: left; }
  .component.two-section-grid.fifty-fifty-columns > .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; } }
  @media only screen and (min-width: 992px) and (min-width: 1200px) {
    .component.two-section-grid.fifty-fifty-columns > .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }

@media only screen and (min-width: 992px) {
  .component.two-section-grid.fifty-fifty-columns .section-one {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 50%; }
    .component.two-section-grid.fifty-fifty-columns .section-one .component-content {
      width: 100%;
      max-width: 100%; }
  .component.two-section-grid.fifty-fifty-columns .section-two {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 50%; }
    .component.two-section-grid.fifty-fifty-columns .section-two .component-content {
      width: 100%;
      max-width: 100%; }
  .component.two-section-grid.as-four-section .section-one {
    width: 100%; }
  .component.two-section-grid.carousel-two-column .section-one {
    display: inline-block;
    width: 65.95744680851064%;
    vertical-align: top; }
  .component.two-section-grid.carousel-two-column .section-two {
    display: inline-block;
    width: 31.914893617021278%;
    padding-left: 2.127659574468085%;
    vertical-align: top; } }

@media only screen and (min-width: 1200px) {
  .component.two-section-grid.as-four-section .section-one {
    display: inline-block;
    width: 73%;
    vertical-align: top; }
  .component.two-section-grid.as-four-section .section-two {
    display: inline-block;
    width: 20%;
    padding-left: 2.127659574468085%;
    vertical-align: top;
    margin-left: 5%; } }

.component.locations-spot .component-content .locations-container {
  display: flex;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0; }
  .component.locations-spot .component-content .locations-container.desktop {
    flex-direction: row;
    height: 40rem; }
  .component.locations-spot .component-content .locations-container.mobile {
    flex-direction: column;
    height: 90vh; }
    .component.locations-spot .component-content .locations-container.mobile .locations-search {
      display: block; }
    .component.locations-spot .component-content .locations-container.mobile .filter-list-close-button {
      display: none; }
    .component.locations-spot .component-content .locations-container.mobile.popover-open .locations-map,
    .component.locations-spot .component-content .locations-container.mobile.popover-open .locations-search,
    .component.locations-spot .component-content .locations-container.mobile.popover-open .locations-list .content,
    .component.locations-spot .component-content .locations-container.mobile.popover-open .locations-list .pager {
      display: none; }
    .component.locations-spot .component-content .locations-container.mobile.popover-open.location-detail-open .filter-list {
      display: none; }

@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 13px !important; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    font-size: 14px !important; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 15px !important; } }

/*
 THEMING
*/
/********************************
DEFAULT STYLING - end
********************************/
@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 13px !important; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    font-size: 14px !important; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 15px !important; } }

.component.locations-spot .locations-list {
  flex-grow: 1;
  overflow: auto; }
  .component.locations-spot .locations-list.desktop {
    height: 100%;
    width: 35%;
    display: flex;
    flex-direction: column;
    overflow: hidden; }
    .component.locations-spot .locations-list.desktop > .content {
      height: calc(100% - 3rem);
      overflow-x: auto; }
  .component.locations-spot .locations-list .pager {
    text-align: right; }
    .component.locations-spot .locations-list .pager .pager-status {
      display: inline-block;
      padding: 1rem; }
      .component.locations-spot .locations-list .pager .pager-status .page-start,
      .component.locations-spot .locations-list .pager .pager-status .page-end {
        padding: 0 0.5rem; }
    .component.locations-spot .locations-list .pager .pager-prev,
    .component.locations-spot .locations-list .pager .pager-next {
      display: inline-block;
      background-color: transparent;
      font-family: "DGS-WebProgram";
      font-size: 1.3rem;
      color: steelblue;
      padding: 1rem; }
      .component.locations-spot .locations-list .pager .pager-prev.disabled,
      .component.locations-spot .locations-list .pager .pager-next.disabled {
        color: rgba(70, 130, 180, 0.5); }
    .component.locations-spot .locations-list .pager .pager-buttons {
      display: inline-block;
      vertical-align: bottom; }
      .component.locations-spot .locations-list .pager .pager-buttons button {
        border: 0; }
    .component.locations-spot .locations-list .pager .pager-prev:before {
      content: ""; }
    .component.locations-spot .locations-list .pager .pager-next:before {
      content: ""; }
    .component.locations-spot .locations-list .pager .pager-append-next-button {
      padding: 1rem; }
      .component.locations-spot .locations-list .pager .pager-append-next-button.disabled {
        color: rgba(70, 130, 180, 0.5); }

.component.locations-spot .locations-list-item {
  padding: 1rem 2rem;
  border-bottom: 1px solid aliceblue;
  display: flex; }
  .component.locations-spot .locations-list-item.odd {
    background-color: white; }
  .component.locations-spot .locations-list-item.even {
    background-color: #fdfdff; }
  .component.locations-spot .locations-list-item.selected {
    background-color: aliceblue; }
  .component.locations-spot .locations-list-item .info {
    flex-grow: 1;
    padding-right: 1rem; }
  .component.locations-spot .locations-list-item .cta {
    display: flex;
    align-items: center; }
  .component.locations-spot .locations-list-item.desktop .distance {
    font-size: 0.9rem; }
  .component.locations-spot .locations-list-item .details > .button {
    padding: 0.8rem 1rem; }

.component.locations-spot .locations-search {
  padding: 1rem;
  background: #f0f0f0;
  display: flex;
  flex-direction: row; }
  .component.locations-spot .locations-search .content {
    flex-grow: 1; }
  .component.locations-spot .locations-search .header,
  .component.locations-spot .locations-search .footer {
    display: flex;
    flex-direction: row;
    align-content: center;
    padding: 0 0 0 1rem; }
    .component.locations-spot .locations-search .header:empty,
    .component.locations-spot .locations-search .footer:empty {
      padding: 0; }
  .component.locations-spot .locations-search .header {
    padding: 0 1rem 0 0; }
  .component.locations-spot .locations-search input {
    background-color: white;
    border: 1px solid #e5e5e5;
    padding-right: 2.5rem;
    width: 100%; }
  .component.locations-spot .locations-search input::placeholder {
    color: lightgray; }
  .component.locations-spot .locations-search input:-ms-input-placeholder {
    color: lightgray; }
  .component.locations-spot .locations-search input::-ms-input-placeholder {
    color: lightgray; }
  .component.locations-spot .locations-search .search-icon {
    float: right;
    height: 1.875rem;
    margin-top: -2.8rem;
    margin-right: 0.7rem;
    position: relative;
    width: 1.9375rem;
    cursor: pointer; }
  .component.locations-spot .locations-search .search-icon:before {
    content: "";
    display: block;
    font-family: "DGS-WebProgram";
    font-size: 1.5rem;
    color: gray; }
  .component.locations-spot .locations-search .search-filter-button {
    background-color: transparent;
    display: inline-block;
    padding: 0; }
  .component.locations-spot .locations-search .filter-icon {
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.3rem; }
  .component.locations-spot .locations-search .filter-icon:before {
    position: absolute;
    content: "";
    display: block;
    font-family: "DGS-WebProgram";
    font-size: 1.2rem;
    margin-top: 0.3rem;
    color: #61B6D8; }

.component.locations-spot .desktop .locations-search {
  padding: 2rem; }

.component.locations-spot .component-content .locations-map {
  flex-grow: 1;
  height: 20rem;
  width: 100%; }
  .component.locations-spot .component-content .locations-map.desktop {
    height: 100%;
    width: 75%;
    float: left;
    position: relative; }
  .component.locations-spot .component-content .locations-map .content {
    width: 100%;
    height: 100%; }
  .component.locations-spot .component-content .locations-map .search-location-marker {
    visibility: hidden;
    font-family: "DGS-WebProgram";
    font-size: 2rem;
    color: #880000; }
    .component.locations-spot .component-content .locations-map .search-location-marker:before {
      content: "";
      position: relative;
      top: -1rem;
      left: -1rem; }
  .component.locations-spot .component-content .locations-map .user-location-marker {
    font-family: "DGS-WebProgram";
    font-size: 2rem;
    color: steelblue; }
    .component.locations-spot .component-content .locations-map .user-location-marker:before {
      content: "";
      position: relative;
      top: -1rem;
      left: -1rem; }
  .component.locations-spot .component-content .locations-map .location-marker {
    font-family: "DGS-WebProgram";
    font-size: 2rem;
    color: cadetblue; }
    .component.locations-spot .component-content .locations-map .location-marker:before {
      content: "";
      position: relative;
      top: -1rem;
      left: -1rem; }
    .component.locations-spot .component-content .locations-map .location-marker.selected {
      color: black;
      font-size: 4rem;
      position: relative;
      top: -2rem;
      left: -1rem;
      z-index: 2; }
  .component.locations-spot .component-content .locations-map .location-cluster-marker .counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: grey; }
  .component.locations-spot .component-content .locations-map .filter-list,
  .component.locations-spot .component-content .locations-map .filter-list .close-button {
    display: none; }
  .component.locations-spot .component-content .locations-map.desktop .filter-list {
    display: block;
    position: absolute;
    top: 2rem;
    right: 2rem;
    padding: 0.7rem 1.1rem 1.1rem 1.1rem;
    background-color: #ffffff; }
    .component.locations-spot .component-content .locations-map.desktop .filter-list .filter-list .close-button {
      display: none; }
    .component.locations-spot .component-content .locations-map.desktop .filter-list .filter-list-filters {
      margin-top: 0; }
    .component.locations-spot .component-content .locations-map.desktop .filter-list .filter-row {
      margin: 0 0 0.5rem 0; }

.component.locations-spot .filter-list {
  padding: 1rem;
  position: relative; }
  .component.locations-spot .filter-list .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    display: inline-block;
    padding: 0;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1rem; }
    .component.locations-spot .filter-list .close-button:before {
      font-family: "DGS-WebProgram";
      content: "\eae3"; }
  .component.locations-spot .filter-list .filter-list-title {
    font-size: 1.3rem;
    margin-bottom: 1rem; }
  .component.locations-spot .filter-list .filter-row {
    display: flex;
    align-items: center; }
    .component.locations-spot .filter-list .filter-row:not(:last-child) {
      margin-bottom: 1rem; }
    .component.locations-spot .filter-list .filter-row input {
      margin: 0 0.5rem 0 0; }
    .component.locations-spot .filter-list .filter-row label {
      background-color: transparent;
      display: inline-block;
      padding: 0; }

.component.locations-spot .mobile .close-button {
  display: none; }

.component.locations-spot .mobile.filter-list-open .filter-list-title {
  font-size: 2rem; }

.component.locations-spot .mobile.filter-list-open .close-button {
  display: block;
  font-size: 1.5rem;
  top: 1.5rem; }

.component.locations-spot .desktop .filter-list {
  padding: 2rem; }
  .component.locations-spot .desktop .filter-list .close-button {
    display: block; }
  .component.locations-spot .desktop .filter-list .filter-list-title {
    font-size: 2rem; }
  .component.locations-spot .desktop .filter-list .filter-list-filters {
    margin-top: 2rem; }

.component.locations-spot .location-detail {
  padding: 1.5rem;
  position: relative; }
  .component.locations-spot .location-detail .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    display: inline-block;
    padding: 0;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1rem; }
    .component.locations-spot .location-detail .close-button:before {
      font-family: "DGS-WebProgram";
      content: "\eae3"; }
  .component.locations-spot .location-detail .close-button {
    top: 1.5rem;
    right: 1.5rem;
    line-height: normal; }
  .component.locations-spot .location-detail .title {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ccc; }
  .component.locations-spot .location-detail .info {
    display: flex;
    justify-content: space-between; }
    .component.locations-spot .location-detail .info .info-left-col {
      width: 40%; }
    .component.locations-spot .location-detail .info .info-right-col {
      width: 55%; }
    .component.locations-spot .location-detail .info .website {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }

.component.locations-spot .component-content .locations-container {
  border-top: 0;
  border-bottom: 0; }

.carousel-slidable > .component.component-ready.animate.carousel-slide {
  opacity: 0;
  left: 100%;
  transition: opacity 1.3s, left 0s linear 1.5s; }
  .carousel-slidable > .component.component-ready.animate.carousel-slide.active-slide {
    opacity: 1;
    left: 0;
    transition: opacity 1.3s, left 0s linear 0s; }

/* global slider style that can be applied to virtually any component via Sitecore */
.carousel-slidable {
  position: relative;
  height: 250px;
  overflow: hidden;
  padding-bottom: 0;
  -webkit-user-select: none;
  /* Safari 3.1+  not being added by auto-prefixer */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */ }
  .carousel-slidable > .component.carousel-slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box; }
  .carousel-slidable > .slider-tabs {
    position: absolute;
    z-index: 1; }
    .carousel-slidable > .slider-tabs .slider-tab {
      display: inline-block;
      cursor: pointer;
      user-select: none; }
  .carousel-slidable > .slider-arrow-left,
  .carousel-slidable > .slider-arrow-right {
    position: absolute;
    z-index: 100;
    display: inline-block;
    cursor: pointer;
    user-select: none; }
  .carousel-slidable .slider-spinner {
    position: absolute;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    .carousel-slidable .slider-spinner:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /*background-color: rgba($black,.1);*/ }
    .carousel-slidable .slider-spinner:not(:required) {
      /* hide "loading..." text */
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0; }
    .carousel-slidable .slider-spinner:not(:required):after {
      content: '';
      display: block;
      font-size: 10px;
      width: 1em;
      height: 1em;
      margin-top: -0.5em;
      animation: spinner 1500ms infinite linear;
      border-radius: 0.5em;
      box-shadow: #f7941d 1.5em 0 0 0, #f7941d 1.1em 1.1em 0 0, #f7941d 0 1.5em 0 0, #f7941d -1.1em 1.1em 0 0, #f7941d -1.5em 0 0 0, #f7941d -1.1em -1.1em 0 0, #f7941d 0 -1.5em 0 0, #f7941d 1.1em -1.1em 0 0; }

@media only screen and (min-width: 992px) {
  .carousel-slidable:not(.slide-on-mobile) {
    padding-bottom: 3rem; } }

.carousel-slidable > .slider-tabs {
  bottom: 1.5rem;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 1.3s; }
  @media only screen and (max-width: 767px) {
    .carousel-slidable > .slider-tabs {
      bottom: 0; } }
  .carousel-slidable > .slider-tabs .tabs-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    text-align: center; }
    @media (min-width: 1200px) {
      .carousel-slidable > .slider-tabs .tabs-content {
        position: relative;
        width: 90.25rem;
        max-width: 1140px; } }
    .carousel-slidable > .slider-tabs .tabs-content .slider-tab {
      width: .7rem;
      height: .7rem;
      margin-left: .75rem;
      border-radius: 50%;
      border: 0.15rem solid #f7941d;
      transition: background-color 0.2s; }
      @media only screen and (min-width: 992px) {
        .carousel-slidable > .slider-tabs .tabs-content .slider-tab {
          width: 1rem;
          height: 1rem;
          margin-left: 1rem; } }
      .carousel-slidable > .slider-tabs .tabs-content .slider-tab.active-tab, .carousel-slidable > .slider-tabs .tabs-content .slider-tab:hover {
        background-color: #f7941d;
        border: 0.15rem solid #f7941d; }

.carousel-slidable > .slider-arrow-left,
.carousel-slidable > .slider-arrow-right {
  width: 3rem;
  height: 3rem;
  top: 50%;
  transform: translateY(-50%);
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: center; }
  @media only screen and (min-width: 992px) {
    .carousel-slidable > .slider-arrow-left,
    .carousel-slidable > .slider-arrow-right {
      top: 50%;
      transform: translateY(-50%); } }
  @media only screen and (min-width: 992px) {
    .carousel-slidable > .slider-arrow-left:not(.show-on-mobile),
    .carousel-slidable > .slider-arrow-right:not(.show-on-mobile) {
      display: none; } }

.carousel-slidable > .slider-arrow-left {
  left: .8rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='129' viewBox='0 0 66 129'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23CCCCCC;stroke:%23656665;stroke-miterlimit:10;stroke-width:0.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eleft-arrow%3C/title%3E%3Cpolygon class='cls-1' points='15.99 64.5 47.1 95.76 49.52 93.35 20.67 64.5 49.52 35.65 47.1 33.24 15.99 64.5 16.14 64.5 15.99 64.5'/%3E%3C/svg%3E"); }

.carousel-slidable > .slider-arrow-right {
  right: .8rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='129' viewBox='0 0 66 129'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23CCCCCC;stroke:%23656665;stroke-miterlimit:10;stroke-width:0.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eright-arrow%3C/title%3E%3Cpolygon class='cls-1' points='49.52 64.5 18.4 33.24 15.99 35.65 44.84 64.5 15.99 93.35 18.4 95.76 49.52 64.5 49.37 64.5 49.52 64.5'/%3E%3C/svg%3E"); }

.carousel-slidable .carousel-slide.active-slide.white-txt ~ .slider-arrow-left {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='129' viewBox='0 0 66 129'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23FFFFFF;stroke:%23656665;stroke-miterlimit:10;stroke-width:0.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eleft-arrow%3C/title%3E%3Cpolygon class='cls-1' points='15.99 64.5 47.1 95.76 49.52 93.35 20.67 64.5 49.52 35.65 47.1 33.24 15.99 64.5 16.14 64.5 15.99 64.5'/%3E%3C/svg%3E"); }

.carousel-slidable .carousel-slide.active-slide.white-txt ~ .slider-arrow-right {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='129' viewBox='0 0 66 129'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23FFFFFF;stroke:%23656665;stroke-miterlimit:10;stroke-width:0.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eright-arrow%3C/title%3E%3Cpolygon class='cls-1' points='49.52 64.5 18.4 33.24 15.99 35.65 44.84 64.5 15.99 93.35 18.4 95.76 49.52 64.5 49.37 64.5 49.52 64.5'/%3E%3C/svg%3E"); }

.carousel-slidable .carousel-slide.active-slide.light-grey-txt ~ .slider-arrow-left {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='129' viewBox='0 0 66 129'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23555555;stroke:%23656665;stroke-miterlimit:10;stroke-width:0.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eleft-arrow%3C/title%3E%3Cpolygon class='cls-1' points='15.99 64.5 47.1 95.76 49.52 93.35 20.67 64.5 49.52 35.65 47.1 33.24 15.99 64.5 16.14 64.5 15.99 64.5'/%3E%3C/svg%3E"); }

.carousel-slidable .carousel-slide.active-slide.light-grey-txt ~ .slider-arrow-right {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='129' viewBox='0 0 66 129'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23555555;stroke:%23656665;stroke-miterlimit:10;stroke-width:0.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eright-arrow%3C/title%3E%3Cpolygon class='cls-1' points='49.52 64.5 18.4 33.24 15.99 35.65 44.84 64.5 15.99 93.35 18.4 95.76 49.52 64.5 49.37 64.5 49.52 64.5'/%3E%3C/svg%3E"); }

.carousel-slidable .carousel-slide.active-slide.dark-grey-txt ~ .slider-arrow-left {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='129' viewBox='0 0 66 129'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23CCCCCC;stroke:%23656665;stroke-miterlimit:10;stroke-width:0.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eleft-arrow%3C/title%3E%3Cpolygon class='cls-1' points='15.99 64.5 47.1 95.76 49.52 93.35 20.67 64.5 49.52 35.65 47.1 33.24 15.99 64.5 16.14 64.5 15.99 64.5'/%3E%3C/svg%3E"); }

.carousel-slidable .carousel-slide.active-slide.dark-grey-txt ~ .slider-arrow-right {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='129' viewBox='0 0 66 129'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23CCCCCC;stroke:%23656665;stroke-miterlimit:10;stroke-width:0.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eright-arrow%3C/title%3E%3Cpolygon class='cls-1' points='49.52 64.5 18.4 33.24 15.99 35.65 44.84 64.5 15.99 93.35 18.4 95.76 49.52 64.5 49.37 64.5 49.52 64.5'/%3E%3C/svg%3E"); }

.carousel-slidable .carousel-slide.active-slide.corporate-txt ~ .slider-arrow-left {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='129' viewBox='0 0 66 129'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23F7941D;stroke:%23656665;stroke-miterlimit:10;stroke-width:0.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eleft-arrow%3C/title%3E%3Cpolygon class='cls-1' points='15.99 64.5 47.1 95.76 49.52 93.35 20.67 64.5 49.52 35.65 47.1 33.24 15.99 64.5 16.14 64.5 15.99 64.5'/%3E%3C/svg%3E"); }

.carousel-slidable .carousel-slide.active-slide.corporate-txt ~ .slider-arrow-right {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='129' viewBox='0 0 66 129'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23F7941D;stroke:%23656665;stroke-miterlimit:10;stroke-width:0.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eright-arrow%3C/title%3E%3Cpolygon class='cls-1' points='49.52 64.5 18.4 33.24 15.99 35.65 44.84 64.5 15.99 93.35 18.4 95.76 49.52 64.5 49.37 64.5 49.52 64.5'/%3E%3C/svg%3E"); }

#wrapper .component.blog .blog-image {
  width: 50rem;
  max-width: unset;
  margin: 0 auto;
  padding-top: 2rem; }

#wrapper .component.blog .component-content {
  width: 50rem;
  margin-bottom: 6rem; }
  #wrapper .component.blog .component-content .blog-reading-time {
    display: none; }
  #wrapper .component.blog .component-content .blog-content {
    margin-top: 1rem; }
    #wrapper .component.blog .component-content .blog-content p {
      font-size: 15px; }

#blog .component.blog-categories {
  display: none; }
  @media (min-width: 992px) {
    #blog .component.blog-categories {
      display: block; } }
  #blog .component.blog-categories .blog-categories-content-wrapper {
    padding: 0 2rem; }
    #blog .component.blog-categories .blog-categories-content-wrapper .blog-category a, #blog .component.blog-categories .blog-categories-content-wrapper .blog-category .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #blog .component.blog-categories .blog-categories-content-wrapper .blog-category .search-filter-button, #blog .component.blog-categories .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #blog .component.blog-categories .blog-categories-content-wrapper .blog-category .close-button, #blog .component.blog-categories .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #blog .component.blog-categories .blog-categories-content-wrapper .blog-category label, #blog .component.blog-categories .blog-categories-content-wrapper .blog-category .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #blog .component.blog-categories .blog-categories-content-wrapper .blog-category .close-button {
      font-weight: 400;
      letter-spacing: 1px; }

#blog .two-section-grid {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    #blog .two-section-grid {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }
  #blog .two-section-grid .component-content {
    padding: 0; }
    @media (min-width: 992px) {
      #blog .two-section-grid .component-content .section-one {
        padding-right: 7rem; } }
    #blog .two-section-grid .component-content .section-one .component.blog .component-content {
      padding-top: 0;
      width: 100%;
      max-width: unset; }
      #blog .two-section-grid .component-content .section-one .component.blog .component-content .blog-header .blog-title {
        margin: 0.7rem 0;
        font-weight: 400; }
      #blog .two-section-grid .component-content .section-one .component.blog .component-content .blog-header .blog-image {
        margin: 2rem 0; }
      #blog .two-section-grid .component-content .section-one .component.blog .component-content .blog-content ol li,
      #blog .two-section-grid .component-content .section-one .component.blog .component-content .blog-content ul li {
        margin-bottom: 1rem !important;
        text-indent: 0;
        list-style-position: inherit; }
    #blog .two-section-grid .component-content .section-two {
      padding: 0; }
      #blog .two-section-grid .component-content .section-two .component.rich-text .component-content {
        width: 100%;
        max-width: unset; }
        #blog .two-section-grid .component-content .section-two .component.rich-text .component-content h3 {
          font-weight: 400; }
      #blog .two-section-grid .component-content .section-two .component.locations-spot .component-content {
        width: 100%;
        max-width: unset;
        margin-bottom: 2rem; }
        #blog .two-section-grid .component-content .section-two .component.locations-spot .component-content .locations-container {
          border: 0;
          padding: 1rem 0; }
          #blog .two-section-grid .component-content .section-two .component.locations-spot .component-content .locations-container .locations-search {
            padding: 0; }
      #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form {
        padding: 0; }
        #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm {
          padding: 1rem 1rem 2rem; }
          #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm label {
            padding: 0;
            margin-bottom: 0.15rem; }
          #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfTitleBorder {
            padding: 1rem;
            font-size: 1.15rem;
            font-weight: 400;
            margin: 0 0 1rem; }
          #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfSectionBorder {
            margin: 0; }
            #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfSectionBorder .scfSectionContent {
              padding-top: 1rem; }
          #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfRequired {
            top: 0.2rem; }
          #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm input:not([type='radio']):not([type='checkbox']):not([type='submit']),
          #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm textarea,
          #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm select {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 0.75rem;
            padding-right: 0.5rem; }
          #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfCheckbox {
            display: flex;
            line-height: 1.45;
            margin-bottom: 1rem;
            font-size: 13px; }
          #blog .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfSubmitButton {
            font-size: 16px;
            font-weight: 600;
            padding: 0.75rem 2rem; }

.component.blog-categories .component-content .blog-categories-content {
  background: #f0f0f0; }
  .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper {
    padding: 1rem 0; }
    @media (min-width: 992px) {
      .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper {
        display: flex;
        justify-content: space-evenly; } }
    .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category {
      text-align: center; }
      @media (min-width: 992px) {
        .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category {
          border: 0; } }
      .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category:hover a, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category:hover .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category:hover .search-filter-button, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category:hover .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category:hover .close-button, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category:hover .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category:hover label, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category:hover .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category:hover .close-button {
        transform: scale(1); }
      .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category a, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .search-filter-button, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .close-button, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category label, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .close-button {
        display: block;
        color: #808285;
        text-transform: uppercase;
        transition: all 0.3s ease;
        min-width: unset; }
        @media (min-width: 992px) {
          .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category a, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .search-filter-button, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .close-button, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category label, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .close-button {
            padding: 0.8rem 1.2rem;
            border-bottom: 2px solid #f0f0f0; } }
        .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category a:hover, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .search-filter-button:hover, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .close-button:hover, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category label:hover, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .close-button:hover {
          background-color: #1890ba;
          color: #ffffff; }
          @media (min-width: 992px) {
            .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category a:hover, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .search-filter-button:hover, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .close-button:hover, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category label:hover, .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail .component.blog-categories .component-content .blog-categories-content .blog-categories-content-wrapper .blog-category .close-button:hover {
              color: #808285;
              background-color: #ededed;
              border-bottom: 2px solid #1890ba; } }

.component.blog-list .component-content.bloglist {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .component.blog-list .component-content.bloglist {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }
  .component.blog-list .component-content.bloglist ul#blog-list {
    margin-top: 2rem;
    justify-content: space-between;
    flex-wrap: wrap; }
    .component.blog-list .component-content.bloglist ul#blog-list li {
      margin: 0 1rem 3rem 0;
      padding: 0; }
      @media (min-width: 992px) {
        .component.blog-list .component-content.bloglist ul#blog-list li {
          width: 27%; } }
      .component.blog-list .component-content.bloglist ul#blog-list li:nth-of-type(3) {
        margin-right: 0; }
    .component.blog-list .component-content.bloglist ul#blog-list h3.background {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 400;
      font-style: normal;
      text-align: left;
      font-size: 15px; }
    .component.blog-list .component-content.bloglist ul#blog-list a, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.blog-list .component-content.bloglist ul#blog-list .search-filter-button, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.blog-list .component-content.bloglist ul#blog-list .close-button, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.blog-list .component-content.bloglist ul#blog-list label, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.blog-list .component-content.bloglist ul#blog-list .close-button {
      display: block;
      padding-top: 0.5rem;
      padding-bottom: 1rem; }
      @media (min-width: 568px) {
        .component.blog-list .component-content.bloglist ul#blog-list a h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .locations-search .search-filter-button h2, .component.locations-spot .locations-search .component.blog-list .component-content.bloglist ul#blog-list .search-filter-button h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .close-button h2, .component.locations-spot .filter-list .component.blog-list .component-content.bloglist ul#blog-list .close-button h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .filter-row label h2, .component.locations-spot .filter-list .filter-row .component.blog-list .component-content.bloglist ul#blog-list label h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .location-detail .close-button h2, .component.locations-spot .location-detail .component.blog-list .component-content.bloglist ul#blog-list .close-button h2 {
          font-family: "Open Sans", "Arial", sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 1.067rem;
          line-height: 1.3;
          color: inherit; } }
  @media all and (min-width: 568px) and (-ms-high-contrast: none) {
    .component.blog-list .component-content.bloglist ul#blog-list a h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .locations-search .search-filter-button h2, .component.locations-spot .locations-search .component.blog-list .component-content.bloglist ul#blog-list .search-filter-button h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .close-button h2, .component.locations-spot .filter-list .component.blog-list .component-content.bloglist ul#blog-list .close-button h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .filter-row label h2, .component.locations-spot .filter-list .filter-row .component.blog-list .component-content.bloglist ul#blog-list label h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .location-detail .close-button h2, .component.locations-spot .location-detail .component.blog-list .component-content.bloglist ul#blog-list .close-button h2 {
      font-weight: bold; } }
  @media only screen and (min-width: 568px) and (max-width: 767px) {
    .component.blog-list .component-content.bloglist ul#blog-list a h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .locations-search .search-filter-button h2, .component.locations-spot .locations-search .component.blog-list .component-content.bloglist ul#blog-list .search-filter-button h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .close-button h2, .component.locations-spot .filter-list .component.blog-list .component-content.bloglist ul#blog-list .close-button h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .filter-row label h2, .component.locations-spot .filter-list .filter-row .component.blog-list .component-content.bloglist ul#blog-list label h2, .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .location-detail .close-button h2, .component.locations-spot .location-detail .component.blog-list .component-content.bloglist ul#blog-list .close-button h2 {
      font-size: 1rem; } }
    .component.blog-list .component-content.bloglist ul#blog-list .news-image a, .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.blog-list .component-content.bloglist ul#blog-list .news-image .search-filter-button, .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.blog-list .component-content.bloglist ul#blog-list .news-image .close-button, .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.blog-list .component-content.bloglist ul#blog-list .news-image label, .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.blog-list .component-content.bloglist ul#blog-list .news-image .close-button {
      padding: 0; }
      .component.blog-list .component-content.bloglist ul#blog-list .news-image a img, .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .locations-search .search-filter-button img, .component.locations-spot .locations-search .component.blog-list .component-content.bloglist ul#blog-list .news-image .search-filter-button img, .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .filter-list .close-button img, .component.locations-spot .filter-list .component.blog-list .component-content.bloglist ul#blog-list .news-image .close-button img, .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .filter-list .filter-row label img, .component.locations-spot .filter-list .filter-row .component.blog-list .component-content.bloglist ul#blog-list .news-image label img, .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .location-detail .close-button img, .component.locations-spot .location-detail .component.blog-list .component-content.bloglist ul#blog-list .news-image .close-button img {
        width: 100%;
        max-height: unset; }
    .component.blog-list .component-content.bloglist ul#blog-list .news-text {
      display: none; }
    .component.blog-list .component-content.bloglist ul#blog-list .news-link {
      display: none; }

.blog-list__pagination {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    .blog-list__pagination {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    .blog-list__pagination {
      font-size: 1.25rem; } }
  .blog-list__pagination .left {
    padding-right: 1rem;
    margin-right: 0.7rem;
    border-right: 2px solid #808285; }
    .blog-list__pagination .left::before {
      content: ' \000AB';
      color: #1890ba;
      padding: 0 0.25rem 0 0; }
  .blog-list__pagination .right::after {
    content: ' \000BB';
    color: #1890ba;
    padding: 0 0 0 0.15rem; }

#blogList .component.blog-categories {
  display: none; }
  @media (min-width: 992px) {
    #blogList .component.blog-categories {
      display: block; } }
  #blogList .component.blog-categories .blog-categories-content-wrapper {
    padding: 0 2rem; }
    #blogList .component.blog-categories .blog-categories-content-wrapper .blog-category a, #blogList .component.blog-categories .blog-categories-content-wrapper .blog-category .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #blogList .component.blog-categories .blog-categories-content-wrapper .blog-category .search-filter-button, #blogList .component.blog-categories .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #blogList .component.blog-categories .blog-categories-content-wrapper .blog-category .close-button, #blogList .component.blog-categories .blog-categories-content-wrapper .blog-category .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #blogList .component.blog-categories .blog-categories-content-wrapper .blog-category label, #blogList .component.blog-categories .blog-categories-content-wrapper .blog-category .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #blogList .component.blog-categories .blog-categories-content-wrapper .blog-category .close-button {
      font-weight: 400;
      letter-spacing: 1px; }

#blogList .two-section-grid {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    #blogList .two-section-grid {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }
  #blogList .two-section-grid .component-content {
    padding: 0; }
    @media (min-width: 992px) {
      #blogList .two-section-grid .component-content .section-one {
        padding-right: 7rem; } }
    #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist {
      width: 100%;
      max-width: unset; }
      #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list {
        display: flex;
        margin-top: 2rem;
        justify-content: space-between; }
        #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list li {
          margin: 0 1rem 3rem 0;
          padding: 0; }
          @media (min-width: 992px) {
            #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list li {
              width: 27%; } }
          #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list li:nth-of-type(3) {
            margin-right: 0; }
        #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list h3.background {
          font-family: "Open Sans", "Arial", sans-serif;
          font-weight: 400;
          font-style: normal;
          text-align: left;
          font-size: 13px; }
        #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list a, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .search-filter-button, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .close-button, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list label, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .close-button {
          display: block;
          padding-top: 0.5rem;
          padding-bottom: 1rem;
          color: #333; }
          #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list a:hover, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .search-filter-button:hover, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .close-button:hover, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list label:hover, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .close-button:hover {
            color: #1890ba; }
          @media (min-width: 568px) {
            #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list a h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .locations-search .search-filter-button h2, .component.locations-spot .locations-search #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .search-filter-button h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .close-button h2, .component.locations-spot .filter-list #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .close-button h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .filter-row label h2, .component.locations-spot .filter-list .filter-row #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list label h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .location-detail .close-button h2, .component.locations-spot .location-detail #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .close-button h2 {
              font-family: "Open Sans", "Arial", sans-serif;
              font-weight: 700;
              font-style: normal;
              font-size: 1.067rem;
              line-height: 1.3;
              color: inherit;
              font-weight: 500; } }
  @media all and (min-width: 568px) and (-ms-high-contrast: none) {
    #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list a h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .locations-search .search-filter-button h2, .component.locations-spot .locations-search #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .search-filter-button h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .close-button h2, .component.locations-spot .filter-list #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .close-button h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .filter-row label h2, .component.locations-spot .filter-list .filter-row #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list label h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .location-detail .close-button h2, .component.locations-spot .location-detail #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .close-button h2 {
      font-weight: bold; } }
  @media only screen and (min-width: 568px) and (max-width: 767px) {
    #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list a h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .locations-search .search-filter-button h2, .component.locations-spot .locations-search #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .search-filter-button h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .close-button h2, .component.locations-spot .filter-list #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .close-button h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .filter-list .filter-row label h2, .component.locations-spot .filter-list .filter-row #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list label h2, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .component.locations-spot .location-detail .close-button h2, .component.locations-spot .location-detail #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .close-button h2 {
      font-size: 1rem; } }
        #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image a, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .search-filter-button, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .close-button, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image label, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .close-button {
          padding: 0; }
          #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image a img, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .locations-search .search-filter-button img, .component.locations-spot .locations-search #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .search-filter-button img, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .filter-list .close-button img, .component.locations-spot .filter-list #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .close-button img, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .filter-list .filter-row label img, .component.locations-spot .filter-list .filter-row #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image label img, #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .component.locations-spot .location-detail .close-button img, .component.locations-spot .location-detail #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-image .close-button img {
            width: 100%;
            max-height: unset; }
        #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-text {
          display: none; }
        #blogList .two-section-grid .component-content .section-one .component.blog-list .component-content.bloglist ul#blog-list .news-link {
          display: none; }
    #blogList .two-section-grid .component-content .section-two {
      padding: 0; }
      #blogList .two-section-grid .component-content .section-two .component.rich-text .component-content {
        width: 100%;
        max-width: unset; }
        #blogList .two-section-grid .component-content .section-two .component.rich-text .component-content h3 {
          font-weight: 400; }
      #blogList .two-section-grid .component-content .section-two .component.locations-spot .component-content {
        width: 100%;
        max-width: unset;
        margin-bottom: 2rem; }
        #blogList .two-section-grid .component-content .section-two .component.locations-spot .component-content .locations-container {
          border: 0;
          padding: 1rem 0; }
          #blogList .two-section-grid .component-content .section-two .component.locations-spot .component-content .locations-container .locations-search {
            padding: 0; }
      #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form {
        padding: 0; }
        #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm {
          padding: 1rem 1rem 2rem; }
          #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm label {
            padding: 0;
            margin-bottom: 0.15rem; }
          #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfTitleBorder {
            padding: 1rem;
            font-size: 1.15rem;
            font-weight: 400;
            margin: 0 0 1rem; }
          #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfSectionBorder {
            margin: 0; }
            #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfSectionBorder .scfSectionContent {
              padding: 0; }
          #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfRequired {
            top: 0.2rem; }
          #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm input:not([type='radio']):not([type='checkbox']):not([type='submit']),
          #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm textarea,
          #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm select {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 0.75rem;
            padding-right: 0.5rem; }
          #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfCheckbox {
            display: flex;
            line-height: 1.45;
            margin-bottom: 1rem;
            font-size: 13px; }
          #blogList .two-section-grid .component-content .section-two .component.form.two-column-grid-form .scfForm .scfSubmitButton {
            font-size: 16px;
            font-weight: 600;
            padding: 0.75rem 2rem; }

.blog-list__pagination {
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.3;
  color: inherit; }
  @media all and (-ms-high-contrast: none) {
    .blog-list__pagination {
      font-weight: bold; } }
  @media only screen and (max-width: 767px) {
    .blog-list__pagination {
      font-size: 1.25rem; } }
  .blog-list__pagination .left {
    padding-right: 1rem;
    margin-right: 0.7rem;
    border-right: 2px solid #808285; }
    .blog-list__pagination .left::before {
      content: ' \000AB';
      color: #1890ba;
      padding: 0 0.25rem 0 0; }
  .blog-list__pagination .right::after {
    content: ' \000BB';
    color: #1890ba;
    padding: 0 0 0 0.15rem; }

.component.latest-blog-posts ul#blog-list a, .component.latest-blog-posts ul#blog-list .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.latest-blog-posts ul#blog-list .search-filter-button, .component.latest-blog-posts ul#blog-list .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.latest-blog-posts ul#blog-list .close-button, .component.latest-blog-posts ul#blog-list .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.latest-blog-posts ul#blog-list label, .component.latest-blog-posts ul#blog-list .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.latest-blog-posts ul#blog-list .close-button {
  padding: 1rem 4rem; }
  @media (min-width: 992px) {
    .component.latest-blog-posts ul#blog-list a, .component.latest-blog-posts ul#blog-list .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.latest-blog-posts ul#blog-list .search-filter-button, .component.latest-blog-posts ul#blog-list .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.latest-blog-posts ul#blog-list .close-button, .component.latest-blog-posts ul#blog-list .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.latest-blog-posts ul#blog-list label, .component.latest-blog-posts ul#blog-list .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.latest-blog-posts ul#blog-list .close-button {
      padding-right: 0;
      padding-left: 0;
      padding-top: 0.5rem;
      padding-bottom: 1rem; } }

.component.latest-blog-posts ul#blog-list .news-image img {
  max-height: unset; }

.component.two-column-grid-form {
  padding: 0 2rem 2rem; }
  .component.two-column-grid-form .component-content .scfForm {
    background-color: #e8e8e9;
    border-radius: 4px;
    padding-bottom: 3rem; }
    .component.two-column-grid-form .component-content .scfForm label {
      color: #333;
      font-weight: bold; }
    .component.two-column-grid-form .component-content .scfForm input,
    .component.two-column-grid-form .component-content .scfForm select,
    .component.two-column-grid-form .component-content .scfForm .nice-select.scfListBox,
    .component.two-column-grid-form .component-content .scfForm .nice-select.scfDropList,
    .component.two-column-grid-form .component-content .scfForm .nice-select.scfDropList.open .list {
      border-radius: 4px;
      background-color: #ffffff;
      color: #333; }
    .component.two-column-grid-form .component-content .scfForm .nice-select {
      display: inline-block;
      font-size: 1rem;
      width: auto;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border: 2px solid #b4b5b7;
      border-radius: 0;
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #4d4d4d;
      line-height: 1;
      background-color: #eaeaea; }
      .component.two-column-grid-form .component-content .scfForm .nice-select:focus {
        border-color: #f7941d; }
      .component.two-column-grid-form .component-content .scfForm .nice-select.scfDropList {
        display: flex;
        align-items: center; }
        .component.two-column-grid-form .component-content .scfForm .nice-select.scfDropList span {
          font-size: 1rem; }
    .component.two-column-grid-form .component-content .scfForm .nice-select .option:hover, .component.two-column-grid-form .component-content .scfForm .nice-select .option.focus, .component.two-column-grid-form .component-content .scfForm .nice-select .option.selected.focus {
      background-color: #f7941d; }
    .component.two-column-grid-form .component-content .scfForm input:not([type='radio']):not([type='checkbox']):not([type='submit']),
    .component.two-column-grid-form .component-content .scfForm textarea,
    .component.two-column-grid-form .component-content .scfForm select {
      display: inline-block;
      font-size: 1rem;
      width: auto;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border: 2px solid #a9a9a9;
      border-radius: 0;
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #333;
      line-height: 1;
      background-color: #ffffff;
      width: 100%; }
      .component.two-column-grid-form .component-content .scfForm input:not([type='radio']):not([type='checkbox']):not([type='submit']):focus,
      .component.two-column-grid-form .component-content .scfForm textarea:focus,
      .component.two-column-grid-form .component-content .scfForm select:focus {
        border-color: #f7941d; }
    .component.two-column-grid-form .component-content .scfForm input[type='submit'],
    .component.two-column-grid-form .component-content .scfForm button,
    .component.two-column-grid-form .component-content .scfForm .like-button {
      background-color: #f7941d;
      border-radius: 4px;
      border: 0;
      border-bottom: 1px solid #d97a08;
      color: #ffffff;
      padding: 0.5rem 2rem;
      transition: background-color 0.15s ease;
      white-space: nowrap;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      border-radius: 0;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0.05em;
      transition: all 0.15s ease;
      width: 100%;
      padding: 1rem 2rem; }
      .component.two-column-grid-form .component-content .scfForm input[type='submit']:hover,
      .component.two-column-grid-form .component-content .scfForm button:hover,
      .component.two-column-grid-form .component-content .scfForm .like-button:hover {
        background-color: #d97a08; }
      .component.two-column-grid-form .component-content .scfForm input[type='submit']:focus,
      .component.two-column-grid-form .component-content .scfForm button:focus,
      .component.two-column-grid-form .component-content .scfForm .like-button:focus {
        box-shadow: 0px 0px 6px 0px #fac380; }
    .component.two-column-grid-form .component-content .scfForm .scfSubmitButtonBorder {
      text-align: center;
      padding: 0 2rem; }
    .component.two-column-grid-form .component-content .scfForm .scfTitleBorder {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 1.2rem;
      line-height: 1.3;
      color: inherit;
      font-weight: 400;
      background: #1890ba;
      color: white;
      border-radius: 4px 4px 0 0;
      padding: 2rem; }
      @media all and (-ms-high-contrast: none) {
        .component.two-column-grid-form .component-content .scfForm .scfTitleBorder {
          font-weight: bold; } }
      @media only screen and (max-width: 767px) {
        .component.two-column-grid-form .component-content .scfForm .scfTitleBorder {
          font-size: 1.25rem; } }
      @media (min-width: 992px) {
        .component.two-column-grid-form .component-content .scfForm .scfTitleBorder {
          padding: 2rem;
          font-size: 1.5rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 0; } }
    .component.two-column-grid-form .component-content .scfForm .scfSectionBorder {
      margin: 0; }
    .component.two-column-grid-form .component-content .scfForm fieldset.scfSectionBorderAsFieldSet .scfSectionContent,
    .component.two-column-grid-form .component-content .scfForm .scfSectionBorder .scfSectionContent {
      padding: 1rem 2rem; }
      @media (min-width: 992px) {
        .component.two-column-grid-form .component-content .scfForm fieldset.scfSectionBorderAsFieldSet .scfSectionContent,
        .component.two-column-grid-form .component-content .scfForm .scfSectionBorder .scfSectionContent {
          padding: 2rem 3rem; } }
      .component.two-column-grid-form .component-content .scfForm fieldset.scfSectionBorderAsFieldSet .scfSectionContent .halfAvailableWidth,
      .component.two-column-grid-form .component-content .scfForm .scfSectionBorder .scfSectionContent .halfAvailableWidth {
        width: 46%; }
        .component.two-column-grid-form .component-content .scfForm fieldset.scfSectionBorderAsFieldSet .scfSectionContent .halfAvailableWidth:nth-child(1),
        .component.two-column-grid-form .component-content .scfForm .scfSectionBorder .scfSectionContent .halfAvailableWidth:nth-child(1) {
          margin-right: 4%; }
        .component.two-column-grid-form .component-content .scfForm fieldset.scfSectionBorderAsFieldSet .scfSectionContent .halfAvailableWidth:nth-child(2),
        .component.two-column-grid-form .component-content .scfForm .scfSectionBorder .scfSectionContent .halfAvailableWidth:nth-child(2) {
          margin-left: 4%; }
    .component.two-column-grid-form .component-content .scfForm .scfValidatorRequired,
    .component.two-column-grid-form .component-content .scfForm .scfRequired {
      color: #f74407; }
    .component.two-column-grid-form .component-content .scfForm .scfValidationSummary {
      padding: 1rem;
      display: none !important; }
      .component.two-column-grid-form .component-content .scfForm .scfValidationSummary:not(:empty) {
        margin-bottom: 0.625rem; }
    .component.two-column-grid-form .component-content .scfForm .scfSubmitSummary span {
      background: #f7bd00;
      border-radius: 4px;
      color: #333;
      display: block;
      margin: 2rem 0;
      padding: 1rem; }
    .component.two-column-grid-form .component-content .scfForm .scfValidator {
      margin: 3px 0; }
    .component.two-column-grid-form .component-content .scfForm .scfCheckbox {
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding-bottom: 1rem; }
      .component.two-column-grid-form .component-content .scfForm .scfCheckbox label {
        font-weight: normal;
        font-size: 15px; }
    .component.two-column-grid-form .component-content .scfForm .scfCheckboxBorder {
      position: relative; }
      .component.two-column-grid-form .component-content .scfForm .scfCheckboxBorder label {
        display: inline-block; }
      .component.two-column-grid-form .component-content .scfForm .scfCheckboxBorder .scfRequired,
      .component.two-column-grid-form .component-content .scfForm .scfCheckboxBorder .scfValidatorRequired {
        display: none; }
    .component.two-column-grid-form .component-content .scfForm textarea {
      background-color: #ffffff; }

.form.yh-banner-email-optin .component-content {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .form.yh-banner-email-optin .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }
  @media (min-width: 768px) {
    .form.yh-banner-email-optin .component-content .scfForm {
      padding: 0; } }
  @media (min-width: 768px) {
    .form.yh-banner-email-optin .component-content .scfForm {
      display: flex;
      align-items: center;
      justify-content: center; } }
  .form.yh-banner-email-optin .component-content .scfForm .scfSectionBorder {
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .form.yh-banner-email-optin .component-content .scfForm .scfSectionBorder {
        margin: 0; } }
  .form.yh-banner-email-optin .component-content .scfForm .scfSectionContent {
    float: none; }
  .form.yh-banner-email-optin .component-content .scfForm .scfTitleBorder {
    color: #ffffff;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 400;
    font-size: 15px;
    width: 100%;
    display: block; }
    @media (min-width: 768px) {
      .form.yh-banner-email-optin .component-content .scfForm .scfTitleBorder {
        width: auto;
        font-size: 18px;
        margin: 0 2em 0 0; } }
  .form.yh-banner-email-optin .component-content .scfForm .scfIntroBorder {
    display: none; }
  .form.yh-banner-email-optin .component-content .scfForm .scfEmailGeneralPanel {
    padding: 0; }
  .form.yh-banner-email-optin .component-content .scfForm .scfEmailBorder {
    margin: 0; }
  .form.yh-banner-email-optin .component-content .scfForm .scfEmailTextBox {
    background-color: #ffffff;
    border: 1px solid #999;
    border-radius: 4px;
    width: 70%;
    margin: 0; }
    @media (min-width: 768px) {
      .form.yh-banner-email-optin .component-content .scfForm .scfEmailTextBox {
        border-radius: 4px 0 0 4px;
        width: 100%;
        height: auto;
        border-right: 0; } }
  .form.yh-banner-email-optin .component-content .scfForm .scfRequired {
    display: none; }
  .form.yh-banner-email-optin .component-content .scfForm .scfSubmitButton {
    width: 100%;
    display: block;
    background-color: #f7941d;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    border-bottom: 1px solid #d97a08;
    padding: 1rem 2rem;
    transition: background-color 0.3s ease;
    margin-top: 1rem; }
    .form.yh-banner-email-optin .component-content .scfForm .scfSubmitButton:hover {
      background-color: #f9ab4e; }
    .form.yh-banner-email-optin .component-content .scfForm .scfSubmitButton:focus {
      box-shadow: 0px 0px 6px 0px #1890ba; }
    .form.yh-banner-email-optin .component-content .scfForm .scfSubmitButton:active, .form.yh-banner-email-optin .component-content .scfForm .scfSubmitButton.nice-select.open {
      border-top: 3px solid #d97a08;
      border-bottom: 0;
      border-left: 3px solid #d97a08; }
    @media (min-width: 768px) {
      .form.yh-banner-email-optin .component-content .scfForm .scfSubmitButton {
        margin-top: 0;
        max-width: 150px;
        border-radius: 0 4px 4px 0; } }
  .form.yh-banner-email-optin .component-content .scfForm label {
    display: none; }

.image-spot-element-link {
  display: flex;
  align-items: center;
  justify-content: center; }

.component.rich-text.yh-banner {
  text-align: center;
  padding: 3rem 2rem;
  margin: 3rem 0; }
  @media (min-width: 768px) {
    .component.rich-text.yh-banner {
      padding: 6em;
      font-size: 18px; } }
  @media (min-width: 992px) {
    .component.rich-text.yh-banner {
      padding-left: 2rem;
      padding-right: 2rem; } }

@media (min-width: 768px) {
  .component.rich-text.rich-text-no-padding .component-content {
    padding: 0rem; } }

.component.rich-text-list .component-content ul li {
  padding: 0 2rem; }

.rich-text.rich-text-article-width .component-content {
  max-width: 100%;
  width: 50rem; }
  @media (min-width: 768px) {
    .rich-text.rich-text-article-width .component-content {
      max-width: 90%; } }

.two-section-grid-anchorlinks .rich-text .link-spacer {
  height: 0;
  padding: 0; }

.brand-logos .component-content div {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important; }
  @media (min-width: 992px) {
    .brand-logos .component-content div {
      flex-wrap: nowrap;
      justify-content: space-between !important; } }
  .brand-logos .component-content div img {
    padding: 10px;
    width: auto; }
    @media (min-width: 992px) {
      .brand-logos .component-content div img {
        padding: 0; } }

@media (min-width: 992px) {
  .component.text-image-spot .component-content .text-image-spot-text-container {
    vertical-align: middle; } }

.component.text-image-spot .component-content .text-image-spot-text-container .text-image-spot-text {
  padding-top: 0rem;
  padding-bottom: 1rem; }

.component.component.text-image-spot {
  display: block;
  table-layout: unset; }
  @media (min-width: 992px) {
    .component.component.text-image-spot .component-content {
      display: flex; } }
  .component.component.text-image-spot .component-content .text-image-spot-image-container {
    display: block; }
    .component.component.text-image-spot .component-content .text-image-spot-image-container .text-image-spot-image img {
      float: none; }
      @media (min-width: 1200px) {
        .component.component.text-image-spot .component-content .text-image-spot-image-container .text-image-spot-image img {
          float: left; } }
  .component.component.text-image-spot .component-content .text-image-spot-text-container {
    display: flex;
    align-items: center; }
    .component.component.text-image-spot .component-content .text-image-spot-text-container .text-image-spot-text {
      float: none;
      width: auto; }
      @media (min-width: 992px) {
        .component.component.text-image-spot .component-content .text-image-spot-text-container .text-image-spot-text {
          width: 56%;
          padding-left: 1rem; } }

.two-section-grid.carousel-two-column .carousel a, .two-section-grid.carousel-two-column .carousel .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .two-section-grid.carousel-two-column .carousel .search-filter-button, .two-section-grid.carousel-two-column .carousel .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .two-section-grid.carousel-two-column .carousel .close-button, .two-section-grid.carousel-two-column .carousel .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .two-section-grid.carousel-two-column .carousel label, .two-section-grid.carousel-two-column .carousel .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .two-section-grid.carousel-two-column .carousel .close-button,
.carousel a,
.carousel .component.locations-spot .locations-search .search-filter-button,
.component.locations-spot .locations-search .carousel .search-filter-button,
.carousel .component.locations-spot .filter-list .close-button,
.component.locations-spot .filter-list .carousel .close-button,
.carousel .component.locations-spot .filter-list .filter-row label,
.component.locations-spot .filter-list .filter-row .carousel label,
.carousel .component.locations-spot .location-detail .close-button,
.component.locations-spot .location-detail .carousel .close-button {
  color: #4d4d4d; }
  .two-section-grid.carousel-two-column .carousel a:hover, .two-section-grid.carousel-two-column .carousel .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search .two-section-grid.carousel-two-column .carousel .search-filter-button:hover, .two-section-grid.carousel-two-column .carousel .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list .two-section-grid.carousel-two-column .carousel .close-button:hover, .two-section-grid.carousel-two-column .carousel .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row .two-section-grid.carousel-two-column .carousel label:hover, .two-section-grid.carousel-two-column .carousel .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail .two-section-grid.carousel-two-column .carousel .close-button:hover,
  .carousel a:hover,
  .carousel .component.locations-spot .locations-search .search-filter-button:hover,
  .component.locations-spot .locations-search .carousel .search-filter-button:hover,
  .carousel .component.locations-spot .filter-list .close-button:hover,
  .component.locations-spot .filter-list .carousel .close-button:hover,
  .carousel .component.locations-spot .filter-list .filter-row label:hover,
  .component.locations-spot .filter-list .filter-row .carousel label:hover,
  .carousel .component.locations-spot .location-detail .close-button:hover,
  .component.locations-spot .location-detail .carousel .close-button:hover {
    color: #1890ba;
    transition: color 0.3s; }

.two-section-grid.carousel-two-column .carousel .component.intro-banner.make-slide-clickable,
.carousel .component.intro-banner.make-slide-clickable {
  cursor: pointer; }

.two-section-grid.carousel-two-column .carousel .component.intro-banner .intro-banner-content,
.carousel .component.intro-banner .intro-banner-content {
  display: none; }

.two-section-grid.carousel-two-column .carousel .slider-tabs,
.carousel .slider-tabs {
  bottom: 0;
  width: 120px;
  margin: 0 auto; }
  .two-section-grid.carousel-two-column .carousel .slider-tabs .tabs-content,
  .carousel .slider-tabs .tabs-content {
    width: 100%;
    max-width: unset;
    padding: 0 0 5px; }
    .two-section-grid.carousel-two-column .carousel .slider-tabs .tabs-content .slider-tab,
    .carousel .slider-tabs .tabs-content .slider-tab {
      opacity: 0.85;
      border-color: white; }
      .two-section-grid.carousel-two-column .carousel .slider-tabs .tabs-content .slider-tab:first-child,
      .carousel .slider-tabs .tabs-content .slider-tab:first-child {
        margin-left: 0; }

.two-section-grid.carousel-two-column .carousel .slider-arrow-left,
.two-section-grid.carousel-two-column .carousel .slider-arrow-right,
.carousel .slider-arrow-left,
.carousel .slider-arrow-right {
  display: none; }

#content .component.four-section-grid > .component-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    #content .component.four-section-grid > .component-content {
      flex-wrap: nowrap; } }
  #content .component.four-section-grid > .component-content [class*="section"] {
    width: 250px; }
    @media (min-width: 992px) {
      #content .component.four-section-grid > .component-content [class*="section"] {
        margin-right: 1rem; } }
    #content .component.four-section-grid > .component-content [class*="section"] .component.link-list .component-content {
      width: auto;
      max-width: 100%;
      margin: 0; }
      #content .component.four-section-grid > .component-content [class*="section"] .component.link-list .component-content li {
        list-style-type: none;
        border-bottom: 1px solid #cecfd0;
        padding: 1rem 0;
        text-indent: 0; }
        #content .component.four-section-grid > .component-content [class*="section"] .component.link-list .component-content li:last-child {
          border-bottom: 0; }

.locations-spot .header {
  opacity: 1; }

.component.location-spot-search {
  padding-top: 0; }
  .component.location-spot-search .component-content .locations-container.desktop {
    height: auto; }
  .component.location-spot-search .component-content .locations-container.mobile {
    height: auto; }
  .component.location-spot-search .component-content .locations-container .locations-search {
    background: transparent;
    padding: 1rem 0 0 0;
    border: 0; }
    .component.location-spot-search .component-content .locations-container .locations-search input {
      border: 2px solid;
      border-color: #b4b5b7; }

.container > .component-content .component.two-section-grid .component-content {
  width: 100%;
  max-width: 100%; }

.component.two-section-grid.wide-thin-columns > .component-content {
  padding: 0; }
  @media (min-width: 992px) {
    .component.two-section-grid.wide-thin-columns > .component-content {
      display: flex;
      padding: 1rem; } }
  .component.two-section-grid.wide-thin-columns > .component-content .section-one {
    float: none !important;
    width: 100%; }
    @media (min-width: 992px) {
      .component.two-section-grid.wide-thin-columns > .component-content .section-one {
        width: 62.5% !important; } }
  .component.two-section-grid.wide-thin-columns > .component-content .section-two {
    float: none !important;
    width: 100%; }
    @media (min-width: 992px) {
      .component.two-section-grid.wide-thin-columns > .component-content .section-two {
        width: 37.5% !important; } }
    @media (min-width: 992px) {
      .component.two-section-grid.wide-thin-columns > .component-content .section-two .two-column-grid-form {
        padding: 0; } }

.component.two-section-grid.widest-thin-columns {
  padding: 0; }
  .component.two-section-grid.widest-thin-columns > .component-content {
    padding: 0; }
    @media (min-width: 992px) {
      .component.two-section-grid.widest-thin-columns > .component-content {
        display: flex;
        padding: 1rem; } }
    .component.two-section-grid.widest-thin-columns > .component-content .section-one {
      float: none !important;
      width: 100%;
      margin-bottom: 5rem; }
      @media (min-width: 992px) {
        .component.two-section-grid.widest-thin-columns > .component-content .section-one {
          width: 75% !important;
          margin-bottom: 0; } }
      .component.two-section-grid.widest-thin-columns > .component-content .section-one .text-image-spot {
        margin-bottom: 5rem; }
        @media (min-width: 992px) {
          .component.two-section-grid.widest-thin-columns > .component-content .section-one .text-image-spot {
            margin-bottom: 0; } }
        .component.two-section-grid.widest-thin-columns > .component-content .section-one .text-image-spot .component-content .text-image-spot-image-container {
          margin-bottom: 1rem; }
          @media (min-width: 992px) {
            .component.two-section-grid.widest-thin-columns > .component-content .section-one .text-image-spot .component-content .text-image-spot-image-container {
              margin-bottom: 0; } }
          .component.two-section-grid.widest-thin-columns > .component-content .section-one .text-image-spot .component-content .text-image-spot-image-container .text-image-spot-image {
            text-align: center; }
        .component.two-section-grid.widest-thin-columns > .component-content .section-one .text-image-spot .component-content .text-image-spot-text-container {
          align-items: flex-start; }
          .component.two-section-grid.widest-thin-columns > .component-content .section-one .text-image-spot .component-content .text-image-spot-text-container .text-image-spot-text {
            width: 100%; }
    .component.two-section-grid.widest-thin-columns > .component-content .section-two {
      float: none !important;
      width: 100%; }
      @media (min-width: 992px) {
        .component.two-section-grid.widest-thin-columns > .component-content .section-two {
          width: 25% !important;
          padding-left: 6%; } }

.two-section-grid.carousel-two-column .component-content .section-two {
  padding: 4rem 0;
  background-color: #ffffff;
  border-bottom: 2px solid;
  border-color: #e8e8e9; }
  @media (min-width: 992px) {
    .two-section-grid.carousel-two-column .component-content .section-two {
      padding: 2rem;
      background-color: transparent;
      border-bottom: 0; } }

.component.two-section-grid-anchorlinks {
  height: 100%; }
  .component.two-section-grid-anchorlinks > .component-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0; }
    @media (min-width: 1200px) {
      .component.two-section-grid-anchorlinks > .component-content {
        position: relative;
        width: 90.25rem;
        max-width: 1140px; } }
    @media (min-width: 992px) {
      .component.two-section-grid-anchorlinks > .component-content {
        display: flex; } }
    .component.two-section-grid-anchorlinks > .component-content .section-one {
      flex: 1; }
      .component.two-section-grid-anchorlinks > .component-content .section-one .component {
        height: 100%; }
        .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content {
          padding: 2rem;
          width: auto; }
          .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content ul {
            list-style-position: outside; }
            .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content ul li {
              text-indent: 0;
              padding-left: 0.25rem;
              line-height: 1.6; }
          .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link {
            display: none;
            position: fixed;
            bottom: 1rem;
            left: 1rem;
            transition: background 0.93s ease; }
            @media (min-width: 992px) {
              .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link {
                left: unset; } }
            .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link .icon-interface-top {
              display: flex;
              align-items: center; }
              .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link .icon-interface-top:before {
                content: '\eb24';
                background-color: #1890ba;
                border-radius: 100%;
                color: #ffffff;
                font-weight: bold;
                padding: 0.5rem 0.5rem 0.5rem 0.7rem;
                font-size: 1.5rem;
                margin-right: 1.5rem; }
                @media (min-width: 992px) {
                  .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link .icon-interface-top:before {
                    margin-right: 0.5rem; } }
            .component.two-section-grid-anchorlinks > .component-content .section-one .component .component-content .backToTop-link:hover .icon-interface-top:before {
              background-color: #808285; }
    .component.two-section-grid-anchorlinks > .component-content .section-two {
      flex: 2; }
      .component.two-section-grid-anchorlinks > .component-content .section-two .component-content table {
        width: 100% !important; }

#plan-landing-page .component.intro-banner,
#plan-landing-page-with-nav .component.intro-banner {
  background-color: #1890ba default; }
  #plan-landing-page .component.intro-banner .intro-banner-image,
  #plan-landing-page-with-nav .component.intro-banner .intro-banner-image {
    display: none; }
    @media (min-width: 1200px) {
      #plan-landing-page .component.intro-banner .intro-banner-image,
      #plan-landing-page-with-nav .component.intro-banner .intro-banner-image {
        display: block; } }
  #plan-landing-page .component.intro-banner--display-mobile-image .intro-banner-image,
  #plan-landing-page-with-nav .component.intro-banner--display-mobile-image .intro-banner-image {
    display: block; }
  #plan-landing-page .component.intro-banner .intro-banner-content,
  #plan-landing-page-with-nav .component.intro-banner .intro-banner-content {
    top: 0;
    bottom: 0;
    color: #ffffff default; }
    @media (min-width: 1200px) {
      #plan-landing-page .component.intro-banner .intro-banner-content,
      #plan-landing-page-with-nav .component.intro-banner .intro-banner-content {
        color: #4d4d4d default; } }
  #plan-landing-page .component.intro-banner .outer-pos-wrapper,
  #plan-landing-page-with-nav .component.intro-banner .outer-pos-wrapper {
    display: flex;
    align-items: center; }
  @media (min-width: 1200px) {
    #plan-landing-page .component.intro-banner.content-overflow .intro-banner-content,
    #plan-landing-page-with-nav .component.intro-banner.content-overflow .intro-banner-content {
      position: absolute;
      top: 0;
      bottom: 0; } }
  @media (min-width: 1200px) {
    #plan-landing-page .component.intro-banner.content-overflow .intro-banner-content .outer-pos-wrapper,
    #plan-landing-page-with-nav .component.intro-banner.content-overflow .intro-banner-content .outer-pos-wrapper {
      padding: 0 2rem; } }

#plan-landing-page .component.text-image-spot,
#plan-landing-page-with-nav .component.text-image-spot {
  display: block; }
  #plan-landing-page .component.text-image-spot .component-content,
  #plan-landing-page-with-nav .component.text-image-spot .component-content {
    display: flex;
    justify-content: space-between; }
    #plan-landing-page .component.text-image-spot .component-content .text-image-spot-image-container,
    #plan-landing-page-with-nav .component.text-image-spot .component-content .text-image-spot-image-container {
      flex: 2;
      display: flex;
      align-items: center; }
      #plan-landing-page .component.text-image-spot .component-content .text-image-spot-image-container .text-image-spot-image,
      #plan-landing-page-with-nav .component.text-image-spot .component-content .text-image-spot-image-container .text-image-spot-image {
        float: none; }
        #plan-landing-page .component.text-image-spot .component-content .text-image-spot-image-container .text-image-spot-image img,
        #plan-landing-page-with-nav .component.text-image-spot .component-content .text-image-spot-image-container .text-image-spot-image img {
          float: none; }
    #plan-landing-page .component.text-image-spot .component-content .text-image-spot-text-container,
    #plan-landing-page-with-nav .component.text-image-spot .component-content .text-image-spot-text-container {
      flex: 3; }
      #plan-landing-page .component.text-image-spot .component-content .text-image-spot-text-container .text-image-spot-text,
      #plan-landing-page-with-nav .component.text-image-spot .component-content .text-image-spot-text-container .text-image-spot-text {
        float: none;
        width: auto; }

#plan-landing-page .component.two-section-grid .component-content .section-one .component > .component-content,
#plan-landing-page-with-nav .component.two-section-grid .component-content .section-one .component > .component-content {
  width: 100%;
  max-width: 100%; }

#plan-landing-page .component.two-section-grid .component-content .section-two .component > .component-content,
#plan-landing-page-with-nav .component.two-section-grid .component-content .section-two .component > .component-content {
  width: 100%;
  max-width: 100%; }

#plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content,
#plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content {
  padding: 0 0 3rem;
  display: flex;
  flex-direction: column-reverse; }
  @media (min-width: 992px) {
    #plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content,
    #plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content {
      padding: 2rem 0;
      flex-direction: row; } }
  #plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content .section-one,
  #plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content .section-one {
    float: none !important;
    width: 100%;
    padding: 1rem; }
    @media (min-width: 992px) {
      #plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content .section-one,
      #plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content .section-one {
        padding-right: 5rem; } }
    #plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content .section-one ul,
    #plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content .section-one ol,
    #plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content .section-one ul,
    #plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content .section-one ol {
      list-style-position: inherit;
      word-break: break-word; }
      #plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content .section-one ul li,
      #plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content .section-one ol li,
      #plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content .section-one ul li,
      #plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content .section-one ol li {
        line-height: 1.4;
        text-indent: 0;
        margin-left: 1rem;
        padding-left: 0; }
  #plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content .section-two,
  #plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content .section-two {
    float: none !important;
    width: 100%; }
    @media (min-width: 992px) {
      #plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content .section-two,
      #plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content .section-two {
        width: 46.875% !important; } }
    @media (min-width: 992px) {
      #plan-landing-page .component.two-section-grid.wide-thin-columns > .component-content .section-two .two-column-grid-form,
      #plan-landing-page-with-nav .component.two-section-grid.wide-thin-columns > .component-content .section-two .two-column-grid-form {
        padding: 0; } }

#plan-landing-page #footer .two-section-grid > .component-content,
#plan-landing-page-with-nav #footer .two-section-grid > .component-content {
  padding: 2rem;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    #plan-landing-page #footer .two-section-grid > .component-content,
    #plan-landing-page-with-nav #footer .two-section-grid > .component-content {
      position: relative;
      width: 90.25rem;
      max-width: 1140px; } }
  @media (min-width: 768px) {
    #plan-landing-page #footer .two-section-grid > .component-content,
    #plan-landing-page-with-nav #footer .two-section-grid > .component-content {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  #plan-landing-page #footer .two-section-grid > .component-content .section-one .component-content,
  #plan-landing-page #footer .two-section-grid > .component-content .section-two .component-content,
  #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one .component-content,
  #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-two .component-content {
    width: auto;
    padding: 0; }
  #plan-landing-page #footer .two-section-grid > .component-content .section-one ul,
  #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul {
    text-align: center;
    width: 100%;
    list-style: none; }
    @media (min-width: 768px) {
      #plan-landing-page #footer .two-section-grid > .component-content .section-one ul,
      #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul {
        display: flex;
        align-items: center;
        justify-content: center; } }
    #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li,
    #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li {
      border-bottom: 1px solid #999999;
      padding-bottom: 1rem;
      padding-top: 1rem;
      margin: 0; }
      #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li:last-child,
      #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li:last-child {
        border-bottom: 0; }
      @media (min-width: 768px) {
        #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li,
        #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li {
          border-bottom: 0;
          text-indent: 0;
          margin: 0;
          border-right: 1px solid #999999;
          padding: 0.1em 1em; } }
      #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li:first-child,
      #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li:first-child {
        padding-left: 0;
        margin-left: 0; }
      #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li:last-child,
      #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li:last-child {
        border-right: 0; }
      #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li a, #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li .search-filter-button, #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li .close-button, #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li label, #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #plan-landing-page #footer .two-section-grid > .component-content .section-one ul li .close-button,
      #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li a,
      #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li .component.locations-spot .locations-search .search-filter-button,
      .component.locations-spot .locations-search #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li .search-filter-button,
      #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li .component.locations-spot .filter-list .close-button,
      .component.locations-spot .filter-list #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li .close-button,
      #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li .component.locations-spot .filter-list .filter-row label,
      .component.locations-spot .filter-list .filter-row #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li label,
      #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li .component.locations-spot .location-detail .close-button,
      .component.locations-spot .location-detail #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-one ul li .close-button {
        font-weight: 400; }
  #plan-landing-page #footer .two-section-grid > .component-content .section-two .rich-text,
  #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-two .rich-text {
    text-align: center;
    color: #999999; }
    @media (min-width: 992px) {
      #plan-landing-page #footer .two-section-grid > .component-content .section-two .rich-text,
      #plan-landing-page-with-nav #footer .two-section-grid > .component-content .section-two .rich-text {
        text-align: right; } }

#plan-landing-page .form .component-content,
#plan-landing-page-with-nav .form .component-content {
  padding: 2rem 0; }
  @media (min-width: 992px) {
    #plan-landing-page .form .component-content,
    #plan-landing-page-with-nav .form .component-content {
      padding: 0 1rem 0 0 !important; } }
  #plan-landing-page .form .component-content .scfForm,
  #plan-landing-page-with-nav .form .component-content .scfForm {
    background-color: #eaeaea;
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
    padding-bottom: 2rem;
    font-family: "Open Sans", "Arial", sans-serif; }
    #plan-landing-page .form .component-content .scfForm label,
    #plan-landing-page-with-nav .form .component-content .scfForm label {
      display: block;
      padding-bottom: 0.3125rem;
      color: #333;
      font-weight: bold; }
    #plan-landing-page .form .component-content .scfForm input:not([type='radio']):not([type='checkbox']):not([type='submit']),
    #plan-landing-page .form .component-content .scfForm textarea,
    #plan-landing-page .form .component-content .scfForm select,
    #plan-landing-page-with-nav .form .component-content .scfForm input:not([type='radio']):not([type='checkbox']):not([type='submit']),
    #plan-landing-page-with-nav .form .component-content .scfForm textarea,
    #plan-landing-page-with-nav .form .component-content .scfForm select {
      display: inline-block;
      font-size: 1rem;
      width: auto;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border: 2px solid #a9a9a9;
      border-radius: 0;
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #333;
      line-height: 1;
      background-color: #ffffff;
      width: 100%; }
      #plan-landing-page .form .component-content .scfForm input:not([type='radio']):not([type='checkbox']):not([type='submit']):focus,
      #plan-landing-page .form .component-content .scfForm textarea:focus,
      #plan-landing-page .form .component-content .scfForm select:focus,
      #plan-landing-page-with-nav .form .component-content .scfForm input:not([type='radio']):not([type='checkbox']):not([type='submit']):focus,
      #plan-landing-page-with-nav .form .component-content .scfForm textarea:focus,
      #plan-landing-page-with-nav .form .component-content .scfForm select:focus {
        border-color: #f7941d; }
    #plan-landing-page .form .component-content .scfForm input[type='submit'],
    #plan-landing-page .form .component-content .scfForm button,
    #plan-landing-page .form .component-content .scfForm .like-button,
    #plan-landing-page-with-nav .form .component-content .scfForm input[type='submit'],
    #plan-landing-page-with-nav .form .component-content .scfForm button,
    #plan-landing-page-with-nav .form .component-content .scfForm .like-button {
      background-color: #f7941d;
      border-radius: 4px;
      border: 0;
      border-bottom: 1px solid #d97a08;
      color: #ffffff;
      padding: 0.5rem 2rem;
      transition: background-color 0.15s ease;
      white-space: nowrap;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      border-radius: 0;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0.05em;
      transition: all 0.15s ease;
      width: 100%;
      padding: 1rem 2rem; }
      #plan-landing-page .form .component-content .scfForm input[type='submit']:hover,
      #plan-landing-page .form .component-content .scfForm button:hover,
      #plan-landing-page .form .component-content .scfForm .like-button:hover,
      #plan-landing-page-with-nav .form .component-content .scfForm input[type='submit']:hover,
      #plan-landing-page-with-nav .form .component-content .scfForm button:hover,
      #plan-landing-page-with-nav .form .component-content .scfForm .like-button:hover {
        background-color: #d97a08; }
      #plan-landing-page .form .component-content .scfForm input[type='submit']:focus,
      #plan-landing-page .form .component-content .scfForm button:focus,
      #plan-landing-page .form .component-content .scfForm .like-button:focus,
      #plan-landing-page-with-nav .form .component-content .scfForm input[type='submit']:focus,
      #plan-landing-page-with-nav .form .component-content .scfForm button:focus,
      #plan-landing-page-with-nav .form .component-content .scfForm .like-button:focus {
        box-shadow: 0px 0px 6px 0px #fac380; }
    #plan-landing-page .form .component-content .scfForm .scfTitleBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfTitleBorder {
      margin: 0;
      background-color: #1890ba;
      color: #ffffff;
      text-align: center;
      padding: 2rem;
      font-size: 1.3rem; }
    #plan-landing-page .form .component-content .scfForm .scfSectionContent,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfSectionContent {
      padding: 2rem;
      padding-bottom: 0; }
    #plan-landing-page .form .component-content .scfForm .scfDroplistBorder,
    #plan-landing-page .form .component-content .scfForm .scfEmailBorder,
    #plan-landing-page .form .component-content .scfForm .scfMultipleLineTextBorder,
    #plan-landing-page .form .component-content .scfForm .scfSingleLineTextBorder,
    #plan-landing-page .form .component-content .scfForm .scfPasswordBorder,
    #plan-landing-page .form .component-content .scfForm .scfNumberBorder,
    #plan-landing-page .form .component-content .scfForm .scfDatePickerBorder,
    #plan-landing-page .form .component-content .scfForm .scfDateBorder,
    #plan-landing-page .form .component-content .scfForm .scfRadioButtonListBorder,
    #plan-landing-page .form .component-content .scfForm .scfListBoxBorder,
    #plan-landing-page .form .component-content .scfForm .scfCheckBoxListBorder,
    #plan-landing-page .form .component-content .scfForm .scfFileUploadBorder,
    #plan-landing-page .form .component-content .scfForm .scfDateSelectorBorder,
    #plan-landing-page .form .component-content .scfForm .scfCreditCardBorder,
    #plan-landing-page .form .component-content .scfForm .scfConfirmPasswordBorder,
    #plan-landing-page .form .component-content .scfForm .scfCaptchaBorder,
    #plan-landing-page .form .component-content .scfForm .scfTelephoneBorder,
    #plan-landing-page .form .component-content .scfForm .scfSmsTelephoneBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDroplistBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfEmailBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfMultipleLineTextBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfSingleLineTextBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfPasswordBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfNumberBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDatePickerBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDateBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfRadioButtonListBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfListBoxBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfCheckBoxListBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfFileUploadBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDateSelectorBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfCreditCardBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfConfirmPasswordBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfCaptchaBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfTelephoneBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfSmsTelephoneBorder {
      margin-bottom: 0;
      margin-top: 0 !important;
      position: relative; }
    #plan-landing-page .form .component-content .scfForm .scfDropListLabel,
    #plan-landing-page .form .component-content .scfForm .scfEmailLabel,
    #plan-landing-page .form .component-content .scfForm .scfMultipleLineTextLabel,
    #plan-landing-page .form .component-content .scfForm .scfSingleLineTextLabel,
    #plan-landing-page .form .component-content .scfForm .scfPasswordLabel,
    #plan-landing-page .form .component-content .scfForm .scfNumberLabel,
    #plan-landing-page .form .component-content .scfForm .scfDatePickerLabel,
    #plan-landing-page .form .component-content .scfForm .scfDateLabel,
    #plan-landing-page .form .component-content .scfForm .scfRadioButtonListLabel,
    #plan-landing-page .form .component-content .scfForm .scfCheckBoxListLabel,
    #plan-landing-page .form .component-content .scfForm .scfListBoxLabel,
    #plan-landing-page .form .component-content .scfForm .scfFileUploadLabel,
    #plan-landing-page .form .component-content .scfForm .scfDateSelectorLabel,
    #plan-landing-page .form .component-content .scfForm .scfCreditCardLabel,
    #plan-landing-page .form .component-content .scfForm .scfConfirmPasswordLabel,
    #plan-landing-page .form .component-content .scfForm .scfCaptchaLabel,
    #plan-landing-page .form .component-content .scfForm .scfTelephoneLabel,
    #plan-landing-page .form .component-content .scfForm .scfSmsTelephoneLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDropListLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfEmailLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfMultipleLineTextLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfSingleLineTextLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfPasswordLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfNumberLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDatePickerLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDateLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfRadioButtonListLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfCheckBoxListLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfListBoxLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfFileUploadLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDateSelectorLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfCreditCardLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfConfirmPasswordLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfCaptchaLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfTelephoneLabel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfSmsTelephoneLabel {
      float: none;
      clear: none;
      width: 100%;
      padding: 0 0 0.3125rem 0; }
    #plan-landing-page .form .component-content .scfForm .scfEmailGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfMultipleLineGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfSingleLineGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfPasswordGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfNumberGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfDatePickerGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfDateGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfRadioButtonListGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfCheckBoxListGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfFileUploadGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfDateSelectorGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfCreditCardGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfConfirmPasswordGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfCaptchaGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfTelephoneGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfSmsTelephoneGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfEmailGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfMultipleLineGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfSingleLineGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfPasswordGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfNumberGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDatePickerGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDateGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfRadioButtonListGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfCheckBoxListGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfFileUploadGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDateSelectorGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfCreditCardGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfConfirmPasswordGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfCaptchaGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfTelephoneGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfSmsTelephoneGeneralPanel {
      float: none;
      clear: none;
      width: 100%; }
    #plan-landing-page .form .component-content .scfForm .scfValidatorRequired,
    #plan-landing-page .form .component-content .scfForm .scfRequired,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfValidatorRequired,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfRequired {
      float: none;
      position: static;
      margin-left: 0.25rem; }
    #plan-landing-page .form .component-content .scfForm .scfCheckboxBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfCheckboxBorder {
      position: relative;
      padding-top: 1rem; }
      #plan-landing-page .form .component-content .scfForm .scfCheckboxBorder .scfRequired,
      #plan-landing-page-with-nav .form .component-content .scfForm .scfCheckboxBorder .scfRequired {
        display: none; }
    #plan-landing-page .form .component-content .scfForm .scfSubmitButtonBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfSubmitButtonBorder {
      text-align: center;
      padding: 0 2rem; }
    #plan-landing-page .form .component-content .scfForm .scfListBoxGeneralPanel,
    #plan-landing-page .form .component-content .scfForm .scfDropListGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfListBoxGeneralPanel,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfDropListGeneralPanel {
      width: 100%;
      float: none; }
    #plan-landing-page .form .component-content .scfForm .scfError,
    #plan-landing-page .form .component-content .scfForm .scfValidationSummary,
    #plan-landing-page .form .component-content .scfForm .scfValidatorRequired,
    #plan-landing-page .form .component-content .scfForm .scfValidator,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfError,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfValidationSummary,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfValidatorRequired,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfValidator {
      color: #f74407; }
    #plan-landing-page .form .component-content .scfForm .scfValidationSummary,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfValidationSummary {
      margin-left: 0; }
      #plan-landing-page .form .component-content .scfForm .scfValidationSummary:not(:empty),
      #plan-landing-page-with-nav .form .component-content .scfForm .scfValidationSummary:not(:empty) {
        margin-bottom: 0.625rem; }
    #plan-landing-page .form .component-content .scfForm .scfSectionBorderAsFieldSet,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfSectionBorderAsFieldSet {
      padding: 0;
      border: 0;
      margin: 0 0 0.625rem 0; }
    #plan-landing-page .form .component-content .scfForm .scfSectionLegend,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfSectionLegend {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 1.2rem;
      line-height: 1.3;
      color: inherit;
      padding: 0;
      margin: 0;
      margin-bottom: 0.41667rem; }
      @media all and (-ms-high-contrast: none) {
        #plan-landing-page .form .component-content .scfForm .scfSectionLegend,
        #plan-landing-page-with-nav .form .component-content .scfForm .scfSectionLegend {
          font-weight: bold; } }
      @media only screen and (max-width: 767px) {
        #plan-landing-page .form .component-content .scfForm .scfSectionLegend,
        #plan-landing-page-with-nav .form .component-content .scfForm .scfSectionLegend {
          font-size: 1.25rem; } }
    #plan-landing-page .form .component-content .scfForm .scfHiddenFieldBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfHiddenFieldBorder {
      display: none; }
    #plan-landing-page .form .component-content .scfForm .scfIntroBorder,
    #plan-landing-page-with-nav .form .component-content .scfForm .scfIntroBorder {
      display: none; }
    #plan-landing-page .form .component-content .scfForm input[type='checkbox'] + label,
    #plan-landing-page .form .component-content .scfForm input[type='radio'] + label,
    #plan-landing-page-with-nav .form .component-content .scfForm input[type='checkbox'] + label,
    #plan-landing-page-with-nav .form .component-content .scfForm input[type='radio'] + label {
      margin-left: 0.3rem; }
  #plan-landing-page .form .component-content .scfCheckboxBorder,
  #plan-landing-page-with-nav .form .component-content .scfCheckboxBorder {
    clear: both;
    position: relative;
    padding: 2rem 0 0; }
  #plan-landing-page .form .component-content .scfCheckbox,
  #plan-landing-page-with-nav .form .component-content .scfCheckbox {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 1rem; }
    #plan-landing-page .form .component-content .scfCheckbox label,
    #plan-landing-page-with-nav .form .component-content .scfCheckbox label {
      font-weight: normal;
      font-size: 15px; }
  #plan-landing-page .form .component-content .scfFooterBorder,
  #plan-landing-page-with-nav .form .component-content .scfFooterBorder {
    padding: 1rem 2rem 0 2rem; }
    #plan-landing-page .form .component-content .scfFooterBorder p,
    #plan-landing-page-with-nav .form .component-content .scfFooterBorder p {
      font-style: italic;
      font-size: 14px; }
  #plan-landing-page .form .component-content .nice-select,
  #plan-landing-page-with-nav .form .component-content .nice-select {
    border-color: #b4b5b7;
    border-width: 2px; }
    #plan-landing-page .form .component-content .nice-select:after,
    #plan-landing-page-with-nav .form .component-content .nice-select:after {
      border-color: #b4b5b7; }
  #plan-landing-page .form .component-content > div > div,
  #plan-landing-page-with-nav .form .component-content > div > div {
    padding: 2rem 2rem 1rem; }
  #plan-landing-page .form .component-content .scfSubmitSummary,
  #plan-landing-page-with-nav .form .component-content .scfSubmitSummary {
    padding: 0rem; }
  #plan-landing-page .form .component-content .scfSectionBorder,
  #plan-landing-page-with-nav .form .component-content .scfSectionBorder {
    margin: 0.5rem 0; }
  #plan-landing-page .form .component-content fieldset.scfSectionBorderAsFieldSet,
  #plan-landing-page-with-nav .form .component-content fieldset.scfSectionBorderAsFieldSet {
    margin-bottom: 0rem !important; }
  #plan-landing-page .form .component-content .scfSectionContent,
  #plan-landing-page-with-nav .form .component-content .scfSectionContent {
    padding: 0 !important; }
    #plan-landing-page .form .component-content .scfSectionContent > div,
    #plan-landing-page-with-nav .form .component-content .scfSectionContent > div {
      margin-bottom: 1rem !important; }
    #plan-landing-page .form .component-content .scfSectionContent .scfDatePickerBorder,
    #plan-landing-page-with-nav .form .component-content .scfSectionContent .scfDatePickerBorder {
      margin-top: 5px !important; }
  #plan-landing-page .form .component-content .scfSingleLineGeneralPanel,
  #plan-landing-page-with-nav .form .component-content .scfSingleLineGeneralPanel {
    margin-bottom: 0rem !important; }
  #plan-landing-page .form .component-content .scfCheckboxBorder,
  #plan-landing-page-with-nav .form .component-content .scfCheckboxBorder {
    padding-top: 0rem !important; }
  #plan-landing-page .form .component-content .scfCheckbox,
  #plan-landing-page-with-nav .form .component-content .scfCheckbox {
    padding-bottom: 0 !important; }
  @media (min-width: 992px) {
    #plan-landing-page .form .component-content .halfSection fieldset .scfSectionContent,
    #plan-landing-page-with-nav .form .component-content .halfSection fieldset .scfSectionContent {
      display: flex; } }
  @media (min-width: 992px) {
    #plan-landing-page .form .component-content .halfSection fieldset .scfSectionContent > div:nth-child(1),
    #plan-landing-page-with-nav .form .component-content .halfSection fieldset .scfSectionContent > div:nth-child(1) {
      margin-right: 1rem; } }

@media (min-width: 992px) {
  #plan-landing-page a[href^='tel']:link, #plan-landing-page .component.locations-spot .locations-search .search-filter-button[href^='tel']:link, .component.locations-spot .locations-search #plan-landing-page .search-filter-button[href^='tel']:link, #plan-landing-page .component.locations-spot .filter-list .close-button[href^='tel']:link, .component.locations-spot .filter-list #plan-landing-page .close-button[href^='tel']:link, #plan-landing-page .component.locations-spot .filter-list .filter-row label[href^='tel']:link, .component.locations-spot .filter-list .filter-row #plan-landing-page label[href^='tel']:link, #plan-landing-page .component.locations-spot .location-detail .close-button[href^='tel']:link, .component.locations-spot .location-detail #plan-landing-page .close-button[href^='tel']:link,
  #plan-landing-page a[href^='tel']:visited,
  #plan-landing-page .component.locations-spot .locations-search .search-filter-button[href^='tel']:visited,
  .component.locations-spot .locations-search #plan-landing-page .search-filter-button[href^='tel']:visited,
  #plan-landing-page .component.locations-spot .filter-list .close-button[href^='tel']:visited,
  .component.locations-spot .filter-list #plan-landing-page .close-button[href^='tel']:visited,
  #plan-landing-page .component.locations-spot .filter-list .filter-row label[href^='tel']:visited,
  .component.locations-spot .filter-list .filter-row #plan-landing-page label[href^='tel']:visited,
  #plan-landing-page .component.locations-spot .location-detail .close-button[href^='tel']:visited,
  .component.locations-spot .location-detail #plan-landing-page .close-button[href^='tel']:visited,
  #plan-landing-page a[href^='tel']:hover,
  #plan-landing-page .component.locations-spot .locations-search .search-filter-button[href^='tel']:hover,
  .component.locations-spot .locations-search #plan-landing-page .search-filter-button[href^='tel']:hover,
  #plan-landing-page .component.locations-spot .filter-list .close-button[href^='tel']:hover,
  .component.locations-spot .filter-list #plan-landing-page .close-button[href^='tel']:hover,
  #plan-landing-page .component.locations-spot .filter-list .filter-row label[href^='tel']:hover,
  .component.locations-spot .filter-list .filter-row #plan-landing-page label[href^='tel']:hover,
  #plan-landing-page .component.locations-spot .location-detail .close-button[href^='tel']:hover,
  .component.locations-spot .location-detail #plan-landing-page .close-button[href^='tel']:hover,
  #plan-landing-page-with-nav a[href^='tel']:link,
  #plan-landing-page-with-nav .component.locations-spot .locations-search .search-filter-button[href^='tel']:link,
  .component.locations-spot .locations-search #plan-landing-page-with-nav .search-filter-button[href^='tel']:link,
  #plan-landing-page-with-nav .component.locations-spot .filter-list .close-button[href^='tel']:link,
  .component.locations-spot .filter-list #plan-landing-page-with-nav .close-button[href^='tel']:link,
  #plan-landing-page-with-nav .component.locations-spot .filter-list .filter-row label[href^='tel']:link,
  .component.locations-spot .filter-list .filter-row #plan-landing-page-with-nav label[href^='tel']:link,
  #plan-landing-page-with-nav .component.locations-spot .location-detail .close-button[href^='tel']:link,
  .component.locations-spot .location-detail #plan-landing-page-with-nav .close-button[href^='tel']:link,
  #plan-landing-page-with-nav a[href^='tel']:visited,
  #plan-landing-page-with-nav .component.locations-spot .locations-search .search-filter-button[href^='tel']:visited,
  .component.locations-spot .locations-search #plan-landing-page-with-nav .search-filter-button[href^='tel']:visited,
  #plan-landing-page-with-nav .component.locations-spot .filter-list .close-button[href^='tel']:visited,
  .component.locations-spot .filter-list #plan-landing-page-with-nav .close-button[href^='tel']:visited,
  #plan-landing-page-with-nav .component.locations-spot .filter-list .filter-row label[href^='tel']:visited,
  .component.locations-spot .filter-list .filter-row #plan-landing-page-with-nav label[href^='tel']:visited,
  #plan-landing-page-with-nav .component.locations-spot .location-detail .close-button[href^='tel']:visited,
  .component.locations-spot .location-detail #plan-landing-page-with-nav .close-button[href^='tel']:visited,
  #plan-landing-page-with-nav a[href^='tel']:hover,
  #plan-landing-page-with-nav .component.locations-spot .locations-search .search-filter-button[href^='tel']:hover,
  .component.locations-spot .locations-search #plan-landing-page-with-nav .search-filter-button[href^='tel']:hover,
  #plan-landing-page-with-nav .component.locations-spot .filter-list .close-button[href^='tel']:hover,
  .component.locations-spot .filter-list #plan-landing-page-with-nav .close-button[href^='tel']:hover,
  #plan-landing-page-with-nav .component.locations-spot .filter-list .filter-row label[href^='tel']:hover,
  .component.locations-spot .filter-list .filter-row #plan-landing-page-with-nav label[href^='tel']:hover,
  #plan-landing-page-with-nav .component.locations-spot .location-detail .close-button[href^='tel']:hover,
  .component.locations-spot .location-detail #plan-landing-page-with-nav .close-button[href^='tel']:hover {
    text-decoration: none;
    color: #4d4d4d;
    font-weight: normal;
    pointer-events: none;
    cursor: default; } }

.header-logo-sm180-lg255 .component-content a img, .header-logo-sm180-lg255 .component-content .component.locations-spot .locations-search .search-filter-button img, .component.locations-spot .locations-search .header-logo-sm180-lg255 .component-content .search-filter-button img, .header-logo-sm180-lg255 .component-content .component.locations-spot .filter-list .close-button img, .component.locations-spot .filter-list .header-logo-sm180-lg255 .component-content .close-button img, .header-logo-sm180-lg255 .component-content .component.locations-spot .filter-list .filter-row label img, .component.locations-spot .filter-list .filter-row .header-logo-sm180-lg255 .component-content label img, .header-logo-sm180-lg255 .component-content .component.locations-spot .location-detail .close-button img, .component.locations-spot .location-detail .header-logo-sm180-lg255 .component-content .close-button img {
  width: 180px !important; }
  @media (min-width: 992px) {
    .header-logo-sm180-lg255 .component-content a img, .header-logo-sm180-lg255 .component-content .component.locations-spot .locations-search .search-filter-button img, .component.locations-spot .locations-search .header-logo-sm180-lg255 .component-content .search-filter-button img, .header-logo-sm180-lg255 .component-content .component.locations-spot .filter-list .close-button img, .component.locations-spot .filter-list .header-logo-sm180-lg255 .component-content .close-button img, .header-logo-sm180-lg255 .component-content .component.locations-spot .filter-list .filter-row label img, .component.locations-spot .filter-list .filter-row .header-logo-sm180-lg255 .component-content label img, .header-logo-sm180-lg255 .component-content .component.locations-spot .location-detail .close-button img, .component.locations-spot .location-detail .header-logo-sm180-lg255 .component-content .close-button img {
      width: 255px !important; } }

#plan-landing-page.plan-landing-page-csea .content .row,
#plan-landing-page.plan-landing-page-csea #footer .row {
  display: block !important; }

#plan-landing-page.plan-landing-page-csea .modal-body {
  padding: 1rem 4rem; }
  #plan-landing-page.plan-landing-page-csea .modal-body .row {
    margin-left: 0;
    margin-right: 0; }
    #plan-landing-page.plan-landing-page-csea .modal-body .row .col,
    #plan-landing-page.plan-landing-page-csea .modal-body .row .col-md-10,
    #plan-landing-page.plan-landing-page-csea .modal-body .row .col-md-2 {
      padding-left: 0;
      padding-right: 0; }

#plan-landing-page.plan-landing-page-csea input#formCseaId {
  width: 100%; }
  #plan-landing-page.plan-landing-page-csea input#formCseaId:focus {
    border-color: #b4b5b7 !important; }

#plan-landing-page.plan-landing-page-csea .modal-backdrop.show {
  opacity: 0.8; }

#plan-landing-page.plan-landing-page-csea .modal-title.h4 {
  margin: 0 3rem;
  color: white; }

#plan-landing-page.plan-landing-page-csea .container,
#plan-landing-page.plan-landing-page-csea .container-fluid,
#plan-landing-page.plan-landing-page-csea .container-lg,
#plan-landing-page.plan-landing-page-csea .container-md,
#plan-landing-page.plan-landing-page-csea .container-sm,
#plan-landing-page.plan-landing-page-csea .container-xl {
  padding-left: 0;
  padding-right: 0; }

.component.rich-text-list.yh-navigation-action-buttons .component-content {
  width: auto;
  max-width: 100%;
  margin: 0; }
  .component.rich-text-list.yh-navigation-action-buttons .component-content ul {
    display: flex; }
    .component.rich-text-list.yh-navigation-action-buttons .component-content ul > li:first-child:nth-last-child(2),
    .component.rich-text-list.yh-navigation-action-buttons .component-content ul > li:first-child:nth-last-child(2) ~ li {
      width: auto;
      float: none;
      margin: 0;
      padding-right: 1rem; }
    .component.rich-text-list.yh-navigation-action-buttons .component-content ul > li:first-child:nth-last-child(2) ~ li {
      padding: 0; }
    .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(1) a, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(1) .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(1) .search-filter-button, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(1) .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(1) .close-button, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(1) .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(1) label, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(1) .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(1) .close-button {
      background-color: #f7941d; }
    .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(2) a, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(2) .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(2) .search-filter-button, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(2) .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(2) .close-button, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(2) .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(2) label, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(2) .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.rich-text-list.yh-navigation-action-buttons .component-content ul li:nth-child(2) .close-button {
      background-color: #1890ba; }
    .component.rich-text-list.yh-navigation-action-buttons .component-content ul li a, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .search-filter-button, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .close-button, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.rich-text-list.yh-navigation-action-buttons .component-content ul li label, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .close-button {
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      border-radius: 4px 10px;
      padding: 0;
      line-height: 1;
      height: 40px;
      width: 50px;
      font-size: 0;
      transition: all 0.3s ease; }
      @media (min-width: 992px) {
        .component.rich-text-list.yh-navigation-action-buttons .component-content ul li a, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .search-filter-button, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .close-button, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row .component.rich-text-list.yh-navigation-action-buttons .component-content ul li label, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .close-button {
          width: 150px;
          height: 48px;
          padding: 0 17px;
          font-size: 14px; } }
      @media (min-width: 992px) {
        .component.rich-text-list.yh-navigation-action-buttons .component-content ul li a.icon-com-phone-a::before, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .locations-search .icon-com-phone-a.search-filter-button::before, .component.locations-spot .locations-search .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .icon-com-phone-a.search-filter-button::before, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .filter-list .icon-com-phone-a.close-button::before, .component.locations-spot .filter-list .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .icon-com-phone-a.close-button::before, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .filter-list .filter-row label.icon-com-phone-a::before, .component.locations-spot .filter-list .filter-row .component.rich-text-list.yh-navigation-action-buttons .component-content ul li label.icon-com-phone-a::before, .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .component.locations-spot .location-detail .icon-com-phone-a.close-button::before, .component.locations-spot .location-detail .component.rich-text-list.yh-navigation-action-buttons .component-content ul li .icon-com-phone-a.close-button::before {
          transform: translate(-4px); } }

.component.latest-blog-posts ul#blog-list li {
  margin: 0;
  padding: 2rem 0; }
  @media (min-width: 768px) {
    .component.latest-blog-posts ul#blog-list li {
      width: 30%;
      padding: 0; } }

.component.accordion-spot {
  margin-top: 5rem; }
  .component.accordion-spot .component-content {
    position: relative; }
    .component.accordion-spot .component-content .accordion-spot-elements__expand-all,
    .component.accordion-spot .component-content .accordion-spot-element__title {
      cursor: pointer; }
    .component.accordion-spot .component-content .accordion-spot-elements__expand-all {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1; }
  .component.accordion-spot .accordion-spot-element__content {
    display: none; }
  .component.accordion-spot .accordion-spot-element.expand .accordion-spot-element__content {
    display: inherit; }
  .component.accordion-spot .accordion-spot-element.shrink {
    cursor: pointer; }

.accordion-spot {
  background-color: #f0f0f0;
  border: 1px solid #cccccc;
  margin-top: 0 !important;
  margin-bottom: 5rem; }
  .accordion-spot > .component-content {
    padding: 0 !important; }
  .accordion-spot:first-child {
    border-radius: 4px 4px 0 0; }
  .accordion-spot:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion-spot-element:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion-spot-element.shrink {
    transition: all 0.3s ease; }
    .accordion-spot-element.shrink .accordion-spot-element__title::after {
      font-family: 'yourhearing-icons';
      content: '\e912'; }
  .accordion-spot-element.expand {
    transition: all 0.3s ease; }
    .accordion-spot-element.expand .accordion-spot-element__title::after {
      font-family: 'yourhearing-icons';
      content: '\e90d'; }
  .accordion-spot-element__title {
    padding: 1.25rem 2rem;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.125rem;
    color: #1890ba;
    border-bottom: 1px solid #cccccc;
    display: flex;
    justify-content: space-between; }
    .accordion-spot-element__title:hover {
      color: #4d4d4d; }
  .accordion-spot-element__header {
    font-weight: bold;
    margin-bottom: 0.5rem; }
  .accordion-spot-element__content {
    color: #4d4d4d;
    background-color: #ffffff;
    line-height: 1.65;
    font-size: 1rem;
    font-weight: 400;
    padding: 1.25rem;
    border-bottom: 1px solid #cccccc; }

/*
 THEMING
*/
/********************************
DEFAULT STYLING - end
********************************/
@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 13px !important; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    font-size: 14px !important; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 15px !important; } }

.component.hearing-test-spot:not(.quiz) {
  padding: 0; }

.component.hearing-test-spot:not(.quiz) .step-manager {
  position: relative;
  width: 100%;
  overflow: hidden; }

.component.hearing-test-spot:not(.quiz) .progress-bar {
  width: 100%;
  background: #4d4d4d;
  padding: 10px 0 5px;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .progress-bar {
      position: absolute;
      z-index: 997;
      top: 99px;
      left: 0;
      padding: 0; } }
  .component.hearing-test-spot:not(.quiz) .progress-bar__blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .progress-bar__blocks {
        border: 0; } }
  .component.hearing-test-spot:not(.quiz) .progress-bar__block {
    height: 6px;
    background-color: #4d4d4d; }
    .component.hearing-test-spot:not(.quiz) .progress-bar__block--active {
      background-color: #f7bd00; }
    .component.hearing-test-spot:not(.quiz) .progress-bar__block--current {
      background-color: #f7941d; }
  .component.hearing-test-spot:not(.quiz) .progress-bar__balloon {
    position: absolute;
    top: calc(100% + 7px);
    padding: 0.4rem 0.8rem;
    background-color: #f7941d;
    border-radius: 4px;
    transform: translateX(-50%);
    transition: left 0.3s ease-in-out;
    z-index: 1; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .progress-bar__balloon {
        display: block;
        padding: 10px; } }
    .component.hearing-test-spot:not(.quiz) .progress-bar__balloon:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #f7941d; }
  .component.hearing-test-spot:not(.quiz) .progress-bar__text {
    color: #ffffff;
    white-space: nowrap;
    font-size: 1.1rem; }

.component.hearing-test-spot:not(.quiz) .step-header {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 0 1rem 0; }
  .component.hearing-test-spot:not(.quiz) .step-header__icon-wrapper div div {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem; }
  .component.hearing-test-spot:not(.quiz) .step-header__icon {
    width: 100%;
    max-height: 50px;
    fill: #ffffff; }
  .component.hearing-test-spot:not(.quiz) .step-header__title {
    font-size: 16px;
    color: #ffffff;
    margin: 0 0 2rem; }

.component.hearing-test-spot:not(.quiz) .step--button .step-header {
  margin: 0 0 3rem 0; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--button .step-header {
      margin: 0 0 1rem 0; } }
  .component.hearing-test-spot:not(.quiz) .step--button .step-header .step-header__title {
    margin: 0; }

.component.hearing-test-spot:not(.quiz) .step {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 71px);
  padding: 2rem 0 0 0;
  color: #ffffff; }
  @media only screen and (max-width: 350px) {
    .component.hearing-test-spot:not(.quiz) .step {
      padding: 3.5rem 0 0 0; } }
  @media all and (-ms-high-contrast: none) {
    .component.hearing-test-spot:not(.quiz) .step {
      min-height: auto; } }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step {
      padding: 6rem 0 6rem;
      min-height: 90vh;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat; } }
  @media all and (-ms-high-contrast: none) and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step {
      min-height: auto; } }
  .component.hearing-test-spot:not(.quiz) .step__wrapper {
    padding: 2rem;
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 1200px) {
      .component.hearing-test-spot:not(.quiz) .step__wrapper {
        position: relative;
        width: 90.25rem;
        max-width: 1140px; } }
  .component.hearing-test-spot:not(.quiz) .step__content {
    display: block;
    width: 100%;
    max-width: 800px; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step__content {
        width: 50%; } }
  .component.hearing-test-spot:not(.quiz) .step__text {
    background-color: rgba(77, 77, 77, 0.9);
    padding: 2rem;
    margin-bottom: 3px; }
    @media only screen and (max-width: 350px) {
      .component.hearing-test-spot:not(.quiz) .step__text {
        padding: 1rem; } }
  .component.hearing-test-spot:not(.quiz) .step__buttons {
    background-color: rgba(77, 77, 77, 0.9);
    padding: 1rem 2rem;
    display: flex;
    justify-content: center; }
  .component.hearing-test-spot:not(.quiz) .step__title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #ffffff; }
  .component.hearing-test-spot:not(.quiz) .step__body {
    font-size: 16px;
    color: #ffffff;
    margin: 2rem 0 4rem 0; }
  .component.hearing-test-spot:not(.quiz) .step-enter-active {
    position: relative; }
  .component.hearing-test-spot:not(.quiz) .step-exit {
    position: absolute; }
  .component.hearing-test-spot:not(.quiz) .step--button .step__title, .component.hearing-test-spot:not(.quiz) .step--slider .step__title {
    margin-bottom: 0; }

.component.hearing-test-spot:not(.quiz) .step--button .step__text {
  background-color: rgba(255, 255, 255, 0.2); }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--button .step__text {
      background-color: rgba(77, 77, 77, 0.9);
      padding: 2rem 3rem 3rem 3rem; } }

.component.hearing-test-spot:not(.quiz) .step--button .step__title {
  font-size: 1.5rem; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--button .step__title {
      font-size: 1.8rem;
      font-family: CentraleSansMedium, sans-serif; } }

.component.hearing-test-spot:not(.quiz) .step--button .step__buttons {
  background-color: rgba(255, 255, 255, 0.2); }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--button .step__buttons {
      background-color: rgba(77, 77, 77, 0.9);
      padding: 1rem 3rem; } }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__content,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__content,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__content,
.component.hearing-test-spot:not(.quiz) .step--tone .step__content {
  width: 100%;
  max-width: 100%;
  position: relative; }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text,
.component.hearing-test-spot:not(.quiz) .step--tone .step__text {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2rem 2rem 4rem 2rem;
  margin-bottom: 6px; }
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__title,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__title,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text .step__title,
  .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__title {
    display: flex;
    align-items: center;
    max-width: 100%; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__title,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__title,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text .step__title,
      .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__title {
        max-width: 60%; } }
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__body,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__body,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text .step__body,
  .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__body {
    max-width: 100%; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__body,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__body,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text .step__body,
      .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__body {
        max-width: 60%; } }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__buttons,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__buttons,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__buttons,
.component.hearing-test-spot:not(.quiz) .step--tone .step__buttons {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1rem 2rem;
  display: flex;
  justify-content: center; }
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__buttons .step-button:first-of-type,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__buttons .step-button:first-of-type,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__buttons .step-button:first-of-type,
  .component.hearing-test-spot:not(.quiz) .step--tone .step__buttons .step-button:first-of-type {
    margin-right: 0; }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text {
  padding: 2rem; }
  @media only screen and (max-width: 350px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text {
      padding: 1rem; } }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__text {
      padding-bottom: 4rem; } }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__title {
  font-size: 1.5rem; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-adjust-volume .step__title {
      font-size: 1.8rem; } }

.component.hearing-test-spot:not(.quiz) .step--tone .step__text {
  padding: 2rem; }
  @media only screen and (max-width: 350px) {
    .component.hearing-test-spot:not(.quiz) .step--tone .step__text {
      padding: 1rem; } }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--tone .step__text {
      padding-bottom: 4rem; } }
  .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__body {
    margin: 1rem 0;
    font-size: 1.5rem; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__body {
        font-size: 1.8rem; } }
  .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__title {
    margin-bottom: 1rem;
    font-size: 1.5rem; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone .step__text .step__title {
        font-size: 1.8rem; } }

.component.hearing-test-spot:not(.quiz) .step--tone .step__buttons .step-button:first-of-type {
  margin-right: 3rem; }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step-circle,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step-circle {
  border-radius: 50%;
  margin: 0 1rem 0 0; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step-circle,
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step-circle {
      margin: 0 2rem 0 0; } }

.component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text,
.component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text {
  padding: 2rem; }
  @media only screen and (max-width: 350px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text,
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text {
      padding: 1rem; } }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text,
    .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text {
      padding-bottom: 4rem; } }
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__title,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__title {
    font-size: 1.5rem; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__title,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__title {
        font-size: 1.8rem; } }
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__body,
  .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__body {
    margin: 1rem 0;
    font-size: 1rem; }
    @media only screen and (min-width: 768px) {
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-intro .step__text .step__body,
      .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__body {
        margin: 2rem 0 4rem 0;
        font-size: 16px; } }

.component.hearing-test-spot:not(.quiz) .step-button {
  font-size: 1.15rem;
  padding: 0.5rem 2rem; }
  .component.hearing-test-spot:not(.quiz) .step-button:first-of-type {
    margin-right: 3rem; }

.component.hearing-test-spot:not(.quiz) .step-circle {
  border: 2px solid #ffffff;
  width: 2.35rem;
  height: 2.35rem;
  font-size: 1.3rem;
  line-height: 1.9rem;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px; }

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb; }

.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none; }

.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset; }

.rc-slider-handle:hover {
  border-color: #57c5f7; }

.rc-slider-handle:active, .rc-slider-handle.nice-select.open {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px; }

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999; }

.rc-slider-mark-text-active {
  color: #666; }

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent; }

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle; }

.rc-slider-dot-active {
  border-color: #96dbfa; }

.rc-slider-disabled {
  background-color: #e9e9e9; }

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc; }

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed; }

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important; }

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px; }

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px; }

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%; }

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running; }

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); } }

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); } }

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip-hidden {
  display: none; }

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0; }

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9; }

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c; }

.component.hearing-test-spot:not(.quiz) .step--slider .slider__wrapper {
  width: 100%;
  max-width: 400px;
  padding: 0;
  margin: 0 auto 3rem;
  position: relative; }

.component.hearing-test-spot:not(.quiz) .step--slider .slider__container {
  display: inline-block;
  width: 65%;
  margin: 0 1.5rem; }

.component.hearing-test-spot:not(.quiz) .step--slider .step-circle {
  cursor: pointer;
  background-color: transparent;
  line-height: 1;
  font-size: 1rem;
  overflow: hidden;
  padding: 0;
  user-select: none;
  color: #ffffff;
  font-family: "philips-global-icon-font"; }

.component.hearing-test-spot:not(.quiz) .step--slider .rc-slider {
  display: block; }
  .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-dot {
    margin: 0;
    border-color: #4d4d4d; }
  .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-handle {
    border-color: #4d4d4d; }
    .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-handle:focus, .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-handle:active, .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-handle.nice-select.open {
      box-shadow: 0 0 5px #4d4d4d; }
  .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-rail {
    background-color: #f7941d; }
  .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-track {
    background-color: #4d4d4d; }
  .component.hearing-test-spot:not(.quiz) .step--slider .rc-slider-mark-text {
    color: #ffffff;
    white-space: nowrap; }

.component.hearing-test-spot:not(.quiz) .volume-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 30rem;
  margin: 0 auto; }
  .component.hearing-test-spot:not(.quiz) .volume-controls .step-circle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .component.hearing-test-spot:not(.quiz) .volume-controls .step-circle {
    cursor: pointer;
    background-color: transparent;
    line-height: 1;
    font-size: 1rem;
    overflow: hidden;
    padding: 0;
    user-select: none;
    color: #ffffff;
    font-family: "philips-global-icon-font";
    border: 1px solid #ffffff;
    margin-bottom: 5px; }
  .component.hearing-test-spot:not(.quiz) .volume-controls .step-circle-label {
    font-size: 0.8571rem;
    line-height: 1.2857rem; }

.component.hearing-test-spot:not(.quiz) .volume-bars {
  display: inline-block;
  width: auto;
  margin: 0 1rem 0; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .volume-bars {
      margin: 0 2rem 0; } }

.component.hearing-test-spot:not(.quiz) .volume-bar {
  display: inline-block;
  width: 15px;
  margin: 0 1.5px;
  cursor: pointer;
  border: 1px solid #ffffff;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .component.hearing-test-spot:not(.quiz) .volume-bar {
      width: 20px; } }
  .component.hearing-test-spot:not(.quiz) .volume-bar.filled {
    background-color: #ffffff; }
  .component.hearing-test-spot:not(.quiz) .volume-bar.empty {
    background-color: transparent; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(1) {
  height: 7px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(2) {
  height: 14px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(3) {
  height: 21px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(4) {
  height: 28px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(5) {
  height: 35px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(6) {
  height: 42px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(7) {
  height: 49px; }

.component.hearing-test-spot:not(.quiz) .volume-bar:nth-child(8) {
  height: 56px; }

.component.hearing-test-spot:not(.quiz) .circular-progress-bar__background, .component.hearing-test-spot:not(.quiz) .circular-progress-bar__progress {
  fill: none; }

.component.hearing-test-spot:not(.quiz) .circular-progress-bar__background {
  stroke: #ffffff; }

.component.hearing-test-spot:not(.quiz) .circular-progress-bar__progress {
  stroke: #f7941d;
  stroke-linecap: round;
  stroke-linejoin: round; }

.component.hearing-test-spot:not(.quiz) .circular-progress-bar__text {
  font-size: 1em;
  font-weight: bold;
  fill: #4d4d4d; }

.component.hearing-test-spot:not(.quiz) .tone-progress {
  position: absolute;
  right: 1em;
  top: 1em; }
  .component.hearing-test-spot:not(.quiz) .tone-progress .circular-progress-bar__progress {
    stroke-linecap: butt;
    stroke-linejoin: butt; }
  .component.hearing-test-spot:not(.quiz) .tone-progress .circular-progress-bar__text {
    fill: white; }
  .component.hearing-test-spot:not(.quiz) .tone-progress .circular-progress-bar__background {
    stroke: #05294a; }

.component.hearing-test-spot:not(.quiz) .tone-player .circular-progress-bar__wrapper {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center; }

.component.hearing-test-spot:not(.quiz) .tone-player__control {
  cursor: pointer; }
  .component.hearing-test-spot:not(.quiz) .tone-player__control:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .component.hearing-test-spot:not(.quiz) .tone-player__control--play:after {
    content: 'PLAY'; }
  .component.hearing-test-spot:not(.quiz) .tone-player__control--stop:after {
    content: 'STOP'; }

.component.hearing-test-spot:not(.quiz) .tone-player__buttons {
  display: flex; }

.component.hearing-test-spot .component-content .tone-player__control:after {
  font-size: 35px; }

.component.hearing-test-spot .component-content .tone-player__control--play:after {
  content: "";
  transform: translate(-44%, -58%);
  font-family: 'icomoon-yourhearing' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.component.hearing-test-spot .component-content .tone-player__control--stop:after {
  content: "";
  transform: translate(-53%, -58%);
  font-family: 'icomoon-yourhearing' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.component.hearing-test-spot .component-content .step--tone-calibration-intro .step__text .step__body,
.component.hearing-test-spot .component-content .component.hearing-test-spot:not(.quiz) .step--tone-calibration-play-test-sound .step__text .step__body {
  font-size: 20px; }

#wrapper #content .component.hearing-test-intro > .component-content,
#wrapper #content .component.hearing-test-result > .component-content {
  width: auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 1.5rem; }
  @media only screen and (min-width: 992px) {
    #wrapper #content .component.hearing-test-intro > .component-content,
    #wrapper #content .component.hearing-test-result > .component-content {
      padding: 2.5rem; } }
  #wrapper #content .component.hearing-test-intro > .component-content > .component.rich-text,
  #wrapper #content .component.hearing-test-result > .component-content > .component.rich-text {
    padding: 0; }
    #wrapper #content .component.hearing-test-intro > .component-content > .component.rich-text .component-content,
    #wrapper #content .component.hearing-test-result > .component-content > .component.rich-text .component-content {
      width: 100%;
      max-width: 100%; }
      #wrapper #content .component.hearing-test-intro > .component-content > .component.rich-text .component-content h1,
      #wrapper #content .component.hearing-test-result > .component-content > .component.rich-text .component-content h1 {
        font-family: CentraleSansMedium, sans-serif; }

#wrapper #content .component.hearing-test-intro {
  padding: 0; }
  #wrapper #content .component.hearing-test-intro .component.image-spot {
    padding-top: 2rem;
    padding-bottom: 0; }
    @media only screen and (min-width: 992px) {
      #wrapper #content .component.hearing-test-intro .component.image-spot {
        padding-top: 3rem; } }
    #wrapper #content .component.hearing-test-intro .component.image-spot .component-content {
      width: 100%;
      max-width: 100%; }
      @media only screen and (min-width: 992px) {
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) .image-spot-element-image,
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li .image-spot-element-image {
          padding: 0 0; }
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) .image-spot-element-image,
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li .image-spot-element-image {
          padding: 0 0; }
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) .image-spot-element-image,
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li .image-spot-element-image {
          padding: 0 0; }
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) .image-spot-element-image,
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li .image-spot-element-image {
          padding: 0 0; } }
      #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element {
        padding-right: 1rem;
        margin-bottom: 0; }
        @media only screen and (max-width: 991px) {
          #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element {
            width: 100%;
            display: flex;
            margin-bottom: 1.5rem; }
            #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
              display: flex;
              align-items: center;
              margin-bottom: 0;
              padding: 0;
              text-align: left; }
            #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
              max-width: 100%;
              display: flex;
              align-items: center;
              margin: 0 0 0 1.5rem;
              text-align: left; }
            #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-text {
              display: none; } }
      #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements img {
        max-width: 50px; }
        @media only screen and (min-width: 992px) {
          #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements img {
            max-width: 80px; } }
  #wrapper #content .component.hearing-test-intro .component.rich-text .component-content [style] {
    text-align: center !important; }
  #wrapper #content .component.hearing-test-intro .component.rich-text .component-content h3 {
    font-size: 2.5rem;
    font-weight: normal; }
    @media (min-width: 992px) {
      #wrapper #content .component.hearing-test-intro .component.rich-text .component-content h3 {
        width: 56%; } }
  #wrapper #content .component.hearing-test-intro .component.rich-text .component-content h5 {
    font-size: 1.5rem; }
    @media (min-width: 992px) {
      #wrapper #content .component.hearing-test-intro .component.rich-text .component-content h5 {
        width: 56%; } }
  @media (min-width: 992px) {
    #wrapper #content .component.hearing-test-intro .component.rich-text .component-content p {
      width: 56%; } }
  @media only screen and (max-width: 991px) {
    #wrapper #content .component.hearing-test-intro .component.rich-text:last-of-type .component-content h3 {
      display: none; }
    #wrapper #content .component.hearing-test-intro .component.rich-text:last-of-type .component-content h3 + p {
      margin-top: 0; } }
  #wrapper #content .component.hearing-test-intro .contains-btn {
    margin-top: 0; }

#wrapper #content .component.hearing-test-intro-button {
  margin-top: 6px; }
  #wrapper #content .component.hearing-test-intro-button > .component-content {
    padding: 1rem 1.5rem; }
    @media only screen and (min-width: 992px) {
      #wrapper #content .component.hearing-test-intro-button > .component-content {
        padding: 1rem 2rem; } }
  #wrapper #content .component.hearing-test-intro-button .component.rich-text .component-content > * {
    width: 100%;
    text-align: center; }

#wrapper #content .component.hearing-test-result {
  padding-bottom: 2rem; }
  #wrapper #content .component.hearing-test-result .component.rich-text .component-content h1 {
    font-size: 2.5rem;
    font-weight: normal;
    font-style: normal;
    line-height: normal; }
  #wrapper #content .component.hearing-test-result .component.rich-text .component-content h3 {
    font-size: 1.5rem;
    font-weight: 400;
    font-style: normal;
    line-height: normal; }
  #wrapper #content .component.hearing-test-result .component.rich-text .component-content p {
    font-size: 1.15rem;
    line-height: 1.6; }
  #wrapper #content .component.hearing-test-result .component.rich-text .component-content a, #wrapper #content .component.hearing-test-result .component.rich-text .component-content .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper #content .component.hearing-test-result .component.rich-text .component-content .search-filter-button, #wrapper #content .component.hearing-test-result .component.rich-text .component-content .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper #content .component.hearing-test-result .component.rich-text .component-content .close-button, #wrapper #content .component.hearing-test-result .component.rich-text .component-content .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper #content .component.hearing-test-result .component.rich-text .component-content label, #wrapper #content .component.hearing-test-result .component.rich-text .component-content .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper #content .component.hearing-test-result .component.rich-text .component-content .close-button {
    margin-top: 0.5rem; }
    #wrapper #content .component.hearing-test-result .component.rich-text .component-content a:first-of-type, #wrapper #content .component.hearing-test-result .component.rich-text .component-content .component.locations-spot .locations-search .search-filter-button:first-of-type, .component.locations-spot .locations-search #wrapper #content .component.hearing-test-result .component.rich-text .component-content .search-filter-button:first-of-type, #wrapper #content .component.hearing-test-result .component.rich-text .component-content .component.locations-spot .filter-list .close-button:first-of-type, .component.locations-spot .filter-list #wrapper #content .component.hearing-test-result .component.rich-text .component-content .close-button:first-of-type, #wrapper #content .component.hearing-test-result .component.rich-text .component-content .component.locations-spot .filter-list .filter-row label:first-of-type, .component.locations-spot .filter-list .filter-row #wrapper #content .component.hearing-test-result .component.rich-text .component-content label:first-of-type, #wrapper #content .component.hearing-test-result .component.rich-text .component-content .component.locations-spot .location-detail .close-button:first-of-type, .component.locations-spot .location-detail #wrapper #content .component.hearing-test-result .component.rich-text .component-content .close-button:first-of-type {
      margin-right: 1rem; }
  #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content {
    padding: 2rem 0 0; }
    #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .section-one {
      padding: 0 2rem 2rem; }
    #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form input[type='text'] {
      background-color: #ffffff; }
    #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfValidationSummary {
      display: none; }
    #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfSubmitButtonBorder {
      text-align: right; }
    #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfRequired {
      display: none; }
    #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox {
      display: flex; }
      #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox a, #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .component.locations-spot .locations-search .search-filter-button, .component.locations-spot .locations-search #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .search-filter-button, #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .component.locations-spot .filter-list .close-button, .component.locations-spot .filter-list #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .close-button, #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .component.locations-spot .filter-list .filter-row label, .component.locations-spot .filter-list .filter-row #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox label, #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .component.locations-spot .location-detail .close-button, .component.locations-spot .location-detail #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .close-button {
        color: #f7941d; }
        #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox a:hover, #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .component.locations-spot .locations-search .search-filter-button:hover, .component.locations-spot .locations-search #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .search-filter-button:hover, #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .component.locations-spot .filter-list .close-button:hover, .component.locations-spot .filter-list #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .close-button:hover, #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .component.locations-spot .filter-list .filter-row label:hover, .component.locations-spot .filter-list .filter-row #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox label:hover, #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .component.locations-spot .location-detail .close-button:hover, .component.locations-spot .location-detail #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form .scfCheckbox .close-button:hover {
          text-decoration: underline; }
    #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form input[type='submit'] {
      background-color: #f7941d;
      border-radius: 4px;
      border: 0;
      border-bottom: 1px solid #d97a08;
      color: #ffffff;
      padding: 0.5rem 2rem;
      transition: background-color 0.15s ease;
      padding: 0.5rem 2rem;
      font-size: 1.15rem;
      font-weight: 400;
      font-style: normal;
      display: inline-block; }
      #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form input[type='submit']:hover {
        background-color: #d97a08; }
      #wrapper #content .component.hearing-test-result .component.two-section-grid > .component-content .component.hearing-test-result-form input[type='submit']:focus {
        box-shadow: 0px 0px 6px 0px #fac380; }

/*# sourceMappingURL=main.[hash].css.map */